import React , { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import './inputtag.scss'
import { WithContext as ReactTags } from 'react-tag-input';

const InputTags = (props) => {
    const KeyCodes = {
        comma: 188,
        enter: 13
      };      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const [tags,setTags] = useState([])
    const [isUpdate,setIsUpdate] = useState(false)

    useEffect(() => {
        if(props.value){
            setTags(props.value)
        }
    },[props.value]);

    const handleDeleteTag = i => {
        let tagsTemp = [];

        tags.map((data,index) => {
            if(index !== i){
                tagsTemp.push(data)
            }
        })
        setTags(tagsTemp);
        setIsUpdate(!isUpdate)
        props.onChange({
            name : props.id,
            value : tagsTemp
        })
    };

    const handleAdditionTag = tag => {
        let tagsTemp = tags ;

        tagsTemp.push(tag);
        setTags(tagsTemp)
        setIsUpdate(!isUpdate)
        props.onChange({
            name : props.id,
            value : tagsTemp
        })
    };

    const handleDrag = e => {

    }

    const handleTagClick = e => {

    }

    return (
    <div className="form-group mt-3">
        <label htmlFor={props.id} className="form-label">{props.label}</label>
        <ReactTags
            placeholder={props.placeholder}
            tags={tags}
            suggestions={props.suggestions}
            delimiters={delimiters}
            handleDelete={handleDeleteTag}
            handleAddition={handleAdditionTag}
            handleDrag={handleDrag}
            handleTagClick={handleTagClick}
            inputFieldPosition="bottom"
            autocomplete
        />
    </div>
    )
}

InputTags.propTypes = {
    placeholder : PropTypes.string,
    label : PropTypes.string,
    id : PropTypes.string,
    tags : PropTypes.array,
    suggestions : PropTypes.array,
    onChange : PropTypes.func,
};

export default InputTags