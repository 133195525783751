import { Link } from "react-router-dom";
import { IconPencil } from "../../../assets/icons";
import { ButtonAction } from "../../../components";
import { EDITREGIONAL } from "../../../routes/routesNames";

const columns = [
  {
    name: "Regional",
    selector: (row) => row.name,
    sortable: true,
    maxWidth: "296px",
  },
  {
    name: "Wilayah Kerja",
    selector: (row) => row.area,
    sortable: true,
    format: (row, index) => {
      let areas = [];

      if (row.area) {
        row.area.province.map((data) => {
          areas.push([data.name]);
        });

        row.area.city.map((data, index) => {
          areas[index].push(data.name);
        });

        row.area.district.map((data, index) => {
          areas[index].push(data.name);
        });

        row.area.village.map((data, index) => {
          areas[index].push(data.name);
        });

        return (
          <div className="pb-1">
            {areas.map((dataParent, indexParent) => (
              <span key={indexParent} className="label-danger">
                {dataParent.map((data, index) => (
                  <span key={index}>
                    {data}
                    {dataParent.length !== index + 1 ? "," : ""}
                  </span>
                ))}
              </span>
            ))}
          </div>
        );
      } else {
        return "";
      }
    },
  },
  {
    name: "Aksi",
    selector: (row) => row.name,
    sortable: true,
    maxWidth: "150px",
    format: (row, index) => {
      return (
        <Link to={EDITREGIONAL} state={row}>
          <ButtonAction title="Ubah" icon={IconPencil} />
        </Link>
      );
    },
  },
];

export default columns;
