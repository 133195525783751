import React from 'react'
import './loader.scss';

const Loader = () => {
  return (
        <div className="d-flex justify-content-center w-100">
            <div className="loader"></div>
        </div>   
    )
}

export default Loader