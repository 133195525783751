import { Link } from "react-router-dom";
import { IconPencil } from "../../../assets/icons";
import { ButtonAction } from "../../../components";
import { EDITADMIN, EDITEXPERT, EDITSUPERADMIN } from "../../../routes/routesNames";

const columns =  [
    {
        name: 'Expert',
        selector: row => row.name,
        sortable: true,
        minWidth : "200px",
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
        minWidth : "296px",
    },
    {
        name: 'No Telepon',
        selector: row => row.phone_number,
        sortable: true,
        minWidth : "296px",
    },
    {
        name: 'Jabatan',
        selector: row => row.jabatan,
        sortable: true,
        minWidth : "296px",
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
        minWidth : "296px",
        format : (row,index) => {    
            return <div className="pb-1">
                        <span className={row.status === 'aktif' ?  `label-success` : 'label-danger'}>
                            {
                                row.status
                            }
                        </span>            
                    </div> 
        }
    },
    {
        name: 'Aksi',
        selector: row => row.name,
        sortable: true,
        maxWidth : "200px",
        format : (row,index) => {
            // return <Dropdown 
            //             detail={row}
            //             data={[
            //                 {
            //                     title : 'Lihat',
            //                     path : '',
            //                     type : 'modal',

            //                 },
            //                 {
            //                     title : 'Ubah',
            //                     path : EDITADMIN,
            //                     type : 'path'
            //                 },
            //             ]} />
            return <Link to={EDITSUPERADMIN} state={row}>
                <ButtonAction title="Ubah" icon={IconPencil} />
            </Link>             
        }
    },
];

export default columns;