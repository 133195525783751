import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { axios, errorResponse } from "../../helpers";
import { loginData, logout } from "../../stores/auth";

const UsePostDataForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataLogin = useSelector(loginData);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handlePostDataForm = async (params) => {
    setIsLoading(true);
    try {
      const bodyFormData = new FormData();
      Object.keys(params.form).map(function (key, index) {
        if (Array.isArray(params.form[key])) {
          if (params.form[key].length) {
            params.form[key].map((data) => {
              if (params.url === "/v1/askAndQuestion" && key === "images") {
                bodyFormData.append(`${key}`, data);
              } else {
                bodyFormData.append(`${key}[]`, data);
              }
            });
          } else {
            bodyFormData.append(`${key}[]`, "");
          }
        } else {
          bodyFormData.append(key, params.form[key]);
        }
      });
      console.log(bodyFormData);
      const response = await axios.post(params.url, bodyFormData, {
        headers: { Authorization: `Bearer ${dataLogin.data.token}` },
      });
      if (response.data.status) {
        let message = response.data.message
          ? response.data.message
          : "Tambah Data Berhasil";
        swal("Berhasil", message, "success", {
          button: "Tutup",
        }).then(() => {
          setError("");
          if (params.navigation) {
            navigate(params.navigation, { replace: true });
          } else {
            window.location.reload();
          }
        });
      } else {
        swal("Error", response.data.message, "error");
        setError(response.data.message);
      }
    } catch (err) {
      if (errorResponse(err) == "jwt expired") {
        dispatch(logout());
      } else {
        setError(errorResponse(err));
        swal("Error", errorResponse(err), "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    handlePostDataForm,
  };
};

export default UsePostDataForm;
