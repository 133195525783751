import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from "react-number-format";
import {IconAddFile, IconEye,IconEyeSlash, IconPencil, IconSearch} from '../../../assets/icons';
import './input.scss';
import { LogoImageDefault } from '../../../assets/images';
import swal from 'sweetalert';

export default function Input(props) {
    const [isVisible, setIsVisible] = useState(false);
    const [isEditIMage, setIsEditImage] = useState(false);
    const [image, setImage] = useState( null);

    const imageValue = props.value && !isEditIMage   ;

    const handleImageChange = e => {
        const {files} = e.target
        if(files[0].size < 2000000){
            if(files[0].type === "image/jpeg" || files[0].type === "image/png"){
                props.onChange(e)
                setImage(URL.createObjectURL(files[0]))
            }
        }else{
            swal('Error','Ukuran gambar tidak lebih dari 2 MB','error')

        }
    }
 
    return (
        <div className={`form-group ${props.label ? 'mt-3' : ''}`}>
            {
                props.label ?
                <label htmlFor={props.id}>{props.label}</label> : null
            }
            {
                props.type === 'password' ?
                <div className="position-relative">
                    <input 
                        required={props.required}
                        readOnly={props.isReadOnly}
                        type={isVisible ? 'text' : 'password'} 
                        className={`form-control ${props.className}`} 
                        id={props.id}
                        name={props.id}
                        value={props.value}
                        placeholder={props.placeholder}
                        onChange={ props.onChange} />
                    <img 
                        src={isVisible ?IconEye : IconEyeSlash } 
                        className="icon-eye pointer"
                        alt="logo eye"
                        onClick={() => setIsVisible(!isVisible)} />
                </div>
                :
                props.type === 'number' ?
                <NumberFormat
                    allowNegative={false}
                    onValueChange={e => {
                        if(parseInt(e.value) >= 0){
                            props.onChange({name : props.id, value : e.value})  
                        }                          
                    }}
                    value={props.value}
                    className={`form-control ${props.className}`} 
                    inputMode="numeric"
                /> : 
                props.type === 'thousand' ?
                <NumberFormat
                    allowNegative={false}
                    onValueChange={e => {
                        if(parseInt(e.value) >= 0){
                            props.onChange({name : props.id, value : e.value})  
                        }                          
                    }}
                    value={props.value}
                    thousandSeparator={true}
                    prefix="Rp"
                    className={`form-control ${props.className}`} 
                    inputMode="numeric"
                /> : 
                props.type === 'unit' ?
                <div className="d-flex flex-row position-relative">
                    <NumberFormat
                        allowNegative={false}
                        onValueChange={e => parseInt(e.value) ? props.onChange(props.id,e.value) : null}
                        value={props.value}
                        thousandSeparator={true}
                        className={`form-control ${props.className}`} 
                        inputMode="numeric"
                    /> 
                    <div className="unit-label">
                        <p className="mb-0">
                        {
                            props.unitName
                        }
                        </p>
                    </div>
                </div>
                :
                props.type === 'file'?
                props.isImage ?
                <div className="position-relative d-flex flex-column">
                    {
                        props.value ?
                        <img 
                            // crossorigin="anonymous"
                            className="mb-2 input-img"
                            height={120}
                            width={214}
                            src={ image ? image : props.value} /> :
                        <img 
                            // crossorigin="anonymous"
                            className="mb-2 input-img"
                            // height={120}
                            width={214}
                            src={ image ? image : LogoImageDefault} />
                    }
                    <label htmlFor={props.id}>
                        <div className="input-img-action cursor-pointer">
                            <img src={IconPencil} alt="icon" />
                        </div>
                    </label>
                    <input 
                        required={props.value ? false : props.required}
                        onChange={handleImageChange}
                        type="file" 
                        name={props.id}
                        id={props.id}  
                        className="input-image-hidden" />
                    <span className="img-desc">{props.placeholder}</span>
                </div>  : 
                <>
                    {
                        imageValue ?
                        <div className="d-flex flex-column">
                            <div className="position-relative">
                                <input 
                                    // onClick={() => window.open(props.value, "_blank") }
                                    defaultValue={props.value}
                                    required={props.required}
                                    readOnly={props.isReadOnly}
                                    type="text" 
                                    className={`form-control form-file cursor-pointer  ${props.className}`} 
                                    id={props.id}
                                    name={props.id}
                                    placeholder={props.placeHolder}
                                    onChange={props.onChange} />
                                <img src={IconAddFile} className="icon-eye" alt="" />
                            </div>
                            <span onClick={() => setIsEditImage(true)} className="img-desc cursor-pointer">Edit</span>
                        </div>
                        :
                        <div className="d-flex flex-column">
                            <div className="position-relative">
                                <input 
                                    required={props.required}
                                    readOnly={props.isReadOnly}
                                    type={props.type} 
                                    className={`form-control ${props.className}`} 
                                    id={props.id}
                                    name={props.id}
                                    placeholder={props.placeHolder}
                                    onChange={props.onChange} />
                                <img src={IconAddFile} className="icon-eye" alt="" />
                            </div>
                            <span className="img-desc">{props.placeholder}</span>
                        </div>
                    }
                </> 
                :  
                props.type === 'search' ?
                <div className="position-relative">
                    <input 
                        required={props.required}
                        readOnly={props.isReadOnly}
                        type="search" 
                        className={`form-control form-search ${props.className}`} 
                        id={props.id}
                        name={props.id}
                        value={props.value}
                        placeholder={props.placeholder}
                        onChange={ props.onChange} />
                    <img 
                        src={IconSearch} 
                        className="icon-search pointer"
                        alt="logo eye"
                        onClick={() => setIsVisible(!isVisible)} />
                </div> :  
               props.type === 'textarea' ?
               <>
                <textarea 
                    defaultValue={props.value}
                    id={props.id}
                    name={props.id}
                    required={props.required}
                    readOnly={props.isReadOnly}
                    placeholder={props.placeholder}
                    onChange={ props.onChange} 
                    className="form-control" 
                    rows="3" />                   
               </> :                                   
                <input 
                    required={props.required}
                    max={props.max}
                    min={props.min}
                    readOnly={props.isReadOnly}
                    type={props.type} 
                    className={`form-control ${props.className}`} 
                    id={props.id}
                    name={props.id}
                    value={props.value}
                    placeholder={props.placeholder}
                    onChange={ props.onChange} />
            }
        </div>
    )
}

Input.propTypes = {
    min : PropTypes.string,
    max : PropTypes.string,
    label : PropTypes.string,
    formClassname : PropTypes.string,
    id : PropTypes.string,
    value : PropTypes.any,
    isReadOnly : PropTypes.bool,
    placeHolder : PropTypes.string,
    type : PropTypes.string,
    unitName : PropTypes.string,
    className : PropTypes.string,
    onChange : PropTypes.func,
    required : PropTypes.bool,
    isImage : PropTypes.bool,
    isPassword : PropTypes.bool,
};
Input.defaultProps = {
    type : 'text',
    required : true,
    isImage : true
}