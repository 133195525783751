import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import './inputWilayah.scss';
import { IconTrash } from '../../../assets/icons';
import SelectMultipleCheckbox from '../../atoms/selectMultipleCheckbox';

const InputWilayahKerja = (props) => {
    const [currentValue, setCurrentValue] = useState(
        {
            wilayah_kerja: '',
            wilayah_kerja_id: [],
            wilayah_kerja_detail_name: [],
        }
    );
    const [isUpdate, setIsUpdate] = useState(false);


    useEffect(() => {
        // console.log(props.dataSelected,'props.dataSelected')

        // if(props.dataSelected){
        //     setIsUpdate(!isUpdate)
        //     props.dataSelected.map((data,index) => {
        //         if(index === 0){
        //             currentValue.wilayah_kerja  = data.wilayah_kerja ;
        //         }
        //         currentValue.wilayah_kerja_id.push(data.wilayah_kerja_detail_name);
        //         currentValue.wilayah_kerja_detail_name.push(data.wilayah_kerja_detail_name);
        //     })
        // }
        setIsUpdate(!isUpdate)
        setCurrentValue(props.dataSelected)


    }, [props.dataSelected]);

    const handleChange = (e) => {
        let wilayah_kerja_detail_name = '';

        if (e.target.value === 'nasional') {
            wilayah_kerja_detail_name = 'nasional'
        }

        const currentValueSelected = {
            wilayah_kerja_id: '',
            wilayah_kerja_detail_name: wilayah_kerja_detail_name,
            wilayah_kerja: e.target.value,
        }

        setIsUpdate(!isUpdate)
        setCurrentValue(currentValueSelected)
        props.onChange({
            name: props.id,
            value: currentValueSelected
        })
    }
    const handleChangeDomain = (value) => {

        const currentValueSelected = {
            ...currentValue,
            wilayah_kerja_id: value.value,
            wilayah_kerja_detail_name: value.value,
        }

        setIsUpdate(!isUpdate)
        setCurrentValue(currentValueSelected)

        props.onChange({
            name: props.id,
            value: currentValueSelected
        })
    }
    //   const handleAddArray = () => {
    //     let currectTemp = currentValue;

    //     currectTemp.push({
    //         wilayah_kerja : '',
    //         wilayah_kerja_id : '',
    //         wilayah_kerja_detail_name : '',        
    //     })

    //     setIsUpdate(!isUpdate)
    //     setCurrentValue(currectTemp)
    //   }
    //   const handleRemove = indexSelected => {
    //     let currentTemp = [];

    //     currentValue.map((data,index) => {
    //         if(index !== indexSelected){
    //             currentTemp.push(data)
    //         }
    //     })
    //     setIsUpdate(!isUpdate)
    //     setCurrentValue(currentTemp)
    //     props.onChange({
    //         name : props.id,
    //         value : currentTemp
    //     })    
    //   }
    // console.log(`currentValue.wilayah_kerja===>${currentValue.wilayah_kerja}`);
    return (
        <div className="form-group input-wilayah  mt-3">
            <div>
                <div className="d-flex flex-row justify-content-between">
                    <label className="mt-3">Wilayah Kerja </label>
                    {/* {
                    currentValue.length > 1 ?
                    <img 
                        onClick={() => handleRemove(index)}
                        src={IconTrash} 
                        className="cursor-pointer"
                        alt="trash" /> : null
                } */}
                </div>
                <div className="d-flex flex-column form-group-radio">
                    <div className="form-check mt-1">
                        <input
                            checked={currentValue.wilayah_kerja === 'nasional'}
                            onChange={(e) => handleChange(e)}
                            className="form-check-input"
                            type="radio"
                            value="nasional"
                            name={`wilayah_kerja`}
                            id={`nasional`} />
                        <label
                            className="form-check-label"
                            htmlFor={`nasional`} >
                            Nasional
                        </label>
                    </div>
                    <div className="form-check mt-2">
                        <input
                            checked={currentValue.wilayah_kerja === 'regional'}
                            onChange={(e) => handleChange(e)}
                            className="form-check-input"
                            type="radio"
                            value="regional"
                            name={`wilayah_kerja`}
                            id={`regional`} />
                        <label
                            className="form-check-label"
                            htmlFor={`regional`} >
                            Regional
                        </label>
                    </div>
                    {
                        currentValue.wilayah_kerja === 'regional' ?
                            <div className="mt-2">
                                <SelectMultipleCheckbox
                                    value={currentValue.wilayah_kerja_detail_name}
                                    id="regional"
                                    placeHolder="Pilih Regional"
                                    onChange={data => handleChangeDomain(data)}
                                    url='/v1/domain?type=regional'
                                    label="Regional"
                                    typeLabel="label-info" />
                            </div> : null
                    }
                    <div className="form-check mt-2">
                        <input
                            checked={currentValue.wilayah_kerja === 'teritorial'}
                            onChange={(e) => handleChange(e)}
                            className="form-check-input"
                            type="radio"
                            value="teritorial"
                            name={`wilayah_kerja`}
                            id={`teritorial`} />
                        <label
                            className="form-check-label"
                            htmlFor={`teritorial`} >
                            Teritorial
                        </label>
                    </div>
                    {
                        currentValue.wilayah_kerja === 'teritorial' ?
                            <div className="mt-2">
                                <SelectMultipleCheckbox
                                    value={currentValue.wilayah_kerja_detail_name}
                                    id="teritorial"
                                    placeHolder="Pilih Teritorial"
                                    onChange={data => handleChangeDomain(data)}
                                    url='/v1/domain?type=teritori'
                                    label="Teritorial"
                                    typeLabel="label-info" />
                            </div> : null
                    }
                    <div className="form-check mt-2">
                        <input
                            checked={currentValue.wilayah_kerja === 'field_promotor'}
                            onChange={(e) => handleChange(e)}
                            className="form-check-input"
                            type="radio"
                            value="field_promotor"
                            name={`wilayah_kerja`}
                            id={`field_promotor`} />
                        <label
                            className="form-check-label"
                            htmlFor={`field_promotor`} >
                            Field Promotor
                        </label>
                    </div>
                    {
                        currentValue.wilayah_kerja === 'field_promotor' ?
                            <div className="mt-2">
                                <SelectMultipleCheckbox
                                    value={currentValue.wilayah_kerja_detail_name}
                                    id="promotor"
                                    placeHolder="Pilih Field Promotor"
                                    onChange={data => handleChangeDomain(data)}
                                    url='/v1/domain?type=filed promotor'
                                    label="Field Promotor"
                                    typeLabel="label-info" />
                            </div> : null
                    }
                </div>
            </div>
            {/* {
            currentValue[currentValue.length-1].wilayah_kerja_id !== '' ?
            <Button 
                type="button"
                onClick={handleAddArray}
                className="btn-primary-light w-100 mt-3" 
                title="Tambah Wilayah Kerja + "  />  : null
        }   */}
        </div>
    )
}

InputWilayahKerja.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    typeLabel: PropTypes.string,
    url: PropTypes.string,
    id: PropTypes.string,
    dataSelected: PropTypes.array,
};
InputWilayahKerja.defaultProps = {
    dataSelected: [],
}

export default InputWilayahKerja