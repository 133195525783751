import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { IconImport } from '../../../assets/icons';
import { UseForm } from '../../../hooks';
import { UsePostDataForm } from '../../../hooks/api';
import Button from '../../atoms/button';
import Input from '../../atoms/input';

const ImportModalSecond = (props) => {
  const { form, handleChange } = UseForm();
  const { isLoading, handlePostDataForm } = UsePostDataForm();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    handleChange({
      target: {
        name: 'excelFile',
        value: selectedFile,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const formData = new FormData();
    // formData.append('excelFile', form.excelFile);

    try {
      const response = await handlePostDataForm({
        url: props.urlImport,
        // form: formData,
        form: {
          "file": form.excelFile,
        }
      });
      handleClose();
    } catch (error) {
    }
  };

  return (
    <>
      <Button
        onClick={handleShow}
        icon={IconImport}
        title="Import"
        className="btn btn-primary-light mx-2"
        isSmall
      />
      <Modal backdrop="static" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Input
              formClassname="mt-0"
              type="file"
              id="file"
              isImage={false}
              name="excelFile"
              onChange={handleFileChange}
            />
            <span className="img-desc">
              Dokumen Excel, maksimal 5MB. Download template
              <a href={props.urlDownload} target="_blank" rel="noopener noreferrer" className="ms-2">
                di sini
              </a>
            </span>
            <div className="d-flex mt-4 justify-content-center">
              <div style={{ width: '256px' }}>
                <Button  
                  isLoading={isLoading}
                  type="submit" 
                  onClick={handleSubmit}
                  title="Import" 
                  className="btn btn-primary w-100" 
                  isSmall />       
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImportModalSecond;