import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import './dropdown.scss';
import { IconArrowDown, IconArrowUp } from '../../../assets/icons';
import { Button, Modal } from 'react-bootstrap';
import parse from 'html-react-parser';
import { UseModal } from '../../../hooks';

const DropdownAction = (props) => {
    const {
        isShowModal,
        setIsShowModal,
        wrapperRef,
    } = UseModal()

    useOutsideAlerter(wrapperRef);
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setIsShowModal(false)
        setShow(true)
    };

    const [showQuestion, setShowQuestion] = useState(false);
    const handleCloseQuestion = () => setShowQuestion(false);
    const handleShowQuestion = () => {
        setIsShowModal(false)
        setShowQuestion(true)
    };


    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsShowModal(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    function formatDate(date) {
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        const formattedDate = new Date(date).toLocaleDateString('id-ID', options);

        // Split the formatted date into day, month, and year parts
        const [month, day, year] = formattedDate.split(' ');

        // Convert the month abbreviation to uppercase
        const capitalizedMonth = month.toUpperCase();

        // Return the formatted date with uppercase month abbreviation and desired format
        return `${capitalizedMonth} ${day}  ${year}`;
    }

    // console.log(props.detail);

    return (
        <div ref={wrapperRef}>
            <div className={`dropdown-action`} onClick={() => setIsShowModal(!isShowModal)}>
                <span>Aksi</span>
                <img src={isShowModal ? IconArrowUp : IconArrowDown} />
            </div>
            {
                isShowModal ?
                    <div className="dropdown-modal">
                        {
                            props.data.map((data, index) => (
                                <span
                                    onClick={() => {
                                        if (data.type === "path") {
                                            navigate(
                                                data.path, {
                                                state: props.detail
                                            }
                                            )
                                        } else if (data.type === 'onclick') {
                                            data.function(props.detail)
                                        } else if (data.type === 'modal_question') {
                                            handleShowQuestion()
                                        } else {
                                            handleShow()
                                        }
                                    }}
                                    key={index}>{data.title}</span>
                            ))
                        }
                    </div> : null
            }
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title className="w-100">
                        <div className='w-100'>
                            <div className='w-100 d-flex justify-content-between'>
                                <span>Lihat {props.detail.link_vidio ? "Video": "Artikel"}</span>
                                <Button onClick={() => setShow(false)} variant="danger" className='px-4 mb-2'>x</Button>
                            </div>
                            {props.detail.title}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{color:"#939599"}}> {formatDate(props.detail.tanggal_publish)} </span><br />
                    <div style={{ marginTop: 16, marginBottom: 16 }}>
                        <img src={props.detail.thumbnail} style={{ objectFit: "cover", height: 314, width: "100%", borderRadius: 16 }} />
                    </div>
                    {/* {props.detail.content ? parse(props.detail.content) : props.detail.link_vidio ? parse(props.detail.link_vidio) : null} */}
                    {props.detail.content ? parse(props.detail.content) :  null}
                </Modal.Body>
            </Modal>
            {
                props.detail.data ?
                    <Modal
                        show={showQuestion}
                        onHide={handleCloseQuestion}
                        backdrop="static"
                        keyboard={false}
                        size="lg"

                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Lihat Gejala</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h5 className="mb-3">Informasi Gejala</h5>
                            {
                                props.detail.data.images.length ?
                                    <>
                                        <div className="d-flex flex-row">
                                            {
                                                props.detail.data.images.map((data, index) => (
                                                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                                    <img src={data} key={index} alt="image" width="400" />
                                                ))
                                            }
                                        </div>
                                    </> : null
                            }
                            <div className="form-group mt-3">
                                <label>Jenis Tanaman</label>
                                <p className="mb-0">{props.detail.data.jenis_tanaman_name}</p>
                            </div>
                            <div className="form-group mt-3">
                                <label>Bagian Tanaman</label>
                                <p className="mb-0">{props.detail.data.bagian_tanaman_name}</p>
                            </div>
                            <div className="form-group mt-3">
                                <label>Gejala</label>
                                <p className="mb-0">{props.detail.data.question}</p>
                            </div>
                            <h5 className="mb-3 mt-5">Informasi Penyakit</h5>
                            {
                                props.detail.data.penyakit_id.map((data, index) => (
                                    <div className="row mb-2" key={index}>
                                        <div className="col-lg-6 col-12">
                                            <div className="form-group">
                                                <label>Penyakit</label>
                                                <p className="mb-0">{data.penyakit_name}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-12">
                                            <div className="form-group">
                                                <label>Nilai MD</label>
                                                <p className="mb-0">{data.md_penyakit}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-12">
                                            <div className="form-group">
                                                <label>Nilai MB</label>
                                                <p className="mb-0">{Number(data.mb_penyakit).toFixed(1)}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            <h5 className="mb-3 mt-5">Informasi Hama</h5>
                            {
                                props.detail.data.hama_id.map((data, index) => (
                                    <div className="row mb-2" key={index}>
                                        <div className="col-lg-6 col-12">
                                            <div className="form-group">
                                                <label>Hama</label>
                                                <p className="mb-0">{data.hama_name}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-12">
                                            <div className="form-group">
                                                <label>Nilai MD</label>
                                                <p className="mb-0">{data.md_hama}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-12">
                                            <div className="form-group">
                                                <label>Nilai MB</label>
                                                <p className="mb-0">{Number(data.mb_hama).toFixed(1)}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                        </Modal.Body>
                    </Modal> : null
            }

        </div>
    )
}

DropdownAction.propTypes = {
    data: PropTypes.array,
    detail: PropTypes.any,
    isBottom: PropTypes.bool,
};

DropdownAction.defaultProps = {
    data: [],
}

export default DropdownAction