import { Button, CardContainer, Input, SelectAsync2, InputImageMultiple} from '../../components'
import InputMdMb from '../../components/molecules/inputMdMb';
import UseFormSymptom from './hookForm';

const AddSymptomPage = () => {
    const {
        isLoadingPost,
        navigate,
        form,
        handleChange,
        handleSubmit        
    } = UseFormSymptom({
        url : "/v1/askAndQuestion",
        type : 'post',
    })

  return (
      <CardContainer>
        <form onSubmit={e =>  handleSubmit(e) }>
            <div className="col-lg-8 col-12">     
                <h5>Informasi Gejala</h5>      
                <Input
                    isReadOnly
                    value={form.name}
                    id="jenis_tanaman_id"
                    onChange={handleChange}
                    placeholder="Jenis Tanaman" 
                    label="Jenis Tanaman" />   
                <SelectAsync2 
                    onChange={handleChange} 
                    labelName="name"
                    valueName="_id"
                    id="bagian_tanaman_id"
                    url="/v1/plantParts"
                    label="Bagian Tanaman"
                    placeHolder="Cari Bagian Tanaman..." />   
                <InputImageMultiple 
                    onChange={handleChange} 
                    id="images"
                    label="Gambar Gejala" 
                    placeholder="Gambar gejala opsional, maksimal 3 gambar. Format foto/gambar .JPG, .JPEG, .PNG, maksimal 2MB, dengan aspect ratio 1 : 1." />    
                <Input
                    type="textarea"
                    id="question"
                    onChange={handleChange}
                    placeholder="Gejala" 
                    label="Gejala" />   
                <InputMdMb 
                    onChange={handleChange}
                    id="fields_penyakit"
                    title="Informasi Penyakit" 
                    desc="Tambah penyakit dan masukan nilai MB dan MD. "
                    label="Penyakit" 
                    placeholder="Pilih penyakit" 
                    paramsId="?category_type=opt"
                    url="/v1/kategori" />
                <InputMdMb 
                    onChange={handleChange}
                    id="fields_penyakit_2"
                    title="Informasi Hama" 
                    desc="Tambah hama dan masukan nilai MB dan MD. "
                    label="Hama" 
                    placeholder="Pilih hama" 
                    paramsId="?category_type=hama"
                    url="/v1/kategori" />                    
                <div className="d-flex justify-content-end">
                    <div className="me-4">
                        <Button 
                            onClick={() => navigate(-1)}
                            type="button"
                            className={`btn-light w-100 mt-4 px-5`} 
                            title="Batalkan" />
                    </div>
                    <Button 
                        type="submit"
                        className={`btn-primary w-100 mt-4 px-5`} 
                        title="Simpan" 
                        isLoading={isLoadingPost} />
                </div>
            </div>
        </form>
      </CardContainer>
  )
}

export default AddSymptomPage;