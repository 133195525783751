import { Button, CardContainer, Input, InputSelect, InputTags, InputToggle, RadioButton, RichText, SelectMultipleCheckbox, SelectMultipleCheckboxStatic } from '../../../components'
import { DateToday } from '../../../helpers';
import UseFormArticle from './hookForm';
import parse from 'html-react-parser';
import React, { useState, useEffect } from "react";
import { LogoImageDefault } from '../../../assets/images';
import SelectMultipleCheckboxValueId from '../../../components/atoms/selectMultipleCheckboxValueId';

const AddArticle = () => {
    const {
        isLoadingPostDataForm,
        form,
        isEnable,
        navigate,
        dataEx,
        content,
        handleChange,
        handleChangeRich,
        handleSubmit
    } = UseFormArticle({
        type: 'post',
    })

    let dEx = []
    dataEx.map((v) => {
        dEx.push({
            name: v.name,
            value: v.name,
        })
    })
    return (
        <CardContainer>
            <form className="row" onSubmit={e => isEnable ? handleSubmit(e) : e.preventDefault()}>
                <div className="col-lg-7 col-12" style={{
                    height: '74vh',
                    overflowY: 'scroll'
                }}>
                    <Input
                        type="file"
                        id="thumbnail"
                        onChange={handleChange}
                        placeholder="Format foto/gambar .JPG, .JPEG, .PNG, maksimal 2MB, dengan aspect ratio 1 : 1."
                        label="Foto Thumbnail" />
                    <Input
                        id="title"
                        onChange={handleChange}
                        placeholder="Masukan judul artikel"
                        label="Judul" />
                    <Input
                        id="penerbit"
                        onChange={handleChange}
                        placeholder="Masukan penulis artikel"
                        label="Penulis" />
                    <SelectMultipleCheckboxStatic
                        id="jenis_opt"
                        placeHolder="Pilih jenis opt"
                        onChange={handleChange}
                        data={[
                            'hama',
                            'gulma',
                            'penyakit',
                        ]}
                        label="Jenis OPT"
                        typeLabel="label-info" />
                    <SelectMultipleCheckbox
                        id="jenis_tanaman"
                        placeHolder="Pilih jenis tanaman"
                        onChange={handleChange}
                        url="/v1/kategori?category_type=tanaman"
                        label="Jenis Tanaman"
                        typeLabel="label-info" />
                    <InputTags
                        id="tag"
                        onChange={handleChange}
                        placeholder="Masukan tag"
                        label="Tag" />
                    {/* <InputSelect
                        label="Status"
                        id="status"
                        value={form.status}
                        onChange={handleChange}
                        data={[
                            {
                                id: 'arsip',
                                name: 'Arsip'
                            },
                            {
                                id: 'publish',
                                name: 'Publish'
                            }
                        ]} /> */}
                    <RadioButton
                        className="mt-4"
                        data={[
                            {
                                name: "Sekarang",
                                value: "sekarang"
                            },
                            {
                                name: "Jadwalkan",
                                value: "jadwalkan"
                            }
                        ]}
                        value={form.terbitkan}
                        id="terbitkan"
                        label="Terbitkan"
                        onChange={handleChange} />
                    {
                        form.terbitkan === 'sekarang' ?
                            < ></> :
                            < div className="row">
                                <div className="col-lg-6 col-12">
                                    <Input
                                        min={DateToday()}
                                        type="date"
                                        id="tanggal_publish"
                                        onChange={handleChange}
                                        placeholder="Pilih tanggal"
                                        label="Tanggal" />
                                </div>
                                <div className="col-lg-6 col-12">
                                    <Input
                                        type="time"
                                        id="waktu_publish"
                                        onChange={handleChange}
                                        placeholder="Pilih waktu"
                                        label="Waktu" />
                                </div>
                            </div>
                    }
                    {/* <RadioButton
                        className="mt-4"
                        data={[
                            {
                                name: "Semua",
                                value: "Semua"
                            },
                            {
                                name: "User",
                                value: "User"
                            },
                            {
                                name: "Expert",
                                value: "Expert"
                            }
                        ]}
                        value={form.reciver_notification}
                        id="reciver_notification"
                        label="Penerima Notifikasi"
                        onChange={handleChange} /> */}

                    <div className={`form-group form-group-radio mt-3 mt-4`}>
                        <label>Penerima Notifikasi</label>
                        <div className="d-flex flex-column">
                            <div className="form-check my-2" >
                                <input
                                    checked={form.reciver_notification === "Semua"}
                                    onChange={handleChange}
                                    className="form-check-input"
                                    type="radio"
                                    value="Semua"
                                    name="reciver_notification"
                                    id="Semua" />
                                <label
                                    className="form-check-label"
                                    htmlFor="Semua">
                                    Semua
                                </label>
                            </div>
                            <div className="form-check my-2" >
                                <input
                                    checked={form.reciver_notification === "User"}
                                    onChange={handleChange}
                                    className="form-check-input"
                                    type="radio"
                                    value="User"
                                    name="reciver_notification"
                                    id="User" />
                                <label
                                    className="form-check-label"
                                    htmlFor="User">
                                    User
                                </label>
                            </div>
                            {
                                form.reciver_notification === "User" ?
                                    <div style={{ marginLeft: 32 }}>
                                        <SelectMultipleCheckboxStatic
                                            id="type_user"
                                            placeHolder="Tipe User"
                                            onChange={handleChange}
                                            data={[
                                                'Petani',
                                                'Perkebunan',
                                                'Lainnya',
                                            ]}
                                            label="Tipe User"
                                            typeLabel="label-info" />
                                        <RadioButton
                                            className="mt-4"
                                            data={[
                                                {
                                                    name: "Semua",
                                                    value: "semua"
                                                },
                                                {
                                                    name: "Beberapa Provinsi",
                                                    value: "Beberapa Provinsi"
                                                }
                                            ]}
                                            value={form.province_type}
                                            id="province_type"
                                            label="Provinsi"
                                            onChange={handleChange} />
                                        {
                                            form.province_type == "Beberapa Provinsi" ?
                                                <SelectMultipleCheckboxValueId
                                                    id="province_name"
                                                    placeHolder="Pilih Provinsi"
                                                    onChange={handleChange}
                                                    url="/v1/province?type=regional"
                                                    label="&nbsp;"
                                                    valueCheckbox="_id"
                                                    typeLabel="label-info" /> :
                                                <></>
                                        }
                                    </div>
                                    : <div></div>
                            }
                            <div className="form-check my-2" >
                                <input
                                    checked={form.reciver_notification === "Expert"}
                                    onChange={handleChange}
                                    className="form-check-input"
                                    type="radio"
                                    value="Expert"
                                    name="reciver_notification"
                                    id="Expert" />
                                <label
                                    className="form-check-label"
                                    htmlFor="Expert">
                                    Expert
                                </label>
                            </div>
                            {
                                form.reciver_notification === "Expert" ?
                                    <div style={{ marginLeft: 32 }}>
                                        <RadioButton
                                            className="mt-4"
                                            data={[
                                                {
                                                    name: "Semua",
                                                    value: "semua"
                                                },
                                                ...dEx
                                            ]}
                                            value={form.type_expert}
                                            orientation="vertical"
                                            id="type_expert"
                                            label="Tipe Expert"
                                            onChange={handleChange} />
                                    </div> : <></>
                            }
                        </div>
                    </div>
                    <InputToggle
                        id="is_trending"
                        onChange={handleChange}
                        checked={form.is_trending}
                        label="Jadikan Trending"
                        placeholder="Artikel akan tampil pada halaman awal aplikasi" />




                    {/* <InputToggle
                        id="is_notif"
                        onChange={handleChange}
                        checked={form.is_notif}
                        label="Notifikasi"
                        placeholder="Notifikasi Aktif" /> */}

                    <RichText
                        onChange={handleChangeRich}
                        label="Deskripsi"
                        id="content" />
                    <div className="d-flex justify-content-end">
                        <div className="me-4">
                            <Button
                                onClick={() => navigate(-1)}
                                type="button"
                                className={`btn-light w-100 mt-4 px-5`}
                                title="Batalkan" />
                        </div>
                        <Button
                            type="submit"
                            className={`${isEnable ? 'btn-primary' : 'btn-secondary'}  w-100 mt-4 px-5`}
                            title="Buat"
                            isLoading={isLoadingPostDataForm} />
                    </div>
                </div>
                <div className="col-lg-5 col-12" style={{
                    height: '74vh',
                    overflowY: 'scroll'
                }}>
                    <h5 className="mb-3">Pratinjau</h5>
                    <h2 className="mb-3">{form.title}</h2>
                    <img
                        className="mb-3 w-100"
                        src={form.thumbnail ? URL.createObjectURL(form.thumbnail) : LogoImageDefault}
                        alt="thumbnail" />

                    {content ? parse(content) : ''}
                </div>
            </form>
        </CardContainer >
    )
}

export default AddArticle