import React, { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";
import { IconSidebarArrow, IconSidebarArrowActive } from '../../../assets/icons'

const SidebarLink = ({ data, handleToggle }) => {
    const [pathName, setPathName] = useState("");

    const location = useLocation()
    useEffect(() => {
        const path = location.pathname.split("/");
    }, [])

    useEffect(() => {
        const path = location.pathname.split("/");
        setPathName('/' + path[1]);
    }, [location])

    let isActive = (props) => props.isExpand ? props.isOpen || pathName == props.path : pathName == props.path;
    let isExpand = (props) => props.isExpand && props.isOpen;

    return (
        <>
            <Link
                onClick={() => handleToggle(data.title, '', '1')}
                to={!data.isExpand ? data.path : '#'}
                state={{ test: 'test' }}>
                <div className={`sidebar-link ${isActive(data) ? 'sidebar-active' : ''}`} >
                    <div className="d-flex flex-row desc">
                        <img src={isActive(data) ? data.iconActive : data.icon} alt="icon" />
                        <p>{data.title}</p>
                    </div>
                    {
                        data.isExpand ?
                            <img src={isActive(data) ? IconSidebarArrowActive : IconSidebarArrow} alt="icon" /> : null
                    }
                </div>
            </Link>
            {
                isExpand(data) ?
                    <div className="sub-link-container">
                        {
                            data.data.map((data2, index) => (
                                <div key={index}>
                                    <Link
                                        onClick={() => handleToggle(data.title, data2.title, '2')}
                                        to={!data2.isExpand ? data2.path : '#'}
                                        className="sub-link">
                                        {
                                            isActive(data2) ?
                                                <div className="border-active"></div> : null
                                        }
                                        <div className="d-flex flex-row align-items-center">
                                            <div className={`indicator ${isActive(data2) ? 'indicator-active' : ''}`}></div>
                                            <p className={isActive(data2) ? `sub-link-active` : ''}>{data2.title}</p>
                                        </div>
                                        {
                                            data2.isExpand ?
                                                <img src={isActive(data2) ? IconSidebarArrowActive : IconSidebarArrow} alt="icon" /> : null
                                        }

                                    </Link>
                                    {
                                        isExpand(data2) ?
                                            data2.data.map((data3, index) => (
                                                <Link
                                                    key={index}
                                                    className="sub-link sub-link-2"
                                                    to={data3.path} >
                                                    <div className="d-flex flex-row align-items-center">
                                                        <div className={`indicator ${isActive(data3) ? 'indicator-active' : ''}`}></div>
                                                        <p className={isActive(data3) ? `sub-link-active` : ''}>{data3.title}</p>
                                                    </div>
                                                </Link>
                                            ))
                                            : null

                                    }
                                </div>
                            ))
                        }
                    </div>
                    : null
            }
        </>

    )
}

export default SidebarLink