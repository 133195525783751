import {useEffect, useRef, useState} from 'react'

const UseModal = () => {
    const [isShowModal,setIsShowModal] = useState(false)

    function useOutsideAlerter(ref) {
        useEffect(() => {
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsShowModal(false)
            }
          }
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return {
        isShowModal,
        setIsShowModal,
        wrapperRef,
    }
}

export default UseModal