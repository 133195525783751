import { Link } from "react-router-dom";
import { IconPencil } from "../../assets/icons";
import { ButtonAction } from "../../components";
import { ADDQUESTION, EDITQUESTION } from "../../routes/routesNames";

const columns = (detail) => [
  {
    name: "Pertanyaan",
    selector: (row) => row.pertanyaan,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Jawaban",
    selector: (row) => row.jawaban,
    sortable: true,
    minWidth: "200px",
    format: (row) => {
      const regex = /(<([^>]+)>)/gi;
      const jawaban = row.jawaban.replace(regex, "");
     const  jawaban2 = jawaban.replace("&nbsp;", "")
      return (
        <>{jawaban2.substring(0, 25)}...</>
      )
    }
  },
  {
    name: "Nama Produk",
    selector: (row) => row.produk.nama,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Tanaman",
    selector: (row) => row.tanaman.nama,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Kategori Produk",
    selector: (row) => row.kategori_produk.nama,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Kategori OPT",
    selector: (row) => row.kategori_penyakit.nama,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Aksi",
    selector: (row) => row.name,
    sortable: true,
    maxWidth: "200px",
    format: (row) => {
      console.log(row);
      return (
        <Link to={EDITQUESTION} state={row}>
          <ButtonAction title="Ubah" icon={IconPencil} />
        </Link>
      );

    },
  },
];

export default columns;
