import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { QUESTION } from "../../routes/routesNames";
import { UseForm } from "../../hooks";
import {
  UsePost,
  UsePut,
} from "../../hooks/api";
import { compose } from "redux";

const UseFormQuestion = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading: isLoadingPost, handlePost } = UsePost();
  const { isLoading: isLoadingPut, handlePut } = UsePut();
  const [isReady, setIsReady] = useState(false);
  const { form, setForm, content, setContent, handleChange, handleChangeRich, } = UseForm();

  useEffect(() => {
    if (props.type === 'put') {
      if (location.state) {
        setForm({
          ...location.state,
          id_produk: location.state.produk._id,
          id_tanaman: location.state.tanaman._id,
          kategori_produk_id: location.state.kategori_produk._id,
          kategori_opt_id: location.state.kategori_penyakit._id,
        });
        setContent(location.state.jawaban)
        setIsReady(true)
      } else {
        navigate(QUESTION)
      }
    }
  }, []);

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.type === "post") {
      let params = {
        url: props.url,
        form: {
          ...form,
          jawaban: content
        },
        navigation: QUESTION
      }

      handlePost(params)
    } else {
      let params = {
        url: props.url + '?_id=' + form._id,
        form: {
          ...form,
          jawaban: content,
        },
        navigation: QUESTION
      }
      console.log("params.form");
      console.log(params.form);
      handlePut(params)
    }
  };

  return {
    handleSubmit,
    form,
    content,
    navigate,
    handleChange,
    handleChangeRich,
    isLoadingPut,
    isReady,
    isLoadingPost,
  };
};

export default UseFormQuestion;
