import React from 'react'
import './select.scss'
import PropTypes from 'prop-types';

const Select = (props) => {
  return (
      <div className="form-group">
        <select
            onChange={props.onChange}
            id={props.id}
            name={props.id}  
            defaultValue={props.value}      
            className="form-select" >
            {
                props.placeHolder ? 
                <option value="">{props.placeHolder}</option> : null
            }
            {
                props.data.map((data,index) => (
                    <option key={index} value={data.id}>{data.name}</option>
                ))
            }
        </select>  
      </div>
    )
}

Select.propTypes = {
    label : PropTypes.string,
    id : PropTypes.string,
    className : PropTypes.string,
    onChange : PropTypes.func,
    data : PropTypes.array,
    value : PropTypes.string,
    placeHolder : PropTypes.string,
};

export default Select