import React,{  useState ,useEffect}  from 'react'
import { Link, useLocation } from "react-router-dom";
import { LogoTitleRight } from '../../../assets/images'
import { DASHBOARD } from '../../../routes/routesNames';
import SidebarLink from '../../molecules/sidebarLink';
import dataLinks from './data_link'
import './sidebar.scss'

const Sidebar = () => {
    const location = useLocation();
    const [links, setLinks] = useState(dataLinks);


    const handleToggle = (title,title2,level) => {
        let newArray = links
        let indexSelected = links.map((e) => { 
            return e.title ; 
        }).indexOf(title)
        if(level ==='1'){    
            newArray.map((data,index) => {
                if(index === indexSelected){
                    newArray[index] = {...newArray[index], isOpen : !data.isOpen}
                }else{
                    if(data.isExpand){
                        newArray[index] = {...newArray[index], isOpen : false}
                    }
                }
            })    
        }else{
            const newArray2 = links[indexSelected].data
            let indexSelected2 = newArray2.map((e) => { 
                return e.title ; 
            }).indexOf(title2)

            newArray2.map((data,index) => {
                if(index === indexSelected2){
                    newArray2[index] = {...newArray2[index], isOpen : !data.isOpen}
                }else{
                    if(data.isExpand){
                        newArray2[index] = {...newArray2[index], isOpen : false}
                    }
                }
            })    
        }
    }

  return (
    <div className="sidebar">
        <Link to={DASHBOARD}>
            <img src={LogoTitleRight} width={150} />
        </Link>
        <div className="spacer"></div>
        {
            links.map((data,index) => (
                <SidebarLink key={index} data={data} handleToggle={handleToggle}  />
            ))
        }
    </div>
  )
}

export default Sidebar