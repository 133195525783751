import { combineReducers } from "redux";

// Reducers
import loginReducer from "./auth";

const rootReducer = combineReducers({
  login: loginReducer,
});

export default rootReducer;
