const columns = [
  {
    name: "Nama Petani",
    selector: (row) => row.name,
    sortable: true,
    minWidth: "300px",
    format: (row, _) => {
      return (
        <div className="d-flex">
          {
            row.image ?
              <img
                className=""
                style={{ borderRadius: "16px" }}
                width={32}
                height={32}
                src={row.image} /> :
              <div></div>
          }
          &nbsp;
          <span>{row.name}</span>
        </div>
      );
    }
  },
  {
    name: "Tipe Petani",
    selector: (row) => row.type_account,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Tanaman Utama",
    selector: (row) => row.main_plant,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Nomor Telfon",
    selector: (row) => row.phone_number,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Domisili",
    selector: (row) => row.province,
    sortable: true,
    minWidth: "296px",
    format: (row, index) => {
      return (
        <div className="pb-1">
          {row.province.name}, {row.city.name}, {row.district.name},{" "}
          {row.address}
          {row.village.name}
        </div>
      );
    },
  },
];

export default columns;
