import { Button, CardContainer, Input, InputSelect, InputToggle, SelectAsync2 } from '../../components'
import UseFormChannel from './hookForm';

const EditChannelPage = () => {
    const {
        isLoadingPutDataForm,
        navigate,
        form,
        handleChange,
        handleSubmit        
    } = UseFormChannel({
        url : "/v1/channel",
        type : 'put',
    })

  return (
      <CardContainer>
        <form onSubmit={e =>  handleSubmit(e) }>
            <div className="col-lg-8 col-12">
                <Input
                    required={false}
                    value={form.thumbnail}
                    type="file"
                    id="thumbnail"
                    onChange={handleChange}
                    placeholder="Format foto/gambar .JPG, .JPEG, .PNG, maksimal 2MB, dengan aspect ratio 1 : 1." 
                    label="Foto Thumbnail" />                       
                <Input
                    value={form.chanel_name}
                    id="chanel_name"
                    onChange={handleChange}
                    placeholder="Nama Retailer" 
                    label="Nama Retailer" />    
                <InputSelect
                    value={form.type}
                    label="Jenis Retailer" 
                    id="type"
                    onChange={handleChange}
                    data={[
                        {
                            id : 'R1',
                            name : 'Retailer 1'
                        },
                        {
                            id : 'R2',
                            name : 'Retailer 2'
                        },
                    ]} 
                />    
                <InputToggle 
                    id="is_star_retailer"
                    onChange={handleChange}
                    checked={form.is_star_retailer}
                    label="Retailer Berbintang" 
                    placeholder="Beri badge Bintang pada retailer" />                                           
                <SelectAsync2 
                    value={{
                        label: form.province ?  form.province.title : '',
                        value: form.province ?  form.province._id : '',
                        name : "province"
                    }}                       
                    onChange={handleChange} 
                    labelName="name"
                    valueName="_id"
                    id="province"
                    url="/v1/province"
                    label="Provinsi"
                    placeHolder="Cari Provinsi..." />   
                {
                    form.province ? 
                    <SelectAsync2   
                        value={{
                            label: form.city ?  form.city.title : '',
                            value: form.city ?  form.city._id : '',
                            name : "city"
                        }}                                
                        onChange={handleChange} 
                        labelName="name"
                        valueName="_id"
                        id="city"
                        url="/v1/city"
                        paramsId={`?province_id=${form.province._id ? form.province._id : form.province}`}
                        label="Kabupaten/Kota"
                        placeHolder="Cari Kabupaten/Kota..." />   : null  
                }      
                {
                    form.city ? 
                    <SelectAsync2 
                        value={{
                            label: form.district ?  form.district.title : '',
                            value: form.district ?  form.district._id : '',
                            name : "district"
                        }}                       
                        onChange={handleChange} 
                        labelName="name"
                        valueName="_id"
                        id="district"
                        url="/v1/district"
                        paramsId={`?city_id=${form.city._id ? form.city._id : form.city}`}
                        label="Kecamatan"
                        placeHolder="Cari Kecamatan..." />    : null  
                }   
                {
                    form.district ? 
                    <SelectAsync2
                        value={{
                            label: form.village ?  form.village.title : '',
                            value: form.village ?  form.village._id : '',
                            name : "village"
                        }}    
                        onChange={handleChange} 
                        labelName="name"
                        valueName="_id"
                        id="village"
                        paramsId={`?district_id=${form.district._id ? form.district._id : form.district}`}
                        url="/v1/village"
                        label="Desa"
                        placeHolder="Cari Desa..." />     : null  
                }  
                <Input
                    value={form.alamat}
                    type="textarea"
                    id="alamat"
                    onChange={handleChange}
                    placeholder="Contoh: Plaza Aminta, Suite 802, 8th Floor, Jl. Let. Jend. TB Simatupang Kav. 10, Jakarta Selatan" 
                    label="Alamat" />   
                <p className="mt-2">Format penulisan alamat sesuai EYD: Nama gedung (bila ada), Nama jalan, Kelurahan/Desa, Kecamatan, Kota/Kabupaten, Provinsi, Kode Pos</p>                                                                                                                                   
            <div className="row">
                    <div className="col-12 col-md-6">
                        <Input
                            value={form.lat}
                            id="lat"
                            // type="number"
                            onChange={handleChange}
                            placeholder="Lattitude" 
                            label="Lattitude" />                                     
                    </div>
                    <div className="col-12 col-md-6">
                        <Input
                            value={form.long}
                            id="long"
                            // type="number"
                            onChange={handleChange}
                            placeholder="Longitude" 
                            label="Longitude" />                                                        
                    </div>                    
                </div>                
                <InputToggle 
                    onChange={handleChange}
                    id="isActive"
                    checked={form.isActive}
                    label="Status" 
                    placeholder="Apakah retailer ini masih active " />                                                                 
                <div className="d-flex justify-content-end">
                    <div className="me-4">
                        <Button 
                            onClick={() => navigate(-1)}
                            type="button"
                            className={`btn-light w-100 mt-4 px-5`} 
                            title="Batalkan" />
                    </div>
                    <Button 
                        type="submit"
                        className={`btn-primary w-100 mt-4 px-5`} 
                        title="Ubah" 
                        isLoading={isLoadingPutDataForm} />
                </div>
            </div>
        </form>
      </CardContainer>
  )
}

export default EditChannelPage;