import { Button, CardContainer, ImportModal, Input, InputSelectAsync, Select } from '../../../components'
import Table from '../../../components/organisms/table'
import columns from './column'
import { IconImport } from '../../../assets/icons';
import { Link } from 'react-router-dom';
import { ADDEXPERT } from '../../../routes/routesNames';
import { UseGet } from '../../../hooks/api';
import { UseTable } from '../../../hooks';
import ImportModalSecond from '../../../components/molecules/importModalSecond';

const Admin = () => {
    const {
        filter,
        isLoading,
        data,
        error,
        totalPages,
        handlePagination,
        handleLimit,
        handleFilter,
    } = UseGet('/v1/account/expert')

    const {
        isLoading: isLoadingPost,
        dataRemove,
        handleSelected,
        handleRemove
    } = UseTable();      

    return (
        <CardContainer >
            <div className="d-flex flex-row justify-content-between">
                <Input type="search" id="search" placeholder="Cari Admin" onChange={handleFilter} />
                {
                    dataRemove.length === 0 ?
                        <div className="d-flex flex-row">
                            <div className="me-2">
                                <InputSelectAsync
                                    isAll={true}
                                    value={filter.wilayah_kerja ? filter.wilayah_kerja : 'Semua Wilayah'}
                                    handleSelected={data => handleFilter({
                                        name: 'wilayah_kerja',
                                        value: data.name
                                    })}
                                    className="select-filter-table"
                                    placeHolder="Wilayah Kerja"
                                    url='/v1/domain'
                                    labelName="name"
                                />
                            </div>
                            <div className="me-2">
                                <Select
                                    id="status"
                                    onChange={handleFilter}
                                    placeHolder="Semua Status"
                                    data={[
                                        {
                                            id: 'aktif',
                                            name: 'Aktif'
                                        },
                                        {
                                            id: 'in aktif',
                                            name: 'In Aktif'
                                        },
                                    ]}
                                />
                            </div>
                            {/* <Button icon={IconImport} title="Import" className="btn btn-primary-light me-3 px-4" isSmall />                 */}
                            <Link to={ADDEXPERT}>
                                <Button title="Tambah Expert" className="btn btn-primary" isSmall />
                            </Link>
                            <ImportModalSecond
                                urlImport={`${process.env.REACT_APP_API_URL}/v1/account/expert/upload_data_expert`}
                                urlDownload={`${process.env.REACT_APP_API_URL}/v1/account/expert/download_excel`}
                                title={"Import Akun"}
                            />
                            <Link to="" target='_blank'>
                                <Button title="Export" className="btn btn-primary" isSmall onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = `${process.env.REACT_APP_API_URL}/v1/account/expert/import_data_expert`;
                                }} />
                            </Link>
                        </div>
                        :
                        <Button
                            isLoading={isLoadingPost}
                            onClick={() => handleRemove('/v1/account/expert/delete')}
                            title="Hapus Admin"
                            className="btn btn-danger" isSmall />
                }
            </div>
            <Table
                filter={filter}
                isLoading={isLoading}
                error={error}
                data={data}
                totalPages={totalPages}
                handlePagination={handlePagination}
                handleLimit={handleLimit}
                handleChangeSelect={handleSelected}
                columns={columns} />
        </CardContainer>
    )
}

export default Admin