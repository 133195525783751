import { Button, CardContainer, Input, InputSelect, InputToggle, InputWilayahKerja, SelectAsync2 } from '../../../components'
import {   EXPERT, SUPERADMIN } from '../../../routes/routesNames';
import UseFormAccount from '../hookForm';

const AddSuperAdminPage = () => {
    const {
        isLoadingPostDataForm,
        form,
        isEnable,
        navigate,
        handleChange,
        handleSubmit        
    } = UseFormAccount({
        url : "/v1/account/super",
        type : 'post',
        redirect : SUPERADMIN,
    })

  return (
      <CardContainer>
        <form onSubmit={e => isEnable ? handleSubmit(e) : e.preventDefault()}>
            <div className="col-lg-10 col-12">
                <Input
                    required={false}
                    type="file"
                    id="image"
                    onChange={handleChange}
                    placeholder="Format foto/gambar .JPG, .JPEG, .PNG, maksimal 2MB." 
                    label="Foto Profil" />                
                <Input
                    id="name"
                    onChange={handleChange}
                    placeholder="Masukan Nama Lengkap" 
                    label="Nama Lengkap" />
                <Input
                    id="email"
                    type="email"
                    onChange={handleChange}
                    placeholder="Masukan Email" 
                    label="Email" />
                <Input
                    id="phone_number"
                    onChange={handleChange}
                    placeholder="Masukan No. Telepon" 
                    label="No. Telepon" />                    
                <SelectAsync2                    
                    onChange={handleChange} 
                    labelName="name"
                    valueName="name"
                    id="jabatan"
                    url="/v1/jabatan"
                    label="Jabatan"
                    placeHolder="Cari Jabatan..." /> 
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <Input
                            type="password"
                            id="password"
                            onChange={handleChange}
                            placeholder="Password" 
                            label="Password" />           
                    </div>
                    <div className="col-12 col-lg-6">
                        <Input
                            type="password"
                            id="passwordConfirmation"
                            onChange={handleChange}
                            placeholder="Password Confirmation" 
                            label="Password Confirmation" />                                   
                    </div>    
                </div>
                <InputToggle 
                    id="isActive"   
                    onChange={handleChange}
                    checked={form.isActive}
                    label="Jadikan Status Active" 
                    placeholder="Menjadikan status akun active" />                                     
                <div className="d-flex justify-content-end">
                    <div className="me-4">
                        <Button 
                            onClick={() => navigate(-1)}
                            type="button"
                            className={`btn-light w-100 mt-4 px-5`} 
                            title="Batalkan" />
                    </div>
                    <Button 
                        type="submit"
                        className={`${isEnable  ? 'btn-primary' : 'btn-secondary'}  w-100 mt-4 px-5`} 
                        title="Simpan" 
                        isLoading={isLoadingPostDataForm} />
                </div>
            </div>
        </form>
      </CardContainer>
  )
}

export default AddSuperAdminPage;