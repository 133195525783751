import React from 'react'
import PropTypes from 'prop-types';

export default function RadioButton(props) {
    return (
        <div className={`form-group form-group-radio mt-3 ${props.className}`}>
            <label>{props.label}</label>
            <div className={props.orientation === "horizontal"?"d-flex flex-row":"d-flex flex-column"}>
                {
                    props.data.map((data,index) => (
                        <div className="form-check my-2" key={index}>
                            <input 
                                checked={data.value === props.value}
                                onChange={props.onChange}
                                className="form-check-input" 
                                type="radio" 
                                value={data.value}
                                name={props.id} 
                                id={data.value} />
                            <label 
                                className="form-check-label" 
                                htmlFor={data.value}>
                                {data.name}
                            </label>
                        </div>
                    ))
                }

            </div>
        </div>    
    )
}

RadioButton.propTypes = {
    label : PropTypes.string,
    id : PropTypes.string,
    data : PropTypes.array,
    value : PropTypes.string,
    className : PropTypes.string,
    onChange : PropTypes.func,
    required : PropTypes.bool,
};
RadioButton.defaultProps = {
    required : true,
}
