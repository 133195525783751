import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import './selectMultiple.scss'
import Checkbox from '../checkbox';
import { IconArrowDown, IconArrowUp } from '../../../assets/icons';
import { UseModal } from '../../../hooks';

const SelectMultipleCheckbox = (props) => {
    const {
        isShowModal,
        setIsShowModal,
        wrapperRef,
    } = UseModal()

    const [dataSelected,setDataSelected] = useState([])
    const [isUpdate,setIsUpdate] = useState(false)

    useEffect(() => {
        if(props.value){
            setDataSelected(props.value)
        }
    },[props.value]);

    const handleChange = e => {
        const {value,checked} = e.target

        if(checked){
            let dataTemp = dataSelected ;

            dataTemp.push(value);
            setDataSelected(dataTemp)
            setIsUpdate(!isUpdate)
            props.onChange({
                name : props.id,
                value : dataTemp
            })
        }else{
            let dataTemp = [];

            dataSelected.map((data) => {
                if(data !== value){
                    dataTemp.push(data)
                }
            })
            setDataSelected(dataTemp);
            setIsUpdate(!isUpdate)
            props.onChange({
                name : props.id,
                value : dataTemp
            })
        }
    }

    const onRemoveItem = value => {
        let dataTemp = [];

        dataSelected.map((data) => {
            if(data !== value){
                dataTemp.push(data)
            }
        })
        setDataSelected(dataTemp);
        props.onChange({
            name : props.id,
            value : dataTemp
        })

    }

    return (
    <div className="position-relative" ref={wrapperRef}>
        <div className={`form-group form-select-check-mulitple ${props.label ? 'mt-3' : ''}`} >
            {
                props.label ?
                <label htmlFor={props.id}>{props.label}</label> : null
            }
            <div className="position-relative" style={{
                height: '48px',
            }}>
                <div className="selected-card">
                {
                    dataSelected.length ?
                    dataSelected.map((data,index) => (
                        <span key={index} className={`label-card cursor-pointer ${props.typeLabel}`} onClick={() => onRemoveItem(data)} >
                            <span>{data}</span>  <span className="ps-2">x</span>
                        </span>  
                    )) :
                    <span className="span-placeholder">{props.placeHolder}</span>
                }                
                </div>
                <div className="form-control form-control-multiple cursor-pointer"  onClick={() => setIsShowModal(!isShowModal)}>
                </div>
            </div>
        </div>
        <img src={isShowModal ? IconArrowUp : IconArrowDown} alt="" className="icon-arrow cursor-pointer" onClick={() => setIsShowModal(!isShowModal)} />
        {
            isShowModal ? 
            <div>
                 <div className="modal-form-select">
                    <div className="container-select-item" >
                        {
                            props.data.map((data,index) => (
                                <label 
                                    key={index} 
                                    className="d-flex justify-content-between"  >
                                    <span>{data}</span>
                                    <Checkbox 
                                        checked={dataSelected.includes(data)}
                                        id={props.id} 
                                        name={props.id} 
                                        value={data} 
                                        onChange={(e) => handleChange(e)} />
                                </label>
                            ))
                        }
                    </div>
                </div>
            </div>
            : null
        }
    </div>
    )
}

SelectMultipleCheckbox.propTypes = {
    label : PropTypes.string,
    id : PropTypes.string,
    value : PropTypes.any,
    placeHolder : PropTypes.string,
    className : PropTypes.string,
    onChange : PropTypes.func,
    typeLabel : PropTypes.string,
    data : PropTypes.array,
};
SelectMultipleCheckbox.defaultProps = {
    placeHolder : '',
}


export default SelectMultipleCheckbox