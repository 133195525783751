import { useNavigate } from 'react-router-dom'
import { Button, CardContainer, Input, InputDomisili, SelectAsync2 } from '../../../components'
import { REGIONAL } from '../../../routes/routesNames'
import UseFormDomisili from '../hookForm'

const AddRegionalCategory = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        form,
        handleChange,
        isLoadingPost,
    } = UseFormDomisili({
        type : 'post',
        redirect : REGIONAL,
        url : '/v1/domain',
        formType : 'regional'
    })

    const isEnable = form.name && form.domisiliSelected.length  ;

  return (
      <CardContainer>
        <form onSubmit={e => isEnable ? handleSubmit(e) : e.preventDefault()}>
            <div className="col-lg-10 col-12">
                <Input
                    id="name"
                    onChange={handleChange}
                    placeholder="Masukan Regional" 
                    label="Regional" />
                <InputDomisili
                    id="domisiliSelected"
                    onChange={handleChange}
                    label="Domisili" />
                {/* <SelectAsync2 
                    onChange={handleChange} 
                    labelName="name"
                    valuename="_id"
                    id="province_id"
                    url="/v1/domain"
                    label="Provinsi"
                    placeHolder="Cari Provinsi..." />                        */}
                <div className="d-flex justify-content-end">
                    <div className="me-4">
                        <Button 
                            onClick={() => navigate(-1)}
                            type="button"
                            className={`btn-light w-100 mt-4 px-5`} 
                            title="Batalkan" />
                    </div>
                    <Button 
                        type="submit"
                        className={`${isEnable  ? 'btn-primary' : 'btn-secondary'}  w-100 mt-4 px-5`} 
                        title="Simpan" 
                        isLoading={isLoadingPost} />
                </div>
            </div>
        </form>
      </CardContainer>
  )
}

export default AddRegionalCategory