import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './button.scss'

export default  function Button (props) {
    const [visibleTooltips, setVisibleTooltips] = useState(false);

    return (
        <div >
            <button 
                disabled={props.disabled}
                onClick={props.onClick}
                type={props.type} 
                className={`btn  ${props.isSmall ? 'btn-small' : ''}  ${props.noPadding ? 'btn-no-padding' : ''}  ${props.className} d-flex justify-content-center`}>
                    {
                        props.isLoading ? 
                        <div className="loader-mini"></div> : 
                        <>
                            {
                                props.icon !== '' ?
                                <img src={props.icon} alt="icon" /> : null
                            }
                            <span  
                                onMouseEnter={() => setVisibleTooltips(true)}
                                onMouseLeave={() => setVisibleTooltips(false)}
                                className={props.icon !=='' ? 'ms-3' : ''}>{props.title}
                            </span>   
                        </>
                    } 
            </button>
            {
                visibleTooltips ? 
                props.tooltips ?
                <div className="tooltips">
                    <p className="mb-0">{props.tooltips}</p>
                </div>  : null : null
            }
        </div>
    )
}

Button.propTypes = {
    tooltips : PropTypes.string,
    title : PropTypes.any,
    type : PropTypes.string,
    className : PropTypes.string,
    onClick : PropTypes.func,
    isLoading : PropTypes.bool,
    isSmall : PropTypes.bool,
    noPadding : PropTypes.bool,
    icon : PropTypes.any,
    disabled :  PropTypes.bool,
};

Button.defaultProps = {
    isLoading : false,
    isSmall : false,
    noPadding : false,
    disabled : false,
    icon : '',
}