import { Button, CardContainer, Input, InputSelect, InputToggle, SelectAsync2 } from '../../components'
import UseFormPlantParts from './hookForm';

const EditChannelPage = () => {
    const {
        isLoadingPut,
        navigate,
        form,
        handleChange,
        handleSubmit        
    } = UseFormPlantParts({
        url : "/v1/plantParts",
        type : 'put',
    })

  return (
      <CardContainer>
        <form onSubmit={e =>  handleSubmit(e) }>
            <div className="col-lg-8 col-12">                    
                <Input
                    value={form.name}
                    id="name"
                    onChange={handleChange}
                    placeholder="Nama Tanaman" 
                    label="Nama Tanaman" />                                                                  
                <div className="d-flex justify-content-end">
                    <div className="me-4">
                        <Button 
                            onClick={() => navigate(-1)}
                            type="button"
                            className={`btn-light w-100 mt-4 px-5`} 
                            title="Batalkan" />
                    </div>
                    <Button 
                        type="submit"
                        className={`btn-primary w-100 mt-4 px-5`} 
                        title="Ubah" 
                        isLoading={isLoadingPut} />
                </div>
            </div>
        </form>
      </CardContainer>
  )
}

export default EditChannelPage;