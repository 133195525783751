import UseGet from './get'
import UsePost from './post'
import UsePostDataForm from './postDataForm'
import UsePut from './put'
import UsePutDataForm from './putDataForm'
import UseGetParamsById from './getByParamsId'

export {
    UseGetParamsById,
    UseGet,
    UsePost,
    UsePut,
    UsePutDataForm,
    UsePostDataForm
}