import React from 'react';
import PropTypes from 'prop-types';
import './table.scss';
import DataTable from 'react-data-table-component';
import Pagination from '../../molecules/pagination';
import Select from '../../atoms/select';
import Loader from '../../atoms/loader';

export default function Table(props) {
    return (
        <div className="mt-4">
            <div className="table-wrap table-fixed-2">
                {
                    props.isLoading ?
                        <Loader /> :
                        props.error === '' ?
                            <>
                                <DataTable
                                    selectableRows
                                    onSelectedRowsChange={props.handleChangeSelect}
                                    columns={props.columns}
                                    footer={props.columns}
                                    data={props.data}
                                    persistTableHead
                                />
                            </>
                            :
                            <p className="text-center">{props.error}</p>
                }


            </div>
            <div className="mt-3 d-flex justify-content-between mt-3" style={{
                zIndex: 0,
                position: 'relative',
            }}>
                <Select
                    onChange={props.handleLimit}
                    value={props.filter.limit}
                    data={[
                        {
                            id: 10,
                            name: '10'
                        },
                        {
                            id: 20,
                            name: '20'
                        },
                        {
                            id: 30,
                            name: '30'
                        },
                    ]} />
                {
                    props.error === '' && props.totalPages > 1 ?
                        <Pagination
                            currentPage={props.filter.page - 1}
                            pageCount={props.totalPages}
                            handlePaginate={props.handlePagination} />
                        : null
                }
            </div>
        </div>
    )
}

Table.propTypes = {
    columns: PropTypes.array,
    url: PropTypes.string,
    urlParams: PropTypes.string,
    isUpdate: PropTypes.bool,
    init: PropTypes.bool,
    handleChangeSelect: PropTypes.func,
    handlePagination: PropTypes.func,
    handleLimit: PropTypes.func,
    filter: PropTypes.any,
    data: PropTypes.any,
    totalPages: PropTypes.number,
    totalData: PropTypes.number,
};
Table.defaultProps = {
    urlParams: '',
}