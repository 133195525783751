import { Button, CardContainer, Input } from '../../components'
import Table from '../../components/organisms/table'
import columns from './column'
import { UseGet } from '../../hooks/api';
import { UseTable } from '../../hooks';

const Symptom = () => {    
    const {
        filter,
        isLoading,
        data,
        error,
        totalPages,
        handlePagination,
        handleLimit,
        handleFilter,
    } = UseGet('/v1/askAndQuestion' )

    const {
        isLoading : isLoadingPost ,
        dataRemove,
        handleSelected,
        handleRemove
    } = UseTable();

  return (
      <CardContainer >
            <div className="d-flex flex-row justify-content-between">
                <Input type="search" id="search"  placeholder="Cari Tanaman" onChange={handleFilter} />
                {
                    dataRemove.length === 0  ? 
                   null
                    : 
                    <Button 
                        isLoading={isLoadingPost} 
                        onClick={() =>  handleRemove('/v1/plantParts/delete')}  
                        title="Hapus Bagian Tanaman" 
                        className="btn btn-danger" isSmall />     
                }
            </div>
            <Table 
                filter={filter}
                isLoading={isLoading}
                error={error}
                data={data}
                totalPages={totalPages}
                handlePagination={handlePagination}
                handleLimit={handleLimit}
                handleChangeSelect={handleSelected} 
                columns={columns}/>  
      </CardContainer>
  )
}

export default Symptom