import { Alert, Button, CardContainer, Input, InputSelect, InputTags, InputToggle, RadioButton, RichText, SelectMultipleCheckbox, SelectMultipleCheckboxStatic } from '../../../components'
import { DateToday } from '../../../helpers';
import UseFormArticle from './hookForm';
import parse from 'html-react-parser';

const EditArticle = () => {
    const {
        isLoadingPutDataForm,
        form,
        content,
        isEnable,
        navigate,
        handleChange,
        handleChangeRich,
        handleSubmit,
        isReady,
    } = UseFormArticle({
        type: 'put',
    })



    if (!isReady) {
        return <p>Loading..</p>
    }

    return (
        <CardContainer>
            <form className="row" onSubmit={e => isEnable ? handleSubmit(e) : e.preventDefault()}>
                <div className="col-lg-7 col-12" style={{
                    height: '74vh',
                    overflowY: 'scroll'
                }}>
                    <Input
                        value={form.thumbnail}
                        type="file"
                        id="thumbnail"
                        onChange={handleChange}
                        placeholder="Format foto/gambar .JPG, .JPEG, .PNG, maksimal 2MB, dengan aspect ratio 1 : 1."
                        label="Foto Thumbnail" />
                    <Input
                        value={form.title}
                        id="title"
                        onChange={handleChange}
                        placeholder="Masukan judul artikel"
                        label="Judul" />
                    <Input
                        value={form.penerbit}
                        id="penerbit"
                        onChange={handleChange}
                        placeholder="Masukan penulis artikel"
                        label="Penulis" />
                    <SelectMultipleCheckboxStatic
                        value={form.jenis_opt}
                        id="jenis_opt"
                        placeHolder="Pilih jenis opt"
                        onChange={handleChange}
                        data={[
                            'hama',
                            'gulma',
                            'penyakit',
                        ]}
                        label="Jenis OPT"
                        typeLabel="label-info" />
                    <SelectMultipleCheckbox
                        value={form.jenis_tanaman}
                        id="jenis_tanaman"
                        placeHolder="Pilih jenis tanaman"
                        onChange={handleChange}
                        url="/v1/kategori?category_type=tanaman"
                        label="Jenis Tanaman"
                        typeLabel="label-info" />
                    <InputTags
                        value={form.tag}
                        id="tag"
                        onChange={handleChange}
                        placeholder="Masukan tag"
                        label="Tag" />
                    <InputSelect
                        label="Status"
                        id="status"
                        value={form.status}
                        onChange={handleChange}
                        data={[
                            {
                                id: 'arsip',
                                name: 'Arsip'
                            },
                            {
                                id: 'publish',
                                name: 'Publish'
                            }
                        ]}
                    />
                    {
                        form.status === "publish" ?
                            <>
                                <RadioButton
                                    className="mt-4"
                                    data={[
                                        {
                                            name: "Sekarang",
                                            value: "sekarang"
                                        },
                                        {
                                            name: "Jadwalkan",
                                            value: "jadwalkan"
                                        }
                                    ]}
                                    value={form.terbitkan}
                                    id="terbitkan"
                                    label="Terbitkan"
                                    onChange={handleChange} />
                                {
                                    form.terbitkan === 'sekarang' ?
                                        < ></> :
                                        < div className="row">
                                            <div className="col-lg-6 col-12">
                                                <Input
                                                    min={DateToday()}
                                                    type="date"
                                                    id="tanggal_publish"
                                                    onChange={handleChange}
                                                    placeholder="Pilih tanggal"
                                                    label="Tanggal" />
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <Input
                                                    type="time"
                                                    id="waktu_publish"
                                                    onChange={handleChange}
                                                    placeholder="Pilih waktu"
                                                    label="Waktu" />
                                            </div>
                                        </div>
                                }
                                <InputToggle
                                    id="is_trending"
                                    onChange={handleChange}
                                    checked={form.is_trending}
                                    label="Jadikan Trending"
                                    placeholder="Artikel akan tampil pada halaman awal aplikasi" />
                            </>
                            : <></>
                    }

                    <RichText value={content} onChange={handleChangeRich} label="Deskripsi" id="desc" />

                    <div className="d-flex justify-content-end">
                        <div className="me-4">
                            <Button
                                onClick={() => navigate(-1)}
                                type="button"
                                className={`btn-light w-100 mt-4 px-5`}
                                title="Batalkan" />
                        </div>
                        <Button
                            type="submit"
                            className={`${isEnable ? 'btn-primary' : 'btn-secondary'}  w-100 mt-4 px-5`}
                            title="Ubah"
                            isLoading={isLoadingPutDataForm} />
                    </div>
                </div>
                <div className="col-lg-5 col-12" style={{
                    height: '74vh',
                    overflowY: 'scroll'
                }}>
                    <h5 className="mb-3">Pratinjau</h5>
                    <h2 className="mb-3">{form.title}</h2>
                    {
                        form.thumbnail ?
                            typeof form.thumbnail !== 'string' ?
                                <img
                                    className="mb-3 w-100"
                                    src={URL.createObjectURL(form.thumbnail)}
                                    alt="thumbnail" /> :
                                <img
                                    className="mb-3 w-100"
                                    src={form.thumbnail}
                                    alt="thumbnail" /> : null
                    }
                    {content ? parse(content) : ''}
                </div>
            </form>
        </CardContainer>
    )
}

export default EditArticle