import ReactPaginate from 'react-paginate';
import './pagination.scss'

const Pagination = ({pageCount,handlePaginate,currentPage}) => {
    return(
        <ReactPaginate
        forcePage={currentPage}
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={handlePaginate}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}/>  
    )
}

export default Pagination