import React from 'react';
import { Button, CardContainer, Input } from '../../../components'
import Table from '../../../components/organisms/table'
import columns from './column'
import { Link } from 'react-router-dom';
import { ADDHTANAMANCATEGORY, ADDPENYAKITCATEGORY, TANAMANCATEGORY } from '../../../routes/routesNames';
import { UseGet } from '../../../hooks/api';
import { UseTable } from '../../../hooks';

const PenyakitCategory = () => {    
    const {
        filter,
        isLoading,
        data,
        error,
        totalPages,
        handlePagination,
        handleLimit,
        handleFilter,
    } = UseGet('/v1/kategori?category_type=tanaman' )

    const {
        isLoading : isLoadingPost ,
        dataRemove,
        handleSelected,
        handleRemove
    } = UseTable();

  return (
      <CardContainer >
            <div className="d-flex flex-row justify-content-between">
                <Input type="search" id="search" placeholder="Cari Kategori" onChange={handleFilter} />
                {
                    dataRemove.length === 0  ? 
                    <Link to={ADDHTANAMANCATEGORY}>
                        <Button title="Tambah Tanaman" className="btn btn-primary" isSmall />                
                    </Link> :
                    <Button 
                        isLoading={isLoadingPost} 
                        onClick={() =>  handleRemove('/v1/kategori/delete')}  
                        title="Hapus Tanaman" 
                        className="btn btn-danger" isSmall />                
                }
            </div>
            <Table 
                filter={filter}
                isLoading={isLoading}
                error={error}
                data={data}
                totalPages={totalPages}
                handlePagination={handlePagination}
                handleLimit={handleLimit}
                handleChangeSelect={handleSelected} 
                columns={columns} 
                isUpdate={filter.isUpdate} />   
      </CardContainer>
  )
}

export default PenyakitCategory