import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Header, Sidebar } from "./components";
import Navigation from "./routes/navigation";

const App = () => {
  const [pathName, setPathName] = useState("");
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split("/");
    setPathName(path[1]);

  }, [location]);


  return (
    <div className="d-flex flex-row">
      {pathName !== "auth" ? <Sidebar /> : null}
      <div className={`d-flex flex-column ${pathName !== "auth" ? 'content-wrapper' : ''}`}>
        {pathName !== "auth" ? <Header /> : null}
        <div className={`scroll-container ${pathName === "auth" ? 'scroll-container-auth' : ''}`}>
          <Navigation />          
        </div>
      </div>
    </div>
  );
};

export default App;
