import React from "react";
import { useSelector } from "react-redux";
import { loginData } from "../stores/auth";
import { Navigate } from "react-router-dom";
import { DASHBOARD, LOGIN } from "../routes/routesNames";

const ProtectedRoute = ({ children, isLogin }) => {
  const dataLogin = useSelector(loginData);

  if (isLogin) {
    return dataLogin.isLogin ? children : <Navigate to={LOGIN} />;
  } else {
    return !dataLogin.isLogin ? children : <Navigate to={DASHBOARD} />;
  }
};

export default ProtectedRoute;
