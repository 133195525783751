import { Button, CardContainer, Input , Select} from '../../components'
import Table from '../../components/organisms/table'
import columns from './column'
import { Link } from 'react-router-dom';
import { UseGet } from '../../hooks/api';
import { UseTable } from '../../hooks';

const Consultation = () => {
  const {
    filter,
    isLoading,
    data,
    error,
    totalPages,
    handlePagination,
    handleLimit,
    handleFilter,
  } = UseGet('/v1/consultation')

  const {
    isLoading: isLoadingPost,
    dataRemove,
    handleSelected,
    handleRemove
  } = UseTable();

  return (
    <CardContainer >
      <div className="d-flex flex-row justify-content-between">
        <Input type="search" id="search" placeholder="Cari Konsultasi" onChange={handleFilter} />
        {
          dataRemove.length === 0 ?
            <div className="d-flex flex-row">
              <div className="me-2">
                <Select
                  id="status"
                  onChange={handleFilter}
                  placeHolder="Semua Status"
                  data={[
                    {
                      id: 'Selesai',
                      name: 'Selesai'
                    },
                    {
                      id: 'Sedang berlangsung',
                      name: 'Sedang berlangsung'
                    },
                  ]}
                />
              </div>
              {/* <Link to="" target='_blank'>
                <Button title="Export" className="btn btn-primary" isSmall onClick={(e) => {
                  e.preventDefault();
                  window.location.href = `${process.env.REACT_APP_API_URL}/v1/account/customer/import_data_user`;
                }} />
              </Link> */}
            </div>
            :
            <Button
              isLoading={isLoadingPost}
              onClick={() => handleRemove('/v1/consultation/delete')}
              title="Hapus Konsultasi"
              className="btn btn-danger" isSmall />
        }
      </div>
      <Table
        filter={filter}
        isLoading={isLoading}
        error={error}
        data={data}
        totalPages={totalPages}
        handlePagination={handlePagination}
        handleLimit={handleLimit}
        handleChangeSelect={handleSelected}
        columns={columns} />
    </CardContainer>
  )
}

export default Consultation