import { Button, CardContainer, Input } from '../../components'
import Table from '../../components/organisms/table'
import columns from './column'
import { UseGet } from '../../hooks/api';
import { UseTable } from '../../hooks';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ADDQUESTION, QUESTION } from '../../routes/routesNames';
import { Container } from 'react-bootstrap';
import { IconArrowLeft } from '../../assets/icons';
import ImportModalSecond from '../../components/molecules/importModalSecond';

const Question = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!location.state) {
            navigate(QUESTION);
        }
    }, []);


    const {
        filter,
        isLoading,
        data,
        error,
        totalPages,
        handlePagination,
        handleLimit,
        handleFilter,
    } = UseGet(`/v1/pertanyaan`)

    const {
        isLoading: isLoadingPost,
        dataRemove,
        handleSelected,
        handleRemove
    } = UseTable();

    return (
        <div>
            <CardContainer >
                <div className="d-flex flex-row justify-content-between">
                    <Input type="search" id="search" placeholder="Cari Q&A" onChange={handleFilter} />
                    {
                        dataRemove.length === 0 ?
                            <div className="d-flex flex-row">
                                <ImportModalSecond
                                    urlImport={`${process.env.REACT_APP_API_URL}/v1/pertanyaan/upload_excel`}
                                    urlDownload={`${process.env.REACT_APP_API_URL}/v1/pertanyaan/download_excel`}
                                    title={"Import"}
                                />
                                <Link to={ADDQUESTION} state={location.state} >
                                    <Button title="Tambah Pertanyaan" className="btn btn-primary" isSmall />
                                </Link>

                            </div>
                            :
                            <Button
                                isLoading={isLoadingPost}
                                onClick={() => handleRemove('/v1/pertanyaan/delete')}
                                title="Hapus Pertanyaan"
                                className="btn btn-danger" isSmall />
                    }
                </div>
                <Table
                    filter={filter}
                    isLoading={isLoading}
                    error={error}
                    data={data}
                    totalPages={totalPages}
                    handlePagination={handlePagination}
                    handleLimit={handleLimit}
                    handleChangeSelect={handleSelected}
                    columns={columns(location.state)} />
            </CardContainer>
        </div>
    )
}

export default Question