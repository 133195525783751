import { Link } from "react-router-dom";
import { IconPencil } from "../../assets/icons";
import { ButtonAction, Dropdown } from "../../components";
import { EDITARTICLE, EDITPRODUCT } from "../../routes/routesNames";
import parse from "html-react-parser";

const columns = [
  {
    name: "Produk",
    selector: (row) => row.product_name,
    sortable: true,
    minWidth: "300px",
  },
  {
    name: "Kategori",
    selector: (row) => row.kategori,
    sortable: true,
    minWidth: "200px",
  },

  {
    name: "Tanaman",
    selector: (row) => row.jenis_tanaman,
    sortable: true,
    width: "200px",
    format: (row, index) => {
      return (
        <div className="pb-1 w-100">
          {row.jenis_tanaman &&
            row.jenis_tanaman.map((data, index) => (
              <span key={index} className="label-info">
                {data}{" "}
              </span>
            ))}
        </div>
      );
    },
  },
  {
    name: "Category Hama",
    selector: (row) => row.all_categori_hama,
    sortable: true,
    width: "200px",
    format: (row, index) => {
      return (
        <div className="pb-1 w-100">
          {row.all_categori_hama &&
            row.all_categori_hama.map((data, index) => (
              <span key={index} className="label-info">
                {data.name}{" "}
              </span>
            ))}
        </div>
      );
    },
  },
  {
    name: "Category Gulma",
    selector: (row) => row.all_categori_gulma,
    sortable: true,
    width: "200px",
    format: (row, index) => {
      return (
        <div className="pb-1 w-100">
          {row.all_categori_gulma &&
            row.all_categori_gulma.map((data, index) => (
              <span key={index} className="label-info">
                {data.name}{" "}
              </span>
            ))}
        </div>
      );
    },
  },
  {
    name: "Category Penyakit",
    selector: (row) => row.all_categori_penyakit,
    sortable: true,
    width: "200px",
    format: (row, index) => {
      return (
        <div className="pb-1 w-100">
          {row.all_categori_penyakit &&
            row.all_categori_penyakit.map((data, index) => (
              <span key={index} className="label-info">
                {data.name}{" "}
              </span>
            ))}
        </div>
      );
    },
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    format: (row, index) => {
      return (
        <div className="d-flex flex-row">
          <div
            className={
              row.status === "draft" ? `label-warning` : "label-success"
            }
          >
            {row.status}
          </div>
          {row.is_trending ? (
            <div className="label-danger">Trending</div>
          ) : null}
        </div>
      );
    },
  },
  {
    name: "Aksi",
    selector: (row) => row._id,
    sortable: true,
    format: (row, index) => {
      return (
        <Link to={EDITPRODUCT} state={row}>
          <ButtonAction title="Ubah" icon={IconPencil} />
        </Link>
      );
    },
  },
];

export default columns;
