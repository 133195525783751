import React, { useState,useEffect } from 'react';
import { IconArrowLeft } from '../../../assets/icons';
import {Input,Button} from '../../../components';
import { UsePost } from '../../../hooks/api';
import { FORGOT } from '../../../routes/routesNames';

const ForgotPage = () => {
    const {
        isLoading,
        error,
        isSuccess,
        navigate,
        handlePost
    } = UsePost()
    const [form,setForm] = useState({
        link : `${process.env.REACT_APP_BASE_URL}/auth/forgot`,
    })

    const handleChange = e => {
        const {value,id} = e.target
        setForm({
            ...form,
            [id] : value
        })
    }
    const handleSubmit = e => {
        e.preventDefault()
        let params = {
            url : '/v1/auth/send_email_reset_password',
            form : form,
            navigation : FORGOT
        }
        handlePost(params)
    }

  return (
    <div className="auth">
        {
            isSuccess ?
            <div className="auth-card">
                <div className="auth-back d-flex flex-row justify-content-start w-100">
                    <div className="pointer" onClick={() => navigate(-1)}>
                        <img src={IconArrowLeft} alt="" />
                        <span>Kembali</span>
                    </div>
                </div>
                <h1>Silahkan Cek Email Anda</h1>
                <p className="sub-title mb-0">Kami telah mengirimkan intruksi melakukan atur ulang password melalui Email ke <strong>{form.email}</strong> .</p>
            </div>
            :
            <div className="auth-card">
                <div className="auth-back d-flex flex-row justify-content-start w-100">
                    <div className="pointer" onClick={() => navigate(-1)}>
                        <img src={IconArrowLeft} alt="" />
                        <span>Kembali</span>
                    </div>
                </div>
                <h1>Lupa Password</h1>
                <p className="sub-title">Masukkan nomor telepon yang terdaftar. Kami akan mengirimkan kode verifikasi untuk atur ulang kata sandi.</p>
                <form onSubmit={e => handleSubmit(e)}>
                    {/* {
                        error !== "" ?
                        <Alert type="danger" message={error}  /> : 
                        null
                    } */}
                    <Input id="email" label="Email" onChange={handleChange} placeholder="Masukan email" type="email" />
                    <Button className="btn-primary w-100 mt-4" title="Selanjutnya" isLoading={isLoading} />
                </form>
            </div>
        }
       
    </div>
  )
}

export default ForgotPage