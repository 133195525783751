import { Link } from "react-router-dom";
import { IconPencil } from "../../../assets/icons";
import { EDITKATEGORIMARKETPLACE } from "../../../routes/routesNames";
import { ButtonAction } from "../../../components";

const columns = (detail) => [
    {
        name: "Kategori",
        selector: (row) => row.name,
        sortable: true,
        minWidth: "200px",
    },
    {
        name: "Aksi",
        selector: (row) => row.name,
        sortable: true,
        maxWidth: "200px",
        format: (row) => {
            console.log(row);
            return (
                <Link to={EDITKATEGORIMARKETPLACE} state={row}>
                    <ButtonAction title="Ubah" icon={IconPencil} />
                </Link>
            );

        },
    },
]

export default columns