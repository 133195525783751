import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Input from '../input';
import { loginData } from '../../../stores/auth';
import { IconArrowDown, IconArrowUp } from '../../../assets/icons';
import { axios } from '../../../helpers';
import Loader from '../../atoms/loader';

const InputSelectAsync = (props) => {
    const dataLogin = useSelector(loginData);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const [isShowModal, setIsShowModal] = useState(false)
    const [filter, setFilter] = useState({
        page: 1,
        limit: 1000,
        search: '',
    })
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsShowModal(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useEffect(() => {
        let operator = "?";
        if (props.url.includes('?')) {
            operator = '&'
        }

        fetchData(`${props.url}${operator}limit=${filter.limit}&search=${filter.search}`)
    }, [filter]);


    // handle
    const fetchData = async (url) => {
        setIsLoading(true)
        try {
            const response = await axios.get(url, {
                headers: { Authorization: `Bearer ${dataLogin.data.token}` },
            });
            if (response.data.status) {
                setData(response.data.data)
            } else {
                setData([])
            }
        } catch (err) {
            // swal("Gagal",errorResponse(err), "error");
        } finally {
            setIsLoading(false)
        }
    }
    const handleSearch = e => {
        setFilter({
            ...filter,
            search: e.target.value
        })
    }
    const handleSelected = data => {
        setIsShowModal(false)
        props.handleSelected(data)
    }

    return (
        <div className={`position-relative ${props.className}`} ref={wrapperRef}>
            <div className={`form-group form-select-check-mulitple ${props.label ? 'mt-3' : ''}`}  >
                {
                    props.label ?
                        <label htmlFor={props.id}>{props.label}</label> : null
                }
                <div className="form-control  cursor-pointer" onClick={() => setIsShowModal(!isShowModal)} >
                    {
                        props.value ?
                            <span>{props.value}</span> :
                            <span className="span-placeholder">{props.placeHolder}</span>
                    }
                </div>
            </div>
            <img src={isShowModal ? IconArrowUp : IconArrowDown} alt="" className="icon-arrow " onClick={() => setIsShowModal(!isShowModal)} />
            {
                isShowModal ?
                    <div>
                        <div className="modal-form-select">
                            <Input type="search" placeholder={props.placeHolder} onChange={handleSearch} />
                            <div className="container-select-item" >
                                {
                                    isLoading ?
                                        <Loader /> :
                                        data.length ?
                                            <>
                                                {
                                                    props.isAll ?
                                                        <label
                                                            onClick={() => handleSelected({
                                                                name: '',
                                                                id: ''
                                                            })}
                                                            className="d-flex justify-content-between cursor-pointer"  >
                                                            <span>Semua</span>
                                                        </label>
                                                        : null
                                                }
                                                {
                                                    data.map((data, index) => (
                                                        <label
                                                            onClick={() => handleSelected(data)}
                                                            key={index}
                                                            className="d-flex justify-content-between cursor-pointer"  >
                                                            <span>{data[props.labelName]}</span>
                                                        </label>
                                                    ))
                                                }
                                            </>
                                            :
                                            <p className="text-center">Tidak ada data</p>
                                }
                            </div>
                        </div>
                    </div>
                    : null
            }
        </div>
    )
}

InputSelectAsync.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.any,
    placeHolder: PropTypes.string,
    labelName: PropTypes.string,
    handleSelected: PropTypes.func,
    className: PropTypes.string,
    onChange: PropTypes.func,
    url: PropTypes.string,
    isAll: PropTypes.bool,
};
InputSelectAsync.defaultProps = {
    placeHolder: '',
    isAll: false,
}

export default InputSelectAsync