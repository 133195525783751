import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import swal from 'sweetalert';
import { IconArrowLeft } from '../../../assets/icons';
import {Input,Button} from '../../../components';
import { UsePost } from '../../../hooks/api';
import { LOGIN } from '../../../routes/routesNames';

const ForgotPasswordPage = () => {

    const location = useLocation();

    const {
        isLoading,
        error,
        isSuccess,
        navigate,
        handlePost
    } = UsePost()

    const urlSplit = location.pathname.split("/");
    const token = urlSplit[3]
    const [form,setForm] = useState({
        password : '',
        passwordConfirmation : '',
    })


    const handleChange = e => {
        const {value,id} = e.target
        setForm({
            ...form,
            [id] : value
        })
    }

    const handleSubmit = e => {
        e.preventDefault()
        if(form.password === form.passwordConfirmation){
            let params = {
                url : '/v1/auth/reset_password',
                form : {...form, token : token },
                navigation : LOGIN
            }
            handlePost(params)
        }else{
            swal('Gagal','Password Konfirmasi Tidak Sama','error')
        }
    }
  return (
    <div className="auth">
        <div className="auth-card">
            <div className="auth-back d-flex flex-row justify-content-start w-100">
                <div className="pointer" onClick={() => navigate(-1)}>
                    <img src={IconArrowLeft} alt="" />
                    <span>Kembali</span>
                </div>
            </div>
            <h1>Atur Ulang Password</h1>
            <p className="sub-title">Password baru harus berbeda dengan password lama Anda</p>
            <form onSubmit={e => handleSubmit(e)}>
                <Input id="password" label="Password" onChange={handleChange} placeholder="Masukan Password" type="password" />
                <Input id="passwordConfirmation" label="Konfirmasi Password Baru" onChange={handleChange} placeholder="Masukan Konfirmasi Password" type="password" />
                <Button className="btn-primary w-100 mt-4" title="Simpan" isLoading={isLoading} />
            </form>
        </div>
    </div>
  )
}

export default ForgotPasswordPage