import { Button, CardContainer, Input, RichText } from '../../../components'
import { PRODUCTCATEGORY } from '../../../routes/routesNames';
import UseFormCategories from '../hookForm';

const AddProductCategory = () => {
    const {
        isLoadingPostDataForm,
        form,
        isEnable,
        navigate,
        handleChange,
        handleChangeRich,
        handleSubmit        
    } = UseFormCategories({
        type : 'post',
        redirect : PRODUCTCATEGORY,
        categoryType : 'product'
    })

    return (
        <CardContainer>
            <form onSubmit={e => isEnable ? handleSubmit(e) : e.preventDefault()}>
                <div className="col-lg-10 col-12">
                    <div className="col-lg-8 col-12">
                        <Input
                            type="file"
                            id="image"
                            onChange={handleChange}
                            placeholder="Format foto/gambar .JPG, .JPEG, .PNG, maksimal 2MB, dengan aspect ratio 1 : 1." 
                            label="Unggah Gambar" />
                        <Input
                            id="name"
                            onChange={handleChange}
                            placeholder="Masukan nama kategori" 
                            label="Nama Kategori" />
                    </div>
                    <RichText onChange={handleChangeRich} label="Deskripsi" id="desc" />
                    <div className="d-flex justify-content-end">
                        <div className="me-4">
                            <Button 
                                onClick={() => navigate(-1)}
                                type="button"
                                className={`btn-light w-100 mt-4 px-5`} 
                                title="Batalkan" />
                        </div>
                        <Button 
                            disabled={!isEnable}
                            type="submit"
                            className={`${isEnable  ? 'btn-primary' : 'btn-secondary'}  w-100 mt-4 px-5`} 
                            title="Simpan" 
                            isLoading={isLoadingPostDataForm} />
                    </div>
                </div>
            </form>
        </CardContainer>
    )
}

export default AddProductCategory