import React, { useState } from 'react'
import { Form, Modal } from 'react-bootstrap';
import { IconImport } from '../../../assets/icons'
import { UseForm } from '../../../hooks';
import { UsePostDataForm } from '../../../hooks/api';
import Button from '../../atoms/button'
import Input from '../../atoms/input';

const ImportModal = props => {
  const {
    form,
    handleChange
  } = UseForm()

  const {
    isLoading,
    handlePostDataForm
} = UsePostDataForm();

  const [show, setShow] = useState(false);

  const handleClose = () => isLoading ? null : setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = e => {
    e.preventDefault()
    let params = {
      url : props.urlImport,
      form : form,
    }
    handlePostDataForm(params)
  }

  return (
    <>
      <Button   
        onClick={handleShow}
        icon={IconImport} 
        title="Import" 
        className="btn btn-primary-light me-3 px-4" 
        isSmall />     
      <Modal 
        backdrop="static" 
        show={show} 
        onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Import Akun</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Input
              formClassname="mt-0"
              type="file"
              id="file"
              isImage={false}
              onChange={handleChange}  />      
            <span className="img-desc">Dokumen Excel, maksimal 5MB. Download template 
              <a href={props.urlDownload} target="_blank" rel="noopener noreferrer" className="ms-2">
                 di sini
              </a>
            </span>      
            <div className="d-flex mt-4 justify-content-center">
              <div style={{
                width: '256px',
              }}>
                <Button  
                  isLoading={isLoading}
                  type="submit" 
                  onClick={handleShow}
                  title="Import" 
                  className="btn btn-primary w-100" 
                  isSmall />                  
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>          
    </>
  )
}

export default ImportModal