import { useState  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { axios, errorResponse } from '../../helpers'
import { loginData, logout } from '../../stores/auth';

const UsePut = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dataLogin = useSelector(loginData);
    const [isLoading,setIsLoading] = useState(false)        
    const [error,setError] = useState('')

    const handlePut = async params => {
        setIsLoading(true)
        try {
            const response = await axios.put(params.url,params.form, {
                headers: { Authorization: `Bearer ${dataLogin.data.token }` },
            });
            // console.log("response.data"+response.data)
            // console.log("response.data.status"+response.data.status)
            if(response.data.status){
                swal("Berhasil",response.data.message, "success",{
                    button : 'Tutup'
                }).then(() => {
                    setError('')
                    if(params.navigation){
                        navigate(params.navigation,{replace : true})
                    }else{
                        window.location.reload();
                    }                
                });                
            }else{
                swal('Error',response.data.message,'error')
                setError(response.data.message)
            }
        } catch (err) {
            if(errorResponse(err) == 'jwt expired'){
                dispatch(logout())
            }else{
                setError(errorResponse(err))
                swal('Error',errorResponse(err),'error')
            }              
        }  finally{
            setIsLoading(false)
        }        
    }

    return {
        isLoading,
        error,
        handlePut,
    }
}

export default UsePut