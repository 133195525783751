import React from 'react'

const Dashboard = () => {
  return (
    <div style={{
      height: '80vh'
    }} className="d-flex justify-content-center align-items-center w-100">Dashboard</div>
  )
}

export default Dashboard