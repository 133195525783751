import React from 'react'
import { Button, CardContainer, Input, InputSelect, InputTags, InputToggle, RadioButton, RichText, SelectMultipleCheckbox, SelectMultipleCheckboxStatic } from '../../../components'
import { DateToday } from '../../../helpers';
import UseFormVideo from './hookForm';

const EditVideo = () => {
    const {
        isLoadingPutDataForm,
        form,
        isEnable,
        navigate,
        handleChange,
        handleSubmit,
        isReady,
    } = UseFormVideo({
        type: 'put',
    })

    if (!isReady) {
        return <p>Loading..</p>
    }

    return (
        <CardContainer>
            <form className="row" onSubmit={e => isEnable ? handleSubmit(e) : e.preventDefault()}>
                <div className="col-lg-7 col-12" style={{
                    height: '74vh',
                    overflowY: 'scroll'
                }}>
                    <Input
                        value={form.title}
                        id="title"
                        onChange={handleChange}
                        placeholder="Masukan judul video"
                        label="Judul" />
                    <Input
                        value={form.thumbnail}
                        type="file"
                        id="thumbnail"
                        onChange={handleChange}
                        placeholder="Format foto/gambar .JPG, .JPEG, .PNG, maksimal 2MB, dengan aspect ratio 1 : 1."
                        label="Foto Thumbnail" />
                    <SelectMultipleCheckboxStatic
                        value={form.jenis_opt}
                        id="jenis_opt"
                        placeHolder="Pilih jenis opt"
                        onChange={handleChange}
                        data={[
                            'hama',
                            'gulma',
                            'penyakit',
                        ]}
                        label="Jenis OPT"
                        typeLabel="label-info" />
                    <SelectMultipleCheckbox
                        value={form.jenis_tanaman}
                        id="jenis_tanaman"
                        placeHolder="Pilih jenis tanaman"
                        onChange={handleChange}
                        url="/v1/kategori?category_type=tanaman"
                        label="Jenis Tanaman"
                        typeLabel="label-info" />
                    <InputTags
                        value={form.tag}
                        id="tag"
                        onChange={handleChange}
                        placeholder="Masukan tag"
                        label="Tag" />
                    <InputSelect
                        label="Status"
                        id="status"
                        value={form.status}
                        onChange={handleChange}
                        data={[
                            {
                                id: 'arsip',
                                name: 'Arsip'
                            },
                            {
                                id: 'publish',
                                name: 'Publish'
                            }
                        ]}
                    />
                    {
                        form.status === "publish" ?
                            <>
                                <RadioButton
                                    className="mt-4"
                                    data={[
                                        {
                                            name: "Sekarang",
                                            value: "sekarang"
                                        },
                                        {
                                            name: "Jadwalkan",
                                            value: "jadwalkan"
                                        }
                                    ]}
                                    value={form.terbitkan}
                                    id="terbitkan"
                                    label="Terbitkan"
                                    onChange={handleChange} />
                                {
                                    form.terbitkan === 'jadwalkan' ?

                                        <div className="row">
                                            <div className="col-lg-6 col-12">
                                                <Input
                                                    min={DateToday()}
                                                    type="date"
                                                    value={form.tanggal_publish}
                                                    id="tanggal_publish"
                                                    onChange={handleChange}
                                                    placeholder="Pilih tanggal"
                                                    label="Tanggal" />
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <Input
                                                    type="time"
                                                    value={form.waktu_publish}
                                                    id="waktu_publish"
                                                    onChange={handleChange}
                                                    placeholder="Pilih waktu"
                                                    label="Waktu" />
                                            </div>
                                        </div>
                                        : <></>
                                }
                                <InputToggle
                                    id="is_trending"
                                    onChange={handleChange}
                                    checked={form.is_trending}
                                    label="Jadikan Trending"
                                    placeholder="Video akan tampil pada halaman awal aplikasi" />

                            </> : <></>
                    }

                    <Input
                        value={form.link_vidio}
                        id="link_vidio"
                        onChange={handleChange}
                        placeholder="Masukan Link Video"
                        label="Link Video" />


                    <div className="d-flex justify-content-end">
                        <div className="me-4">
                            <Button
                                onClick={() => navigate(-1)}
                                type="button"
                                className={`btn-light w-100 mt-4 px-5`}
                                title="Batalkan" />
                        </div>
                        <Button
                            type="submit"
                            className={`${isEnable ? 'btn-primary' : 'btn-secondary'}  w-100 mt-4 px-5`}
                            title="Ubah"
                            isLoading={isLoadingPutDataForm} />
                    </div>
                </div>
                <div className="col-lg-5 col-12" style={{
                    height: '74vh',
                    overflowY: 'scroll'
                }}>
                    <h5 className="mb-3">Pratinjau</h5>
                    <h2 className="mb-3">{form.title}</h2>
                    {
                        form.link_vidio ?
                            form.link_vidio.includes('fb') ?
                                <iframe
                                    src={`https://www.facebook.com/plugins/video.php?height=420&href=${form.link_vidio}&show_text=false&width=560&t=0`}
                                    width="100%"
                                    height="420"
                                    scrolling="no"
                                    frameBorder="0"
                                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                    allowFullScreen="true"></iframe> :
                                <iframe
                                    width="100%"
                                    height="315"
                                    src={`https://www.youtube.com/embed/${form.link_vidio}`}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe> : null
                    }
                </div>
            </form>
        </CardContainer>
    )
}

export default EditVideo