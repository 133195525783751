import { Button, CardContainer, Input } from '../../../components'
import UseFormJabatan from './hookForm';

const EditJabatanPage = () => {
    const {
        isLoadingPut,
        navigate,
        form,
        handleChange,
        handleSubmit        
    } = UseFormJabatan({
        url : "/v1/jabatan",
        type : 'put',
    })

  return (
      <CardContainer>
        <form onSubmit={e =>  handleSubmit(e) }>
            <div className="col-lg-10 col-12">
                <Input
                    value={form.name}
                    id="name"
                    onChange={handleChange}
                    placeholder="Masukan Nama Jabatan" 
                    label="Nama Jabatan" />     
                <Input
                    id="desc"
                    value={form.desc}
                    onChange={handleChange}
                    placeholder="Masukan Deskripsi Jabatan" 
                    label="Deskripsi Jabatan" />                                                                    
                <div className="d-flex justify-content-end">
                    <div className="me-4">
                        <Button 
                            onClick={() => navigate(-1)}
                            type="button"
                            className={`btn-light w-100 mt-4 px-5`} 
                            title="Batalkan" />
                    </div>
                    <Button 
                        type="submit"
                        className={`btn-primary  w-100 mt-4 px-5`} 
                        title="Ubah" 
                        isLoading={isLoadingPut} />
                </div>
            </div>
        </form>
      </CardContainer>
  )
}

export default EditJabatanPage;