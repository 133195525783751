import React,{useEffect} from 'react';
import { useNavigate ,useLocation} from "react-router-dom";
import { IconArrowLeft } from '../../../assets/icons';
import { FORGOT } from '../../../routes/routesNames';

const ForgotPage = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if(!state){
            navigate(FORGOT)
        }
    }, []);

    return (
    <div className="auth">
        <div className="auth-card">
            <div className="auth-back d-flex flex-row justify-content-start w-100">
                <div className="pointer" onClick={() => navigate(-1)}>
                    <img src={IconArrowLeft} alt="" />
                    <span>Kembali</span>
                </div>
            </div>
            <h1>Silahkan Cek Email Anda</h1>
            <p className="sub-title mb-0">Kami telah mengirimkan intruksi melakukan atur ulang password melalui Email ke <strong>{state}</strong> .</p>
        </div>
    </div>
  )
}

export default ForgotPage