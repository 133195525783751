import React from 'react';
import { Button, CardContainer, Input,Table } from '../../../components'
import columns from './column'
import { Link } from 'react-router-dom';
import {  ADDPROMOTOR } from '../../../routes/routesNames';
import { UseGet } from '../../../hooks/api';
import { UseTable } from '../../../hooks';
import { IconImport } from '../../../assets/icons';

const Promotor = () => {    
    const {
        filter,
        isLoading,
        data,
        error,
        totalPages,
        handlePagination,
        handleLimit,
        handleFilter,
    } = UseGet('/v1/domain?type=sub terotori')

    const {
        isLoading : isLoadingPost ,
        dataRemove,
        handleSelected,
        handleRemove
    } = UseTable();

  return (
      <CardContainer >
            <div className="d-flex flex-row justify-content-between">
                <Input type="search" id="search" placeholder="Cari Sub teritori" onChange={handleFilter} />
                {
                    dataRemove.length === 0  ? 
                    <div className="d-flex flex-row">
                        {/* <Button icon={IconImport} title="Import" className="btn btn-primary-light me-3 px-4" isSmall />                 */}
                        <Link to={ADDPROMOTOR}>
                            <Button title="Tambah Sub teritori" className="btn btn-primary" isSmall />                
                        </Link>
                    </div>
                    : 
                    <Button 
                        isLoading={isLoadingPost === 'loading'} 
                        onClick={() =>  handleRemove('/v1/domain/delete')}  
                        title="Hapus Sub teritori" 
                        className="btn btn-danger" 
                        isSmall />                
                }
            </div>
            <Table 
                filter={filter}
                isLoading={isLoading}
                error={error}
                data={data}
                totalPages={totalPages}
                handlePagination={handlePagination}
                handleLimit={handleLimit}
                handleChangeSelect={handleSelected} 
                columns={columns} 
                isUpdate={filter.isUpdate} />
      </CardContainer>
  )
}

export default Promotor