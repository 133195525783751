import React from 'react'

import './checkbox.scss'
import PropTypes from 'prop-types';

const Checkbox = (props) => {
  return (
      <div className="form-check">
        <input 
            onChange={props.onChange}
            checked={props.checked}
            className={`form-check-input ${props.className}`} 
            type="checkbox" 
            name={props.name}
            value={props.value}
            id={props.id} />
      </div>
  )
}

Checkbox.propTypes = {
    onChange : PropTypes.func,
    checked : PropTypes.bool,
    className : PropTypes.string,
    value : PropTypes.string,
    id : PropTypes.string,
};

export default Checkbox