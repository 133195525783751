import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { axios } from '../../../helpers';
import { loginData } from '../../../stores/auth';
import { useSelector } from 'react-redux';


const RichText = (props) => {
    const editorRef = useRef();
    const dataLogin = useSelector(loginData);

    const base64ToFile = (base64String, fileName) => {
        const byteString = atob(base64String.split(',')[1]);
        const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([ab], { type: mimeString });
        const file = new File([blob], fileName, { type: mimeString });

        return file;
    };



    const handleImageUpload = async (targetElement, index, state, imageInfo, remainingFilesCount) => {
        console.log(targetElement);
        const file = base64ToFile(targetElement.src, "images.png")
        const formData = new FormData();
        formData.append('image', file);
        formData.append('type', "article");

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/article_upload_image`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${dataLogin.data.token}`
                },
            });
            var data = response.data;
            console.log(data);
            if (data.status) {
                const imageUrl = data.data;
                targetElement.src = imageUrl;
            } else {
                console.error('Failed to upload image');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }


    }

    const handleImageUploadBefore = (files, info, uploadHandler) => {
        // uploadHandler is a function
        console.log(files, info)
    }


    return (
        <div className="form-group mt-3">
            <label htmlFor={props.id} className="form-label">{props.label}</label>
            <SunEditor
                ref={editorRef}
                setOptions={{
                    height: 200,
                    buttonList: buttonList.complex,
                }}
                // onImageUploadBefore={handleImageUploadBefore}
                onImageUpload={handleImageUpload}
                lang="en"
                defaultValue={props.value}
                onChange={e => props.onChange(e)}
                height="200px"
                width="100%" />
        </div>
    )
}

RichText.propTypes = {
    min: PropTypes.string,
    max: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.any,
    isReadOnly: PropTypes.bool,
    placeHolder: PropTypes.string,
    type: PropTypes.string,
    unitName: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    isPassword: PropTypes.bool,
};
RichText.defaultProps = {
    type: 'text',
    required: true,
}

export default RichText