import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CHANNEL, SYMPTOM } from "../../routes/routesNames";
import { UseForm } from "../../hooks";
import {
  UsePost,
  UsePostDataForm,
  UsePut,
  UsePutDataForm,
} from "../../hooks/api";

const UseFormChannel = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading: isLoadingPost, handlePostDataForm } = UsePostDataForm();
  const { isLoading: isLoadingPut, handlePutDataForm } = UsePutDataForm();
  const { form, setForm, handleChange } = UseForm();

  useEffect(() => {
    if (props.type === "post") {
      if (location.state) {
        setForm({
          ...location.state,
        });
      } else {
        navigate(SYMPTOM);
      }
    }
  }, []);

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.type === "post") {
      let penyakit_id = [];
      let md_penyakit = [];
      let mb_penyakit = [];

      form.fields_penyakit.map((data) => {
        penyakit_id.push(data.id_penyakit.value);
        mb_penyakit.push(data.mb_penyakit);
        md_penyakit.push(parseFloat(data.md_penyakit));
      });

      form.fields_penyakit_2.map((data) => {
        penyakit_id.push(data.id_penyakit.value);
        mb_penyakit.push(data.mb_penyakit);
        md_penyakit.push(parseFloat(data.md_penyakit));
      });

      let params = {
        url: props.url,
        form: {
          ...form,
          penyakit_id: penyakit_id,
          md_penyakit: md_penyakit,
          mb_penyakit: mb_penyakit,
          jenis_tanaman_id: form._id,
        },
        navigation: SYMPTOM,
      };

      delete params.form.bagian_tanaman;
      delete params.form.fields_penyakit;
      delete params.form.fields_penyakit_2;
      delete params.form._id;
      delete params.form.name;

      handlePostDataForm(params);
    } else {
      let params = {
        url: props.url,
        form: {
          ...form,
        },
        navigation: SYMPTOM,
      };
      handlePutDataForm(params);
    }
  };

  return {
    handleSubmit,
    form,
    navigate,
    handleChange,
    isLoadingPut,
    isLoadingPost,
  };
};

export default UseFormChannel;
