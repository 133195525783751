import React, { useState,useEffect,useRef } from 'react'
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import { loginData } from '../../../stores/auth';
import { IconAddDomisili, IconArrowDown, IconArrowUp } from '../../../assets/icons';
import './inputDomisili.scss'
import Input from '../../atoms/input';
import Loader from '../../atoms/loader';
import { axios } from '../../../helpers';

const InputDomisili = (props) => {
  const wrapperRef = useRef(null);
  const dataLogin = useSelector(loginData);  
  const [isShowModal,setIsShowModal] = useState(false)
  const [currentTab,setCurrentTab] = useState('provinsi')
  const [filter,setFilter] = useState({
      page : 1,
      limit : 30,
      search : '',
  })

  const [isLoading,setIsLoading] = useState(false)
  const [data,setData] = useState({
    name : '',
    _id : ''
  })
  const [province,setProvince] = useState({
    name : '',
    _id : ''
  })
  const [city,setCity] = useState({
    name : '',
    _id : ''
  })
  const [district,setDistrict] = useState({
    name : '',
    _id : ''
  })
  const [village,setVillage] = useState({
    name : '',
    _id : ''
  })

  const [domisiliSelected,setDomisiliSelected] = useState([])

  useEffect(() => {
    if(props.value){
      setDomisiliSelected(props.value)
    }
  },[props.value]);

  useEffect(() => {
    if(currentTab === 'provinsi'){
      fetchData(`/v1/province?limit=${filter.limit}&search=${filter.search}`)
    }else if(currentTab === 'kab/kota'){
      fetchData(`/v1/city?province_id=${province._id}&limit=${filter.limit}&search=${filter.search}`)
    }else if(currentTab === 'kecamatan'){
      fetchData(`/v1/district?city_id=${city._id}&limit=${filter.limit}&search=${filter.search}`)
    }else if(currentTab === 'desa/kelurahan'){
      fetchData(`/v1/village?district_id=${district._id}&limit=${filter.limit}&search=${filter.search}`)
    }else{
      fetchData(`/v1/province`)
    }
},[currentTab,filter]);


const fetchData = async (url) => {
  setIsLoading(true)
  try {
      const response = await axios.get(url, {
          headers: { Authorization: `Bearer ${dataLogin.data.token }` },
      });
      // console.log(url,'url')
      // console.log(response,'response')

      if(response.data.status){
        setData(response.data.data)
      }else{
        setData([])
      }
  } catch (err) {
      // swal("Gagal",errorResponse(err), "error");
  } finally {
    setIsLoading(false)
  }         
}


  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsShowModal(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(wrapperRef);
  
  // handle
  const handleSearch = e => {
    setFilter({
        ...filter,
        search : e.target.value
    })
  }
  const handleSelected = data => {
    setFilter({
      page : 1,
      limit : 30,
      search : '',
    })
    if(currentTab === 'provinsi'){
      setProvince(data)
      setCurrentTab('kab/kota')
    }else if(currentTab === 'kab/kota'){
      setCity(data)
      setCurrentTab('kecamatan')
    }else if(currentTab === 'kecamatan'){
      setDistrict(data)
      setCurrentTab('desa/kelurahan')
    }else if(currentTab === 'desa/kelurahan'){
      setVillage(data)
    }else{
      setProvince(data)
    }
  }
  const handleAdd = () => {
    handleAddSelected([
      province,
      city,
      district,
      village,
    ])
    setCurrentTab('provinsi')
    setIsShowModal(false)
    setProvince({
      name : '',
      _id : ''
    })
    setCity({
        name : '',
        _id : ''
      })
    setDistrict({
        name : '',
        _id : ''
      })
    setVillage({
        name : '',
        _id : ''
      })
  }
  const handleAddSelected = data => {
    const tempDOmisili = domisiliSelected;

    tempDOmisili.push(data)
    props.onChange({
      name : props.id,
      value : tempDOmisili
    })
    setDomisiliSelected(tempDOmisili)
}
const handleDeleteSelected = indexSelected => {
    const tempDOmisili = [];

    domisiliSelected.map((data,index) => {
        if(indexSelected !== index){
            tempDOmisili.push(data)
        }
    })
    props.onChange({
      name : props.id,
      value : tempDOmisili
    })    
    setDomisiliSelected( tempDOmisili)    
}


  return (
    <div className="position-relative input-domisili-multiple"  ref={wrapperRef}>
        <div className={`form-group form-select-check-mulitple ${props.label ? 'mt-3' : ''}`} >
            {
                props.label ?
                <label htmlFor={props.id}>{props.label}</label> : null
            }
            <div className="row">
              <div className="col-lg-6 col-12 position-relative mt-2">
                <div className="form-control" style={{
                  height: 48,
                  overflowX: 'scroll',
                  whiteSpace: 'nowrap'
                }}>
                    {
                        domisiliSelected.length ?
                        domisiliSelected.map((dataParent,indexParent) => (
                            <span key={indexParent} className={`label-card cursor-pointer label-danger`}>
                              {
                                dataParent.map((data,index) => (
                                  <div key={index}>
                                    {
                                      data.name ? 
                                      <span>{data.name}{  dataParent.length !== index + 1 &&  dataParent[index + 1].name !== '' ? ',' : ''}</span>  : null
                                    }
                                  </div>
                                )) 
                              }
                              <span onClick={() => handleDeleteSelected(indexParent)} className="ps-2">x</span>
                            </span>  
                        )) : 
                        <span className="span-placeholder">Domisili masih kosong</span>
                    }
                </div>
              </div>
              <div className="col-lg-5 col-12 position-relative mt-2">
                <div className="form-control cursor-pointer"  style={{
                      whiteSpace : 'nowrap',
                      overflowX : 'scroll'
                }} onClick={() =>  setIsShowModal(!isShowModal)} >
                  {
                    province.name ? 
                    <span className={`label-card cursor-pointer label-danger`}>
                        <span>{province.name}{ city.name  ? ',' : '' }{city.name}{ district.name ? ',' : '' }{district.name}{ village.name ? ',' : '' }{village.name}</span>
                    </span>  : 
                    <span className="span-placeholder">Pilih Domisili</span>                   
                  }
                </div>
                <img 
                  src={isShowModal ? IconArrowUp : IconArrowDown} 
                  alt="icon" 
                  className="icon-arrow-domisili cursor-pointer"
                  onClick={() =>  setIsShowModal(!isShowModal)} />
              </div>
              <div className="col-lg-1 col-12 d-flex justify-content-end">
                <img src={IconAddDomisili} alt="icon add domisili"  onClick={() => province.name ?  handleAdd() : null} className="cursor-pointer" />
              </div>
            </div>
        </div>
        {
            isShowModal ? 
            <div>
                <div className="modal-form-select">
                <ul>
                  <li 
                    className={currentTab === 'provinsi' ? `tab-active` : ''} 
                    onClick={() => {
                      setCurrentTab('provinsi')
                      setProvince({
                          name : '',
                          _id : ''
                        })
                      setCity({
                          name : '',
                          _id : ''
                        })
                      setDistrict({
                          name : '',
                          _id : ''
                        })
                      setVillage({
                          name : '',
                          _id : ''
                        })
                      setFilter({
                        page : 1,
                        limit : 30,
                        search : '',
                      })
                  
                    }} >
                      Propinsi {'>'}
                  </li>
                  <li 
                    className={currentTab === 'kab/kota' ? `tab-active` : ''} 
                    onClick={() =>{
                      if(province._id){
                        setCurrentTab('kab/kota')
                        setCity({
                          name : '',
                          _id : ''
                        })
                        setDistrict({
                          name : '',
                          _id : ''
                        })
                        setVillage({
                          name : '',
                          _id : ''
                        }) 
                        setFilter({
                          page : 1,
                          limit : 30,
                          search : '',
                        })
                     
                      }
                    }} >
                      Kab/Kota {'>'}
                  </li>
                  <li 
                    className={currentTab === 'kecamatan' ? `tab-active` : ''}
                    onClick={() => {
                      if(city._id){
                        setDistrict({
                          name : '',
                          _id : ''
                        })
                        setCurrentTab('kecamatan')                        
                        setVillage({
                          name : '',
                          _id : ''
                        })
                        setFilter({
                          page : 1,
                          limit : 30,
                          search : '',
                        })
                      
                      }
                    }}
                    >
                      Kecamatan {'>'}
                  </li>
                  <li 
                    className={currentTab === 'desa/kelurahan' ? `tab-active` : ''}
                    onClick={() => {
                      if(district._id){
                        setCurrentTab('desa/kelurahan')  
                        setVillage({
                          name : '',
                          _id : ''
                        })  
                        setFilter({
                          page : 1,
                          limit : 30,
                          search : '',
                        })
                                         
                      }
                    }}
                    >
                      Desa/Kelurahan 
                  </li>
                </ul>
                <div className="barrier"></div>
                <Input type="search" placeholder={`Cari ${currentTab}`} onChange={handleSearch} />
                <div className="container-select-item" >
                    {
                        isLoading  ?
                        <Loader /> : 
                        data.length ?
                        data.map((data,index) => (
                            <label 
                                onClick={() => handleSelected(data)}
                                key={index} 
                                className="d-flex justify-content-between cursor-pointer"  >
                                <span>{data.name}</span>
                            </label>
                        )) :
                      <p className="text-center">Tidak ada data</p>
                    }
                </div>
            </div>
            </div>
             : null
        }
    </div>
  )
}

InputDomisili.propTypes = {
  label : PropTypes.string,
  className : PropTypes.string,
  onChange : PropTypes.func,
  typeLabel : PropTypes.string,
  url : PropTypes.string,
  id : PropTypes.string,
  value : PropTypes.any
};
InputDomisili.defaultProps = {
  dataSelected : [],
}

export default InputDomisili