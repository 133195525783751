import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { UseForm } from "../../hooks";
import { UsePostDataForm, UsePutDataForm } from "../../hooks/api";

const UseFormAccount = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { isLoading: isLoadingPostDataForm, handlePostDataForm } =
    UsePostDataForm();
  const { isLoading: isLoadingPutDataForm, handlePutDataForm } =
    UsePutDataForm();

  const { form, setForm, handleChange, handleChangeRich } = UseForm();

  useEffect(() => {
    setForm({
      ...form,
      status: true,
      is_trending: false,
    });
    if (props.type === "put") {
      // console.log(location.state);
      if (location.state) {
        let dataWilayahSelectedTemp = {
          wilayah_kerja: "",
          wilayah_kerja_id: [],
          wilayah_kerja_detail_name: [],
        };
        
        // console.log("location.state.wilayah_kerja_combine==>"+location.state.wilayah_kerja_combine);
        if(location.state.wilayah_kerja_combine.length === 0) {
          let a = {
            _id:"",
            name:"nasional",
            type:"nasional"
          }
          location.state.wilayah_kerja_combine.push(a)
        }

        location.state.wilayah_kerja_combine &&
          location.state.wilayah_kerja_combine.map((data, index) => {
            let id = "";

            if (data.type === "nasional") {
              id = "";
            } else {
              id = data._id;
            }

            if (index === 0) {
              dataWilayahSelectedTemp.wilayah_kerja =
                data.type === "teritori" ? "teritorial" : data.type === "sub terotori" ? "field_promotor" : data.type;
            }
            dataWilayahSelectedTemp.wilayah_kerja_id.push(data.name);
            dataWilayahSelectedTemp.wilayah_kerja_detail_name.push(data.name);
          });

        setForm({
          ...location.state,
          wilayahKerja: dataWilayahSelectedTemp,
          isActive: location.state.status === "aktif" ? true : false,
        });
      } else {
        navigate(props.redirect);
      }
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(form);

    if (!form.jabatan) {
      swal("Gagal", "Jabatan belum diisi", "error");
      return false;
    }

    if (form.password === form.passwordConfirmation) {
      let wilayah_kerja = [];
      let wilayah_kerja_id = [];
      let wilayah_kerja_detail_name = [];

      if (!form.jabatan) {
        swal("Gagal", "Jabatan belum diisi", "error");
        return false;
      }

      if (!form.wilayahKerja.wilayah_kerja && props.url !== "/v1/account/super") {
        swal("Gagal", "Wilayah kerja belum diisi", "error");
        return false;
      }

      if (
        props.url === "/v1/account/expert" ||
        props.url === "/v1/account/admin"
      ) {
        if (form.wilayahKerja.wilayah_kerja !== "nasional") {
          if (!form.wilayahKerja.wilayah_kerja_id) {
            swal("Gagal", "Wilayah kerja area belum diisi", "error");
            return false;
          }

          form.wilayahKerja.wilayah_kerja_id.map((detailWilayahKerja) => {
            wilayah_kerja.push(form.wilayahKerja.wilayah_kerja);
            wilayah_kerja_id.push(detailWilayahKerja);
            wilayah_kerja_detail_name.push(detailWilayahKerja);
          });
        } else {
          wilayah_kerja.push(form.wilayahKerja.wilayah_kerja);
          wilayah_kerja_detail_name.push(
            form.wilayahKerja.wilayah_kerja_detail_name
          );
        }
      }


      if (props.type === "post") {
        // if(form.image.type === "image/jpeg" || form.image.type === "image/png"){
        let params = {
          url: props.url,
          form: {
            ...form,
            status: form.isActive ? "aktif" : "in aktif",
            wilayah_kerja: wilayah_kerja,
            wilayah_kerja_id: wilayah_kerja_id,
            wilayah_kerja_detail_name: wilayah_kerja_detail_name,
          },
          navigation: props.redirect,
        };
        delete params.form.wilayahKerja;

        handlePostDataForm(params);
        // }else{
        //     swal('Gagal','File Harus Gambar','error')
        // }
      } else {
        if (form.image && typeof form.image !== "string") {
          
          if (
            form.image.type === "image/jpeg" ||
            form.image.type === "image/png"
          ) {
            let params;

            if (form.wilayahKerjaSelected) {
              params = {
                url: props.url + "?_id=" + form.user_id,
                form: {
                  ...form,
                  ...form.wilayahKerjaSelected,
                  status: form.isActive ? "aktif" : "in aktif",
                  wilayah_kerja: [form.wilayahKerjaSelected.wilayah_kerja],
                },
                navigation: props.redirect,
              };
            } else {
              params = {
                url: props.url + "?_id=" + form.user_id,
                form: {
                  ...form,
                  status: form.isActive ? "aktif" : "in aktif",
                  wilayah_kerja: wilayah_kerja,
                  wilayah_kerja_id: wilayah_kerja_id,
                  wilayah_kerja_detail_name: wilayah_kerja_detail_name,
                },
                navigation: props.redirect,
              };
            }

            delete params.form.wilayahKerja;

            handlePutDataForm(params);
          } else {
            swal("Gagal", "File Harus Gambar", "error");
          }
        } else {
          
         
          let params;
          if (form.wilayahKerjaSelected) {
            // console.log("disini1");
            params = {
              url: props.url + "?_id=" + form.user_id,
              form: {
                ...form,
                ...form.wilayahKerjaSelected,
                status: form.isActive ? "aktif" : "in aktif",
                wilayah_kerja: [form.wilayahKerjaSelected.wilayah_kerja],
              },
              navigation: props.redirect,
            };
          } else {
            // console.log("atau disini1");
            params = {
              url: props.url + "?_id=" + form.user_id,
              form: {
                ...form,
                status: form.isActive ? "aktif" : "in aktif",
                wilayah_kerja: wilayah_kerja,
                wilayah_kerja_id: wilayah_kerja_id,
                wilayah_kerja_detail_name: wilayah_kerja_detail_name,
              },
              navigation: props.redirect,
            };
          }

          delete params.form.wilayahKerja;
          delete params.form.image;

          handlePutDataForm(params);
        }
      }




    } else {
      swal("Gagal", "Password Konfirmasi Tidak Sama", "error");
    }
  };

  const isEnable = true;

  return {
    isLoadingPostDataForm,
    isLoadingPutDataForm,
    form,
    handleChange,
    handleSubmit,
    isEnable,
    navigate,
  };
};

export default UseFormAccount;