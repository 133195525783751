import { Button, CardContainer, Input } from "../../../components"
import UseFormKategoriMarketplace from "./hookForm"

const EditKategoriMarketplace = () => {
    const {
        isLoadingPut,
        navigate,
        form,
        handleChange,
        handleSubmit
    } = UseFormKategoriMarketplace({
        url: "/v1/category_market_place",
        type: 'put',
    })
    return (
        <CardContainer>
            <form onSubmit={e => handleSubmit(e)}>
                <div className="col-lg-8 col-12">
                    <Input
                        id="name"
                        value={form.name}
                        onChange={handleChange}
                        placeholder="Masukkan nama kategori"
                        label="Nama Kategori" />
                    <div className="d-flex justify-content-end">
                        <div className="me-4">
                            <Button
                                onClick={() => navigate(-1)}
                                type="button"
                                className={`btn-light w-100 mt-4 px-5`}
                                title="Batalkan" />
                        </div>
                        <Button
                            type="submit"
                            className={`btn-primary w-100 mt-4 px-5`}
                            title="Simpan"
                            isLoading={isLoadingPut} />
                    </div>
                </div>
            </form>
        </CardContainer>
    )
}
export default EditKategoriMarketplace