import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CHANNEL, JABATAN } from "../../routes/routesNames";
import { UseForm } from "../../hooks";
import {
  UsePost,
  UsePostDataForm,
  UsePut,
  UsePutDataForm,
} from "../../hooks/api";
import swal from "sweetalert";

const UseFormChannel = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading: isLoadingPostDataForm, handlePostDataForm } =
    UsePostDataForm();
  const { isLoading: isLoadingPutDataForm, handlePutDataForm } =
    UsePutDataForm();
  const { form, setForm, handleChange } = UseForm();

  useEffect(() => {
    setForm({
      ...form,
      type: "R1",
      is_star_retailer: false,
    });
    if (props.type === "put") {
      if (location.state) {
        setForm({
          ...location.state,
          isActive: location.state.status === "aktif" ? true : false,
          is_star_retailer:
            location.state.is_star_retailer === null
              ? false
              : location.state.is_star_retailer,
        });
      } else {
        navigate(CHANNEL);
      }
    }
  }, []);

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.type === "post") {
      let params = {
        url: props.url,
        form: {
          ...form,
          status: form.isActive ? "aktif" : "in aktif",
          is_star_retailer: String(form.is_star_retailer),
        },
        navigation: CHANNEL,
      };

      handlePostDataForm(params);
    } else {
      if (typeof form.thumbnail !== "string") {
        if (
          form.thumbnail.type === "image/jpeg" ||
          form.thumbnail.type === "image/png"
        ) {
          let params = {
            url: props.url + "?_id=" + form._id,
            form: {
              ...form,
              status: form.isActive ? "aktif" : "in aktif",
              is_star_retailer: form.is_star_retailer
                ? String(form.is_star_retailer)
                : false,
              province: form.province._id ? form.province._id : form.province,
              city: form.city._id ? form.city._id : form.city,
              district: form.district._id ? form.district._id : form.district,
              village: form.village._id ? form.village._id : form.village,
            },
            navigation: CHANNEL,
          };

          handlePutDataForm(params);
        } else {
          swal("Gagal", "File Harus Gambar", "error");
        }
      } else {
        let params = {
          url: props.url + "?_id=" + form._id,
          form: {
            ...form,
            status: form.isActive ? "aktif" : "in aktif",
            is_star_retailer: form.is_star_retailer
              ? String(form.is_star_retailer)
              : false,
            province: form.province._id ? form.province._id : form.province,
            city: form.city._id ? form.city._id : form.city,
            district: form.district._id ? form.district._id : form.district,
            village: form.village._id ? form.village._id : form.village,
          },
          navigation: CHANNEL,
        };

        // console.log(params, "p");

        delete params.form.thumbnail;

        handlePutDataForm(params);
      }
    }
  };

  return {
    handleSubmit,
    form,
    navigate,
    handleChange,
    isLoadingPutDataForm,
    isLoadingPostDataForm,
  };
};

export default UseFormChannel;
