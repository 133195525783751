import React from 'react'
import PropTypes from 'prop-types';
import './buttonAction.scss'

const ButtonAction = (props) => {
  return (
    <button 
        onClick={props.onClick}
        type="button" 
        className="btn-action">
            <span className="me-2">{props.title}</span>
            <img src={props.icon} alt="" />
    </button>
  )
}


ButtonAction.propTypes = {
    icon : PropTypes.any,
    title : PropTypes.string,
    onClick : PropTypes.func,
};


export default ButtonAction