import { Alert, Button, CardContainer, Input, InputSelect, InputTags, InputToggle, RadioButton, RichText, SelectAsync2, SelectMultipleCheckbox, SelectMultipleCheckboxRow, SelectMultipleCheckboxStatic } from '../../components'
import { DateToday } from '../../helpers';
import UseFormProduct from './hookForm';
import UseFormArticle from './hookForm';

const EditArticle = () => {
    const {
        isLoadingPutDataForm,
        form,
        content,
        navigate,
        handleChange,
        handleChangeRich,
        handleSubmit,
        isReady    
    } = UseFormProduct({
        type : 'put',
    })

  return (
      <CardContainer>
        <form onSubmit={e => handleSubmit(e)}>
            <div className="col-lg-10 col-12">
                <div className="col-lg-8 col-12">
                    <Input
                        value={form.image_product}
                        type="file"
                        id="image_product"
                        onChange={handleChange}
                        placeholder="Format foto/gambar .JPG, .JPEG, .PNG, maksimal 2MB, dengan aspect ratio 1 : 1." 
                        label="Unggah Gambar" />                                           
                    <Input
                        value={form.product_name}
                        id="product_name"
                        onChange={handleChange}
                        placeholder="Masukan Nama Produk" 
                        label="Nama Produk" />    
                    <SelectAsync2    
                        value={{
                            label: form.kategori,
                            value: form.kategori,
                            name : "kategori"
                        }}                                      
                        onChange={handleChange} 
                        labelName="name"
                        valueName="name"
                        id="kategori"
                        paramsId="?category_type=product"
                        url="/v1/kategori"
                        label="Kategori Produk"
                        placeHolder="Pilih Kategori Produk..." />   
                    <p className="mt-3">Digunakan untuk:</p>                                                                             
                    <SelectMultipleCheckbox 
                        value={form.jenis_tanaman}
                        id="jenis_tanaman"
                        placeHolder="Pilih jenis tanaman"
                        onChange={handleChange}
                        url="/v1/kategori?category_type=tanaman"
                        label="Jenis Tanaman" 
                        typeLabel="label-info" />  

                    <div className="form-group mt-3">
                        <label>Jenis OPT</label>
                        <SelectMultipleCheckboxRow 
                            id="categori_hama"
                            value={form.categori_hama}
                            placeHolder="Pilih Hama"
                            onChange={handleChange}
                            url="/v1/kategori?category_type=hama"
                            label="Hama" 
                            typeLabel="label-info" />     
                        <SelectMultipleCheckboxRow 
                            id="categori_gulma"
                            value={form.categori_gulma}
                            placeHolder="Pilih Gulma"
                            onChange={handleChange}
                            url="/v1/kategori?category_type=gulma"
                            label="Gulma" 
                            typeLabel="label-info" />     
                        <SelectMultipleCheckboxRow 
                            id="categori_penyakit"
                            value={form.categori_penyakit}
                            placeHolder="Pilih Penyakit"
                            onChange={handleChange}
                            url="/v1/kategori?category_type=opt"
                            label="Penyakit" 
                            typeLabel="label-info" />                           
                    </div>

                    <InputSelect
                        value={form.status}
                        label="Status" 
                        id="status"
                        placeHolder="Pilih Status"
                        onChange={handleChange}
                        data={[
                            {
                                id : 'publish',
                                name : 'Publish'
                            },
                            {
                                id : 'arsip',
                                name : 'Arsip'
                            }
                        ]} 
                    />                                                        
                  <InputToggle 
                    id="is_unggulan"
                    onChange={handleChange}
                    checked={form.is_unggulan}
                    label="Jadikan Produk Unggulan" 
                    placeholder="Menjadikan status produk unggulan" />                                  
                </div>  
                {
                    isReady ?
                    <RichText 
                        value={content} 
                        onChange={handleChangeRich} 
                        label="Deskripsi" 
                        id="desc" /> 
                    : null
                }
                <div className="d-flex justify-content-end">
                    <div className="me-4">
                        <Button 
                            onClick={() => navigate(-1)}
                            type="button"
                            className={`btn-light w-100 mt-4 px-5`} 
                            title="Batalkan" />
                    </div>
                    <Button 
                        type="submit"
                        className="btn-primary  w-100 mt-4 px-5" 
                        title="Ubah" 
                        isLoading={isLoadingPutDataForm} />
                </div>
            </div>
        </form>
      </CardContainer>
  )
}

export default EditArticle