import React from 'react'
import PropTypes from 'prop-types';
import Switch from "react-switch";
import './toggle.scss' ;

const InputToggle = (props) => {
  return (
    <div className="form-group mt-3  ">
        <label>{props.label}</label>
        <div className="d-flex flex-row justify-content-between toggle">
            <span>{props.placeholder}</span>
            <Switch onChange={value =>  props.onChange({
              name : props.id,
              value : value
            })} checked={props.checked} offColor="#0F6292" onColor="#0BC678" />

        </div>
    </div>
  )
}

InputToggle.propTypes = {
    label : PropTypes.string,
    id : PropTypes.string,
    placeholder : PropTypes.string,
    value : PropTypes.string,
    checked : PropTypes.bool,
    onChange : PropTypes.func,
};
InputToggle.defaultProps = {
    required : true,
}


export default InputToggle