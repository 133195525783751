import { Link } from "react-router-dom";
import { IconEye, IconPencil } from "../../assets/icons";
import { ButtonAction } from "../../components";
import { DETAILCONSULTATION, EDITCHANNEL } from "../../routes/routesNames";

function tanggal(params) {
    var now = new Date(params)
    var d = now.getDay()
    var M = "";
    var Y = now.getFullYear()
    switch (now.getMonth() + 1) {
        case 1:
            M = "Januari"
            break;
        case 2:
            M = "Februari"
            break;
        case 3:
            M = "Maret"
            break;
        case 4:
            M = "April"
            break;
        case 5:
            M = "Mei"
            break;
        case 6:
            M = "Juni"
            break;
        case 7:
            M = "Juli"
            break;
        case 8:
            M = "Agustus"
            break;
        case 9:
            M = "September"
            break;
        case 10:
            M = "Oktober"
            break;
        case 11:
            M = "November"
            break;
        case 12:
            M = "Desember"
            break;
        default:
            break;
    }
    return d + " " + M + " " + Y;
}

const columns = [
    {
        name: "Expert",
        selector: (row) => row.expert_name,
        sortable: true,
        minWidth: "300px",
        format: (row, _) => {
            return (
                <div className="d-flex">
                    {
                        row.expert_profile ?
                            <img
                                className=""
                                style={{ borderRadius: "16px" }}
                                width={32}
                                height={32}
                                src={row.expert_profile} /> :
                            <div></div>
                    }
                    &nbsp;
                    <span>{row.expert_name}</span>
                </div>
            );
        }
    },
    {
        name: "Teritori",
        selector: (row) => row.expert_teritory,
        sortable: true,
        minWidth: "200px",
    },
    {
        name: "Username",
        selector: (row) => row.user_name,
        sortable: true,
        minWidth: "300px",
        format: (row, _) => {
            return (
                <div className="d-flex">
                    {
                        row.user_profile ?
                            <img
                                className=""
                                style={{ borderRadius: "16px" }}
                                width={32}
                                height={32}
                                src={row.user_profile} /> :
                            <div></div>
                    }
                    &nbsp;
                    <span>{row.user_name}</span>
                </div>
            );
        }
    },
    {
        name: "Tanggal",
        selector: (row) => row.date,
        sortable: true,
        minWidth: "200px",
        format: (row, _) => {

            return (
                <div>{tanggal(row.date)} </div>
            );
        }
    },
    {
        name: "Status",
        selector: (row) => row.status,
        sortable: true,
        minWidth: "200px",
        format: (row, index) => {
            return (
                <div className="pb-1">
                    <span
                        className={
                            row.status === "Selesai" ? `label-success` : row.status === "Sedang berlangsung" ? "label-warning" : "label-danger"
                        }
                    >
                        {row.status}
                    </span>
                </div>
            );
        },
    },
    {
        name: "Aksi",
        selector: (row) => row.name,
        sortable: true,
        maxWidth: "200px",
        format: (row, index) => {
            return (
                <Link to={DETAILCONSULTATION} state={row}>
                    <ButtonAction title="Lihat" icon={IconEye} />
                </Link>
            );
        },
    },
];

export default columns;
