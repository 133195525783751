import React from 'react'
import './select.scss'
import PropTypes from 'prop-types';

const InputSelect = (props) => {
  return (
      <div className="form-group mt-3">
        <label>{props.label}</label>
        <select
            required={props.required}
            onChange={props.onChange}
            id={props.id}
            name={props.id}        
            defaultValue={props.value}   
            placeholder={props.placeHolder}
            className="form-select" >
            {
                props.data.map((data,index) => (
                    <option key={index} value={data.id}>{data.name}</option>
                ))
            }
        </select>  
      </div>
    )
}

InputSelect.propTypes = {
    label : PropTypes.string,
    id : PropTypes.string,
    className : PropTypes.string,
    onChange : PropTypes.func,
    data : PropTypes.array,
    value : PropTypes.string,
    placeHolder : PropTypes.string,
    required : PropTypes.bool,
};
InputSelect.defaultProps = {
    required : true, 
}

export default InputSelect