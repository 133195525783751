import React from 'react'
import './cardContainer.scss'

const CardContainer = ({children}) => {
  return (
    <div className="card-container">
       {children}
    </div>
  )
}

export default CardContainer