import { Link } from "react-router-dom";
import { IconPencil } from "../../../assets/icons";
import { ButtonAction } from "../../../components";
import { OverflowText } from "../../../helpers";
import { EDITPRODUCTCATEGORY } from "../../../routes/routesNames";

const columns =  [
    {
        name: 'Kategori',
        selector: row => row.name,
        sortable: true,
        maxWidth : "296px",
    },
    {
        name: 'Deskripsi',
        selector: row => row.desc,
        sortable: true,
        format : (row,index) => {
            return <OverflowText text={row.desc ? row.desc : ''} />

        }
    },
    {
        name: 'Aksi',
        selector: row => row.name,
        sortable: true,
        maxWidth : "150px",
        format : (row,index) => {
            return <Link to={EDITPRODUCTCATEGORY} state={row}>
                <ButtonAction title="Ubah" icon={IconPencil} />
            </Link> 
        }
    },
];

export default columns;