import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, CardContainer, Input, Table } from "../../../components"
import { ADDKATEGORIMARKETPLACE, KATEGORIMARKETPLACE } from "../../../routes/routesNames";
import { useEffect } from "react";
import { UseGet } from "../../../hooks/api";
import { UseTable } from "../../../hooks";
import columns from "./column";


const KategoriMarketplace = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!location.state) {
            navigate(KATEGORIMARKETPLACE);
        }
    }, []);

    const {
        filter,
        isLoading,
        data,
        error,
        totalPages,
        handlePagination,
        handleLimit,
        handleFilter,
    } = UseGet(`/v1/category_market_place`)

    const {
        isLoading: isLoadingPost,
        dataRemove,
        handleSelected,
        handleRemove
    } = UseTable();

    return (
        <div>
            <CardContainer >
                <div className="d-flex flex-row justify-content-between">
                    <Input type="search" id="search" placeholder="Cari Kategori" onChange={handleFilter} />
                    {
                        dataRemove.length === 0 ?
                            <div className="d-flex flex-row">
                                <Link to={ADDKATEGORIMARKETPLACE} state={location.state} >
                                    <Button title="Tambah Kategori" className="btn btn-primary" isSmall />
                                </Link>

                            </div>
                            :
                            <Button
                                isLoading={isLoadingPost}
                                onClick={() => handleRemove('/v1/category_market_place/delete')}
                                title="Hapus Kategori"
                                className="btn btn-danger" isSmall />
                    }
                </div>
                <Table
                    filter={filter}
                    isLoading={isLoading}
                    error={error}
                    data={data}
                    totalPages={totalPages}
                    handlePagination={handlePagination}
                    handleLimit={handleLimit}
                    handleChangeSelect={handleSelected}
                    columns={columns(location.state)} />
            </CardContainer>
        </div>
    )
}
export default KategoriMarketplace