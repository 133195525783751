import { useNavigate } from "react-router-dom";
import { Button, CardContainer, Input, InputDomisili, InputSelectAsync } from '../../../components'
import { TERITORIAL } from '../../../routes/routesNames';
import UseFormDomisili from '../hookForm';

const AddTeritorial = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        form,
        handleChange,
        isLoadingPost,
    } = UseFormDomisili({
        type : 'post',
        redirect : TERITORIAL,
        url : '/v1/domain',
        formType : 'teritori'
    })

    const isEnable = form.name && form.reference_id &&  form.domisiliSelected.length  ;

  return (
      <CardContainer>
        <form onSubmit={e => isEnable ? handleSubmit(e) : e.preventDefault()}>
            <div className="col-lg-10 col-12">
                <Input
                    id="name"
                    onChange={handleChange}
                    placeholder="Masukan Teritorial" 
                    label="Teritorial" />
                <InputSelectAsync 
                    label="Regional"
                    value={form.reference_id.name}
                    handleSelected={data => handleChange({
                        name :'reference_id',
                        value : data
                    })}
                    placeHolder="Pilih Regional"
                    url='/v1/domain?type=regional'
                    labelName="name"
                     />
                <InputDomisili
                    id="domisiliSelected"
                    onChange={handleChange}
                    label="Domisili" />
                <div className="d-flex justify-content-end">
                    <div className="me-4">
                        <Button 
                            onClick={() => navigate(-1)}
                            type="button"
                            className={`btn-light w-100 mt-4 px-5`} 
                            title="Batalkan" />
                    </div>
                    <Button 
                        type="submit"
                        className={`${isEnable  ? 'btn-primary' : 'btn-secondary'}  w-100 mt-4 px-5`} 
                        title="Simpan" 
                        isLoading={isLoadingPost} />
                </div>
            </div>
        </form>
      </CardContainer>
  )
}

export default AddTeritorial