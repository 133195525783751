import React from 'react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, CardContainer, Input, Select } from '../../../components'
import Table from '../../../components/organisms/table'
import { UseTable } from '../../../hooks';
import { UseGet, UsePost, UsePutDataForm } from '../../../hooks/api';
import { ADDVIDEO } from '../../../routes/routesNames';
import columns from './column'

const Video = () => {
    const {
        filter,
        isLoading,
        data,
        error,
        totalPages,
        handlePagination,
        handleLimit,
        handleFilter,
    } = UseGet('/v1/vidio')

    const {
        isLoading: isLoadingTanaman,
        data: dataTanaman,
    } = UseGet('/v1/jenis_tanaman')

    const {
        isLoading: isLoadingPost,
        dataRemove,
        handleSelected,
        handleRemove
    } = UseTable();

    const {
        isLoading: isLoadingPutDataForm,
        error: errorDataForm,
        handlePutDataForm,
    } = UsePutDataForm();

    const {
        isLoading: isLoadingDuplicate,
        error: errorDuplicate,
        handlePost: handlePostDuplicate,
    } = UsePost();

    const handleDuplicate = detail => {
        let params = {
            url: '/v1/article/duplicate',
            form: {
                parameter_id: detail._id
            },

        }

        delete params.form.thumbnail;
        handlePostDuplicate(params)
    }


    const handleEdit = detail => {
        let params = {
            url: '/v1/vidio?_id=' + detail._id,
            form: {
                ...detail,
                status: detail.status === 'arsip' ? 'publish' : 'arsip'
            },

        }

        delete params.form.thumbnail;
        handlePutDataForm(params)
    }

    const dataOpt = useMemo(() => [
        {
            id: 'hama',
            name: 'Hama'
        },
        {
            id: 'gulma',
            name: 'Gulma'
        },
        {
            id: 'penyakit',
            name: 'Penyakit'
        },
    ])

    return (
        <>
            <CardContainer>
                <div className="row" style={{
                }}>
                    <div className="col-12 col-lg-3 mb-2">
                        <Select
                            id="jns_opt"
                            onChange={handleFilter}
                            placeHolder="Semua OPT"
                            data={dataOpt}
                        />
                    </div>
                    {
                        isLoadingTanaman ?
                            <p>Loading</p> :
                            <div className="col-12 col-lg-3 mb-2">
                                <Select
                                    id="jns_tanaman"
                                    onChange={handleFilter}
                                    placeHolder="Semua Tanaman"
                                    data={dataTanaman}
                                />
                            </div>
                    }
                    <div className="col-12 col-lg-3 mb-2">
                        <Select
                            onChange={handleFilter}
                            id="status"
                            placeHolder="Semua Status"
                            data={[
                                {
                                    id: 'publish',
                                    name: 'Publish'
                                },
                                {
                                    id: 'rsip',
                                    name: 'Arsip'
                                },
                            ]}
                        />
                    </div>
                    <div className="col-12 col-lg-3 mb-2">
                        {
                            dataRemove.length === 0 ?
                                <Link to={ADDVIDEO}>
                                    <Button title="Tambah Video" className="btn btn-primary" isSmall />
                                </Link> :
                                <Button
                                    isLoading={isLoadingPost}
                                    onClick={() => handleRemove('/v1/vidio/delete')}
                                    title="Hapus Video"
                                    className="btn btn-danger" isSmall />
                        }
                    </div>

                </div>
            </CardContainer>
            <CardContainer >
                <div className="d-flex flex-row justify-content-between">
                    <Input id="search" type="search" placeholder="Cari Video" onChange={handleFilter} />

                </div>
                <Table
                    filter={filter}
                    isLoading={isLoading}
                    error={error}
                    data={data}
                    totalPages={totalPages}
                    handlePagination={handlePagination}
                    handleLimit={handleLimit}
                    handleChangeSelect={handleSelected}
                    columns={columns(handleEdit, handleDuplicate)} />
            </CardContainer>
        </>

    )
}

export default Video