const LOGIN = "/auth/login";
const FORGOT = "/auth/forgot";
const FORGOTSUCCESS = "/auth/forgot_success";
const FORGOTPASSWORD = "/auth/forgot/:id";
const DASHBOARD = "/";
const ADMIN = "/admin";
const ADDADMIN = "/admin/add";
const EDITADMIN = "/admin/edit";
const EXPERT = "/expert";
const CUSTOMER = "/user";
const ADDEXPERT = "/expert/add";
const EDITEXPERT = "/expert/edit";
const SUPERADMIN = "/super_admin";
const ADDSUPERADMIN = "/super_admin/add";
const EDITSUPERADMIN = "/super_admin/edit";
const JABATAN = "/jabatan";
const ADDJABATAN = "/jabatan/add";
const EDITJABATAN = "/jabatan/edit";
const PRODUCTCATEGORY = "/product_kategori";
const ADDPRODUCTCATEGORY = "/product_kategori/add";
const EDITPRODUCTCATEGORY = "/product_kategori/edit";
const OPTCATEGORY = "/opt_kategori";
const REGIONAL = "/regional";
const ADDREGIONAL = "/regional/add";
const EDITREGIONAL = "/regional/edit";
const TERITORIAL = "/teritori";
const ADDTERITORIAL = "/teritori/add";
const EDITTERITORIAL = "/teritori/edit";
const PROMOTOR = "/sub_teritori";
const ADDPROMOTOR = "/sub_teritori/add";
const EDITPROMOTOR = "/sub_teritori/edit";
const ARTICLE = "/info";
const ADDARTICLE = "/info/add";
const EDITARTICLE = "/info/edit";
const VIDEO = "/video";
const ADDVIDEO = "/video/add";
const EDITVIDEO = "/video/edit";
const GULMACATEGORY = "/gulma_kategori";
const ADDGULMACATEGORY = "/gulma_kategori/add";
const EDITGULMACATEGORY = "/gulma_kategori/edit";
const HAMACATEGORY = "/hama_kategori";
const ADDHAMACATEGORY = "/hama_kategori/add";
const EDITHAMACATEGORY = "/hama_kategori/edit";
const TANAMANCATEGORY = "/tanaman_kategori";
const ADDHTANAMANCATEGORY = "/tanaman_kategori/add";
const EDITTANAMANCATEGORY = "/tanaman_kategori/edit";
const PENYAKITCATEGORY = "/penyakit_kategori";
const ADDPENYAKITCATEGORY = "/penyakit_kategori/add";
const EDITPENYAKITCATEGORY = "/penyakit_kategori/edit";
const PRODUCT = "/produk";
const ADDPRODUCT = "/produk/add";
const EDITPRODUCT = "/produk/edit";
const CHANNEL = "/retailer";
const ADDCHANNEL = "/retailer/add";
const EDITCHANNEL = "/retailer/edit";
const PARTPLANT = "/bagian_tanaman";
const ADDPARTPLANT = "/bagian_tanaman/add";
const EDITPARTPLANT = "/bagian_tanaman/edit";
const SYMPTOM = "/gejala";
const ADDSYMPTOM = "/gejala/add";
const EDITSYMPTOM = "/gejala/edit";
const QUESTION = "/pertanyaan";
const ADDQUESTION = "/pertanyaan/add";
const EDITQUESTION = "/pertanyaan/edit";
const CONSULTATION = "/konsultasi";
const DETAILCONSULTATION = "/konsultasi/detail";
const KATEGORIMARKETPLACE = "/kategori_marketplace";
const ADDKATEGORIMARKETPLACE = "/kategori_marketplace/add"
const EDITKATEGORIMARKETPLACE = "/kategori_marketplace/edit"
const DAFTARONLINESHOP = "/daftar_onlineshop";
const ADDDAFTARONLINESHOP = "/daftar_onlineshop/add";
const EDITDAFTARONLINESHOP = "/daftar_onlineshop/edit";

export {
  QUESTION,
  ADDQUESTION,
  EDITQUESTION,
  SYMPTOM,
  ADDSYMPTOM,
  EDITSYMPTOM,
  PARTPLANT,
  ADDPARTPLANT,
  EDITPARTPLANT,
  TANAMANCATEGORY,
  ADDHTANAMANCATEGORY,
  EDITTANAMANCATEGORY,
  CHANNEL,
  ADDCHANNEL,
  EDITCHANNEL,
  LOGIN,
  FORGOT,
  DASHBOARD,
  FORGOTPASSWORD,
  ADMIN,
  ADDADMIN,
  EDITADMIN,
  EXPERT,
  CUSTOMER,
  ADDEXPERT,
  EDITEXPERT,
  JABATAN,
  ADDJABATAN,
  EDITJABATAN,
  PRODUCTCATEGORY,
  ADDPRODUCTCATEGORY,
  EDITPRODUCTCATEGORY,
  OPTCATEGORY,
  PRODUCT,
  REGIONAL,
  TERITORIAL,
  PROMOTOR,
  ADDPROMOTOR,
  EDITPROMOTOR,
  ARTICLE,
  VIDEO,
  GULMACATEGORY,
  ADDGULMACATEGORY,
  EDITGULMACATEGORY,
  HAMACATEGORY,
  ADDHAMACATEGORY,
  EDITHAMACATEGORY,
  PENYAKITCATEGORY,
  ADDPENYAKITCATEGORY,
  EDITPENYAKITCATEGORY,
  FORGOTSUCCESS,
  ADDARTICLE,
  EDITARTICLE,
  ADDVIDEO,
  EDITVIDEO,
  ADDREGIONAL,
  EDITREGIONAL,
  ADDTERITORIAL,
  EDITTERITORIAL,
  ADDPRODUCT,
  EDITPRODUCT,
  SUPERADMIN,
  ADDSUPERADMIN,
  EDITSUPERADMIN,
  CONSULTATION,
  DETAILCONSULTATION,
  KATEGORIMARKETPLACE,
  ADDKATEGORIMARKETPLACE,
  EDITKATEGORIMARKETPLACE,
  DAFTARONLINESHOP,
  ADDDAFTARONLINESHOP,
  EDITDAFTARONLINESHOP,
};
