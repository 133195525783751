import { Routes, Route } from "react-router-dom";


import { ProtectedRoute } from "../helpers";
import {
  UserPage,
  AddProductCategory,
  ProductCategory,
  AdminPage,
  DashboardPage,
  ForgotPage,
  ForgotPasswordPage,
  LoginPage,
  NotFoundPage,
  EditProductCategory,
  GulmaCategory,
  EditGulmaCategory,
  AddGulmaCategory,
  HamaCategory,
  EditHamaCategory,
  AddHamaCategory,
  PenyakitCategory,
  EditPenyakitCategory,
  AddPenyakitCategory,
  ArticlePage,
  EditArticlePage,
  AddArticlePage,
  VideoPage,
  EditVideoPage,
  AddVideoPage,
  RegionalPage,
  EditRegionalPage,
  AddRegionalPage,
  TeritorialPage,
  AddTeritorialPage,
  EditTeritorialPage,
  PromotorPage,
  AddPromotorPage,
  EditPromotorPage,
  AddAdminPage,
  EditAdminPage,
  ExpertPage,
  AddExpertPage,
  EditExpertPage,
  ProductPage,
  AddProductPage,
  EditProductPage,
  SuperAdminPage,
  AddSuperAdminPage,
  EditSuperAdminPage,
  JabatanPage,
  AddJabatanPage,
  EditJabatanPage,
  ChannelPage,
  AddChannelPage,
  EditChannelPage,
  TanamanCategory,
  AddTanamanCategory,
  EditTanamanCategory,
  PartPlanPage,
  AddPartPlanPage,
  EditPartPlanPage,
  SymptomPage,
  AddSymptomPage,
  EditSymptomPage,
  QuestionPage,
  AddQuestionPage,
  EditQuestionPage,
  KategoriMarketplace,
  AddKategoriMarketplace,
  EditKategoriMarketplace,
  ConsultationPage,
  DetailConsultationPage,
  DaftarOnlineshop,
  AddDaftarOnlineshop,
  EditDaftarOnlineshop,
} from "../pages";
import ForgotSuccess from "../pages/auth/forgotSuccess";
import {
  LOGIN,
  FORGOT,
  DASHBOARD,
  FORGOTPASSWORD,
  ADMIN,
  PRODUCTCATEGORY,
  ADDPRODUCTCATEGORY,
  EDITPRODUCTCATEGORY,
  GULMACATEGORY,
  EDITGULMACATEGORY,
  ADDGULMACATEGORY,
  HAMACATEGORY,
  EDITHAMACATEGORY,
  ADDHAMACATEGORY,
  PENYAKITCATEGORY,
  EDITPENYAKITCATEGORY,
  ADDPENYAKITCATEGORY,
  FORGOTSUCCESS,
  ARTICLE,
  EDITARTICLE,
  ADDARTICLE,
  VIDEO,
  ADDVIDEO,
  EDITVIDEO,
  REGIONAL,
  ADDREGIONAL,
  EDITREGIONAL,
  TERITORIAL,
  ADDTERITORIAL,
  EDITTERITORIAL,
  PROMOTOR,
  ADDPROMOTOR,
  EDITPROMOTOR,
  ADDADMIN,
  EDITADMIN,
  EXPERT,
  ADDEXPERT,
  EDITEXPERT,
  PRODUCT,
  ADDPRODUCT,
  EDITPRODUCT,
  SUPERADMIN,
  ADDSUPERADMIN,
  EDITSUPERADMIN,
  JABATAN,
  ADDJABATAN,
  EDITJABATAN,
  CHANNEL,
  ADDCHANNEL,
  EDITCHANNEL,
  TANAMANCATEGORY,
  ADDHTANAMANCATEGORY,
  EDITTANAMANCATEGORY,
  CUSTOMER,
  PARTPLANT,
  ADDPARTPLANT,
  EDITPARTPLANT,
  SYMPTOM,
  ADDSYMPTOM,
  EDITSYMPTOM,
  QUESTION,
  ADDQUESTION,
  EDITQUESTION,
  CONSULTATION,
  DETAILCONSULTATION,
  KATEGORIMARKETPLACE,
  ADDKATEGORIMARKETPLACE,
  EDITKATEGORIMARKETPLACE,
  DAFTARONLINESHOP,
  ADDDAFTARONLINESHOP,
  EDITDAFTARONLINESHOP,
} from './routesNames';

const Navigation = () => {
  return (
    <>
      <Routes>
        <Route
          path={LOGIN}
          element={
            <ProtectedRoute>
              <LoginPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={FORGOT}
          element={
            <ProtectedRoute>
              <ForgotPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={FORGOTPASSWORD}
          element={
            <ProtectedRoute>
              <ForgotPasswordPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={FORGOTSUCCESS}
          element={
            <ProtectedRoute>
              <ForgotSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          path={DASHBOARD}
          element={
            <ProtectedRoute isLogin>
              <DashboardPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={CONSULTATION}
          element={
            <ProtectedRoute isLogin>
              <ConsultationPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={DETAILCONSULTATION}
          element={
            <ProtectedRoute isLogin>
              <DetailConsultationPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADMIN}
          element={
            <ProtectedRoute isLogin>
              <AdminPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDADMIN}
          element={
            <ProtectedRoute isLogin>
              <AddAdminPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITADMIN}
          element={
            <ProtectedRoute isLogin>
              <EditAdminPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={JABATAN}
          element={
            <ProtectedRoute isLogin>
              <JabatanPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDJABATAN}
          element={
            <ProtectedRoute isLogin>
              <AddJabatanPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITJABATAN}
          element={
            <ProtectedRoute isLogin>
              <EditJabatanPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={EXPERT}
          element={
            <ProtectedRoute isLogin>
              <ExpertPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={CUSTOMER}
          element={
            <ProtectedRoute isLogin>
              <UserPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDEXPERT}
          element={
            <ProtectedRoute isLogin>
              <AddExpertPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITEXPERT}
          element={
            <ProtectedRoute isLogin>
              <EditExpertPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={SUPERADMIN}
          element={
            <ProtectedRoute isLogin>
              <SuperAdminPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDSUPERADMIN}
          element={
            <ProtectedRoute isLogin>
              <AddSuperAdminPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITSUPERADMIN}
          element={
            <ProtectedRoute isLogin>
              <EditSuperAdminPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PRODUCTCATEGORY}
          element={
            <ProtectedRoute isLogin>
              <ProductCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDPRODUCTCATEGORY}
          element={
            <ProtectedRoute isLogin>
              <AddProductCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITPRODUCTCATEGORY}
          element={
            <ProtectedRoute isLogin>
              <EditProductCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path={TANAMANCATEGORY}
          element={
            <ProtectedRoute isLogin>
              <TanamanCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDHTANAMANCATEGORY}
          element={
            <ProtectedRoute isLogin>
              <AddTanamanCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITTANAMANCATEGORY}
          element={
            <ProtectedRoute isLogin>
              <EditTanamanCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path={GULMACATEGORY}
          element={
            <ProtectedRoute isLogin>
              <GulmaCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITGULMACATEGORY}
          element={
            <ProtectedRoute isLogin>
              <EditGulmaCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDGULMACATEGORY}
          element={
            <ProtectedRoute isLogin>
              <AddGulmaCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path={HAMACATEGORY}
          element={
            <ProtectedRoute isLogin>
              <HamaCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITHAMACATEGORY}
          element={
            <ProtectedRoute isLogin>
              <EditHamaCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDHAMACATEGORY}
          element={
            <ProtectedRoute isLogin>
              <AddHamaCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path={HAMACATEGORY}
          element={
            <ProtectedRoute isLogin>
              <HamaCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITHAMACATEGORY}
          element={
            <ProtectedRoute isLogin>
              <EditHamaCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDHAMACATEGORY}
          element={
            <ProtectedRoute isLogin>
              <AddHamaCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path={PENYAKITCATEGORY}
          element={
            <ProtectedRoute isLogin>
              <PenyakitCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITPENYAKITCATEGORY}
          element={
            <ProtectedRoute isLogin>
              <EditPenyakitCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDPENYAKITCATEGORY}
          element={
            <ProtectedRoute isLogin>
              <AddPenyakitCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path={ARTICLE}
          element={
            <ProtectedRoute isLogin>
              <ArticlePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITARTICLE}
          element={
            <ProtectedRoute isLogin>
              <EditArticlePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDARTICLE}
          element={
            <ProtectedRoute isLogin>
              <AddArticlePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={VIDEO}
          element={
            <ProtectedRoute isLogin>
              <VideoPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITVIDEO}
          element={
            <ProtectedRoute isLogin>
              <EditVideoPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDVIDEO}
          element={
            <ProtectedRoute isLogin>
              <AddVideoPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={REGIONAL}
          element={
            <ProtectedRoute isLogin>
              <RegionalPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDREGIONAL}
          element={
            <ProtectedRoute isLogin>
              <AddRegionalPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITREGIONAL}
          element={
            <ProtectedRoute isLogin>
              <EditRegionalPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={TERITORIAL}
          element={
            <ProtectedRoute isLogin>
              <TeritorialPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDTERITORIAL}
          element={
            <ProtectedRoute isLogin>
              <AddTeritorialPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITTERITORIAL}
          element={
            <ProtectedRoute isLogin>
              <EditTeritorialPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PROMOTOR}
          element={
            <ProtectedRoute isLogin>
              <PromotorPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDPROMOTOR}
          element={
            <ProtectedRoute isLogin>
              <AddPromotorPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITPROMOTOR}
          element={
            <ProtectedRoute isLogin>
              <EditPromotorPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PRODUCT}
          element={
            <ProtectedRoute isLogin>
              <ProductPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDPRODUCT}
          element={
            <ProtectedRoute isLogin>
              <AddProductPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITPRODUCT}
          element={
            <ProtectedRoute isLogin>
              <EditProductPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={CHANNEL}
          element={
            <ProtectedRoute isLogin>
              <ChannelPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDCHANNEL}
          element={
            <ProtectedRoute isLogin>
              <AddChannelPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITCHANNEL}
          element={
            <ProtectedRoute isLogin>
              <EditChannelPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PARTPLANT}
          element={
            <ProtectedRoute isLogin>
              <PartPlanPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDPARTPLANT}
          element={
            <ProtectedRoute isLogin>
              <AddPartPlanPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITPARTPLANT}
          element={
            <ProtectedRoute isLogin>
              <EditPartPlanPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={SYMPTOM}
          element={
            <ProtectedRoute isLogin>
              <SymptomPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDSYMPTOM}
          element={
            <ProtectedRoute isLogin>
              <AddSymptomPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITSYMPTOM}
          element={
            <ProtectedRoute isLogin>
              <EditSymptomPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={QUESTION}
          element={
            <ProtectedRoute isLogin>
              <QuestionPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDQUESTION}
          element={
            <ProtectedRoute isLogin>
              <AddQuestionPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITQUESTION}
          element={
            <ProtectedRoute isLogin>
              <EditQuestionPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={KATEGORIMARKETPLACE}
          element={
            <ProtectedRoute isLogin>
              <KategoriMarketplace />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDKATEGORIMARKETPLACE}
          element={
            <ProtectedRoute isLogin>
              <AddKategoriMarketplace />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITKATEGORIMARKETPLACE}
          element={
            <ProtectedRoute isLogin>
              <EditKategoriMarketplace />
            </ProtectedRoute>
          }
        />
        <Route
          path={DAFTARONLINESHOP}
          element={
            <ProtectedRoute isLogin>
              <DaftarOnlineshop />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADDDAFTARONLINESHOP}
          element={
            <ProtectedRoute isLogin>
              <AddDaftarOnlineshop />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDITDAFTARONLINESHOP}
          element={
            <ProtectedRoute isLogin>
              <EditDaftarOnlineshop />
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={
            <ProtectedRoute isLogin>
              <NotFoundPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
};

export default Navigation;
