import { Button, CardContainer, Input,  } from '../../components'
import UseFormPlantParts from './hookForm';

const AddChannelPage = () => {
    const {
        isLoadingPost,
        navigate,
        handleChange,
        handleSubmit        
    } = UseFormPlantParts({
        url : "/v1/plantParts",
        type : 'post',
    })

  return (
      <CardContainer>
        <form onSubmit={e =>  handleSubmit(e) }>
            <div className="col-lg-8 col-12">                   
                <Input
                    id="name"
                    onChange={handleChange}
                    placeholder="Nama Tanaman" 
                    label="Nama Tanaman" />   
                <div className="d-flex justify-content-end">
                    <div className="me-4">
                        <Button 
                            onClick={() => navigate(-1)}
                            type="button"
                            className={`btn-light w-100 mt-4 px-5`} 
                            title="Batalkan" />
                    </div>
                    <Button 
                        type="submit"
                        className={`btn-primary w-100 mt-4 px-5`} 
                        title="Simpan" 
                        isLoading={isLoadingPost} />
                </div>
            </div>
        </form>
      </CardContainer>
  )
}

export default AddChannelPage;