import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";
import App from "./App";

// Redux
import { store, persistor } from "./stores/stores";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>

          <App />
          </BrowserRouter>

        </PersistGate>
      </Provider>
  // </React.StrictMode>
);
