import React from 'react'
import { AsyncPaginate } from "react-select-async-paginate";
import { UseGetParamsById } from '../../../hooks/api';
import './selectAsync.scss';

const SelectAsync2 = props => {
    const {
        filter,
        isLoading,
        isLoadingParams,
        data,
        handleFilter,
    } = UseGetParamsById(props.url, props.paramsId, 1000)



    const loadOptions = async (search, prevOptions) => {
        if (!isLoading) {
            let hasMore = false;
            const masterData = [...data];
            const currentData = masterData.filter((item) => {
                // if (props.labelName !== "name") {
                return item[props.labelName].toLowerCase().includes(search);
                // }
                // return item.name.toLowerCase().includes(search);
            });

            return {
                options: currentData.map(res => ({
                    label: res[props.labelName],
                    value: res[props.valueName],
                    name: props.id
                })),
                hasMore: hasMore
            }

        }

        // if(!isLoading){
        //     let hasMore = false ; 

        //     if(hasMore){
        //         handleFilter({
        //             name : 'page',
        //             value : {
        //                 name : filter.page + 1
        //             }
        //         });
        //     }

        //     if(search !== filter.search){
        //         handleFilter({
        //             name : 'search',
        //             value : {
        //                 page : 1,
        //                 name : search
        //             }
        //         });
        //     }

        //     console.log(data,'data')

        //     return {
        //         options : data.map(res =>({
        //             label: res[props.labelName] ,
        //             value: res[props.valueName],
        //             name : props.id
        //         })),
        //         hasMore : hasMore
        //     }            
        // }        
    }

    return (
        <div className="form-group mt-1" style={{ zIndex: 5 }}>
            {
                props.label ?
                    <label htmlFor={props.id} className="mt-3">{props.label} {props.required ? <span className="text-danger">*</span> : ''}</label> : null
            }
            {
                isLoadingParams ?
                    null :
                    <AsyncPaginate
                        isLoading={isLoading}
                        defaultValue={props.value}
                        className={props.className}
                        placeholder={props.placeHolder}
                        loadOptions={loadOptions}
                        onChange={props.onChange}
                    />
            }

        </div>
    )
}

SelectAsync2.defaultProps = {
    paramsId: '',
    isValueable: true,
}


export default SelectAsync2