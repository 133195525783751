import { Button, CardContainer, Input, InputSelectAsync, Select } from '../../../components'
import Table from '../../../components/organisms/table'
import columns from './column'
import { IconImport } from '../../../assets/icons';
import { Link } from 'react-router-dom';
import { ADDEXPERT, ADDSUPERADMIN } from '../../../routes/routesNames';
import { UseGet } from '../../../hooks/api';
import { UseTable } from '../../../hooks';

const SuperAdmin = () => {    
    const {
        filter,
        isLoading,
        data,
        error,
        handlePagination,
        handleLimit,
        handleFilter,
        totalPages,
    } = UseGet('/v1/account/super' )

    const {
        isLoading : isLoadingPost ,
        dataRemove,
        handleSelected,
        handleRemove
    } = UseTable();

  return (
      <CardContainer >
            <div className="d-flex flex-row justify-content-between">
                <Input type="search" id="search"  placeholder="Cari Super Admin" onChange={handleFilter} />
                {
                    dataRemove.length === 0  ? 
                    <div className="d-flex flex-row">
                        <div className="me-2">
                            <Select 
                                id="status"
                                onChange={handleFilter}
                                placeHolder="Semua Status"
                                data={[
                                    {
                                        id : 'aktif',
                                        name : 'Aktif'
                                    },
                                    {
                                        id : 'in aktif',
                                        name : 'In aktif'
                                    },
                                ]} 
                            />
                        </div>                        
                        {/* <Button icon={IconImport} title="Import" className="btn btn-primary-light me-3 px-4" isSmall />                 */}
                        <Link to={ADDSUPERADMIN}>
                            <Button title="Tambah Super Admin" className="btn btn-primary" isSmall />                
                        </Link>
                    </div>
                    : 
                    <Button 
                        isLoading={isLoadingPost} 
                        onClick={() =>  handleRemove('/v1/account/super/delete')}  
                        title="Hapus Super Admin" 
                        className="btn btn-danger" isSmall />     
                }
            </div>
            <Table 
                filter={filter}
                isLoading={isLoading}
                error={error}
                data={data}
                totalPages={totalPages}
                handlePagination={handlePagination}
                handleLimit={handleLimit}
                handleChangeSelect={handleSelected} 
                columns={columns}/>  
      </CardContainer>
  )
}

export default SuperAdmin