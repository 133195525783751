import IconArrowLeft from "./icon_arrow_right.svg";
import IconEyeSlash from "./icon_eye_slash.svg";
import IconEye from "./icon_eye.svg";
import IconLock from "./icon_lock.svg";
import IconUser from "./icon_user.svg";
import IconCatalogActive from "./icon_catalog_active.svg";
import IconCatalog from "./icon_catalog.svg";
import IconEducationActive from "./icon_education_active.svg";
import IconEducation from "./icon_education.svg";
import IconHomeActive from "./icon_home_active.svg";
import IconHome from "./icon_home.svg";
import IconImport from "./icon_import.svg";
import IconMapActive from "./icon_map_active.svg";
import IconMap from "./icon_map.svg";
import IconSearch from "./icon_search.svg";
import IconSidebarArrowActive from "./icon_sidebar_arrow_active.svg";
import IconSidebarArrow from "./icon_sidebar_arrow.svg";
import IconUsersActive from "./icon_users_active.svg";
import IconUsers from "./icon_users.svg";
import IconPencil from "./icon_pencil.svg";
import IconAddFile from "./icon_add_file.svg";
import IconArrowUp from "./icon_arrow_up_select.svg";
import IconArrowDown from "./icon_arrow_down_select.svg";
import IconAddDomisili from "./icon_add_domisili.svg";
import IconTrash from "./icon_trash.svg";
import IconChannel from "./icon_channel.svg";
import IconChannelActive from "./icon_channel_active.svg";
import IconPakarActive from "./icon_pakar_active.svg";
import IconPakar from "./icon_pakar.svg";
import IconAddImage from "./icon_add_image.svg";
import IconRemove from "./icon_remove.svg";
import IconRemoveDanger from "./icon_remove_danger.svg";
import IconConsultation from "./icon_consultation.svg";
import IconConsultationActive from "./icon_consultation_active.svg";
import IconQuestion from "./icon_question.svg";
import IconQuestionActive from "./icon_question_active.svg";
import IconStore from "./icon_store.svg";
import IconStoreActive from "./icon_store_active.svg";

export {
  // IconArrowLeft,
  IconRemoveDanger,
  IconRemove,
  IconAddImage,
  IconPakar,
  IconPakarActive,
  IconChannel,
  IconChannelActive,
  IconArrowLeft,
  IconEyeSlash,
  IconEye,
  IconLock,
  IconUser,
  IconCatalogActive,
  IconCatalog,
  IconEducationActive,
  IconEducation,
  IconHomeActive,
  IconHome,
  IconImport,
  IconMapActive,
  IconMap,
  IconSearch,
  IconSidebarArrowActive,
  IconSidebarArrow,
  IconUsersActive,
  IconUsers,
  IconPencil,
  IconAddFile,
  IconArrowUp,
  IconArrowDown,
  IconAddDomisili,
  IconTrash,
  IconConsultation,
  IconConsultationActive,
  IconQuestion,
  IconQuestionActive,
  IconStore,
  IconStoreActive,
};
