import React, { useState ,useEffect} from 'react';
import {  logout } from '../../../stores/auth';
import {loginData} from '../../../stores/auth';
import { Link, useLocation } from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';
import Button from '../../atoms/button'
import './header.scss'
import { UseModal } from '../../../hooks';

const Header = () => {
  const {
    isShowModal,
    setIsShowModal,
    wrapperRef,
} = UseModal()

const dispatch = useDispatch();
const location = useLocation();
const dataLogin = useSelector(loginData);

const [pathName, setPathName] = useState("");
const [breadCumb, setBreadCumb] = useState([]);

useEffect(() => {
  const path = location.pathname.split("/");
  if(path[1] === ""){
    setPathName("dashboard");
  }else{
    const titleReplace = path[1].replace("_", " ");
    setPathName(titleReplace);
  }
  setBreadCumb(path)
}, [location]);


  return (
    <div className="header">
        <div className="d-flex flex-column">
            <span className="title-page">{pathName}</span>
            <div className="breadcumb d-flex flex-row">
                {
                  breadCumb.map((data,index) => (
                    <div key={index}>
                      {
                        index !== 0 ?
                        <span className={index == breadCumb.length -1 && index !== 1 ? "breadcumb-active" : ''} key={index}>
                          {
                            index == breadCumb.length -1 ?
                            data.replace("_", " ") : 
                            <Link to={data} >
                              {data.replace("_", " ")}
                            </Link>
                          }
                          {
                             index != breadCumb.length -1  ?
                             <span className="mx-2"> - </span> : null
                          }
                        </span> : null
                      }
                    </div>
                  ))
                }
            </div>
        </div>
        <div className="d-flex flex-row align-items-center" style={{
              zIndex : 999,
            }} ref={wrapperRef}>
            <span>{dataLogin.data.name}</span>
            <div className="position-relative" >
                <img onClick={() => setIsShowModal(!isShowModal)} className="avatar-img" src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png" alt="" />
                {
                    isShowModal ? 
                    <div className="profile-menu">
                        <Button onClick={() => dispatch(logout())} className="btn-danger w-100" title="Logout" />
                    </div> : null 
                }
            </div>
        </div>
    </div>
  )
}

export default Header