const OverFlowText = ({text}) => {
    let richText = '';
    if(text.length > 120){
        richText = text.substring(0, 120) + "...";
    }else{
        richText = text;
    }
    return  <div  dangerouslySetInnerHTML={{__html: richText}} />
}

export default OverFlowText;