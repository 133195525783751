import { useLocation, useNavigate } from "react-router-dom";
import { UseForm } from "../../../hooks";
import { UsePost, UsePut } from "../../../hooks/api";
import { useEffect, useState } from "react";
import { DAFTARONLINESHOP } from "../../../routes/routesNames";

const UseFormDaftarOnlineshop = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isLoading: isLoadingPost, handlePost } = UsePost();
    const { isLoading: isLoadingPut, handlePut } = UsePut();
    const [isReady, setIsReady] = useState(false);
    const { form, setForm, content, setContent, handleChange, handleChangeRich, } = UseForm();

    useEffect(() => {
        if (props.type === 'put') {
            if (location.state) {
                setForm({
                    ...location.state,
                    id_kategori: location.state.kategori._id,
                    id_provinsi: location.state.provinsi._id,
                    id_kota: location.state.kota._id,
                    id_kecamatan: location.state.kecamatan._id,
                    status: location.state.status === "aktif" ? true : false
                });
                setContent(location.state.keterangan)
                setIsReady(true);
            } else {
                navigate(DAFTARONLINESHOP)
            }
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (props.type === "post") {
            let params = {
                url: props.url,
                form: {
                    ...form,
                    keterangan: content,
                    status: form.status ? "aktif" : "in aktif"
                },
                navigation: DAFTARONLINESHOP
            }
            console.log(params);
            handlePost(params)
        } else {
            let params = {
                url: props.url + '?_id=' + form._id,
                form: {
                    ...form,
                    keterangan: content,
                    status: form.status ? "aktif" : "in aktif"
                },
                navigation: DAFTARONLINESHOP
            }
            handlePut(params)
        }
    }

    return {
        handleSubmit,
        form,
        content,
        navigate,
        handleChange,
        handleChangeRich,
        isLoadingPut,
        isReady,
        isLoadingPost,
    };
}
export default UseFormDaftarOnlineshop