import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UseForm } from "../../hooks";
import { UsePost, UsePut } from "../../hooks/api";

const UseFormDomisili = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading: isLoadingPost, handlePost } = UsePost();
  const { isLoading: isLoadingPut, handlePut } = UsePut();
  const { form, setForm, handleChange } = UseForm();

  useEffect(() => {
    if (props.type === "put") {
      if (location.state) {
        let tempArea = [];

        location.state.area.province.map((data) => {
          tempArea.push([
            {
              _id: data.id,
              name: data.name,
            },
          ]);
        });

        location.state.area.city.map((data, index) => {
          tempArea[index].push({
            _id: data.id,
            name: data.name,
          });
        });

        location.state.area.district.map((data, index) => {
          tempArea[index].push({
            _id: data.id,
            name: data.name,
          });
        });

        location.state.area.village.map((data, index) => {
          tempArea[index].push({
            _id: data.id,
            name: data.name,
          });
        });

        setForm({
          ...location.state,
          domisiliSelected: tempArea,
        });
      } else {
        navigate(props.redirect);
      }
    }
  }, []);

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();

    let province_id = [];
    let city_id = [];
    let district_id = [];
    let village_id = [];

    form.domisiliSelected.map((dataParent) => {
      dataParent.map((data, index) => {
        if (index === 0 && data.name !== "") {
          province_id.push(data._id);
        } else if (index === 1 && data.name !== "") {
          city_id.push(data._id);
        } else if (index === 2 && data.name !== "") {
          district_id.push(data._id);
        } else if (index === 3 && data.name !== "") {
          village_id.push(data._id);
        }
      });
    });

    if (props.type === "put") {
      let params = {
        url: props.url + "?_id=" + form._id,
        form: {
          ...form,
          type: props.formType,
          province_id: province_id,
          city_id: city_id,
          district_id: district_id,
          village_id: village_id,
        },
        navigation: props.redirect,
      };

      handlePut(params);
    } else {
      let params = {
        url: props.url,
        form: {
          ...form,
          type: props.formType,
          province_id: province_id,
          city_id: city_id,
          district_id: district_id,
          village_id: village_id,
        },
        navigation: props.redirect,
      };

      handlePost(params);
    }
  };

  return {
    handleSubmit,
    form,
    handleChange,
    isLoadingPost,
    isLoadingPut,
  };
};

export default UseFormDomisili;
