const axios = require("axios");

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
    // "X-API-KEY": process.env.REACT_APP_API_KEY,
    "X-API-KEY": "$2a$12$LnRdbXKAbhWaVbg6PWEbQ.H2wlcI0fHMcmidb628SCuwe5cNX8UHW",
  },
});
