import React from 'react'

const NotFound = () => {
  return (
    <div style={{
        height: '80vh'
    }} className="d-flex justify-content-center align-items-center w-100">404 | Not Fund</div>
  )
}

export default NotFound