import React from 'react'
import PropTypes from 'prop-types';
import { IconAddImage, IconRemove } from '../../../assets/icons';
import { useState } from "react";
import './inputImageMultiple.scss'
import { useEffect } from 'react';

const InputImageMultiple = props => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        if(props.value){
            setImages(props.value)
        }
    },[props.value]);

    const handleChange = e => {
        const { files } = e.target;    
        if(files[0]){
           if(files[0].type === 'image/jpeg' || files[0].type === 'image/png'){
                const imagesTemp = [
                    ...images
                ]
                imagesTemp.push(files[0])  
                props.onChange({
                    name : props.id,
                    value : imagesTemp
                })
                setImages(imagesTemp)
            }
       }
    }

    const handleRemove = index => {
        const imagesTemp = [
            ...images
        ]
        imagesTemp.splice(index, 1);
        props.onChange({
            name : props.id,
            value : imagesTemp
        })
        setImages(imagesTemp)
    }

    return (
        <div className={`form-group ${props.label ? 'mt-3' : ''}`}>            
            {
                props.label ?
                <label htmlFor={props.id}>{props.label}</label> : null
            }
            <div className="d-flex flex-row">
                {
                    images.map((data,index) => {
                            return <div key={index}>
                                <div className="position-relative file-multiple-container">
                                    <img onClick={() => handleRemove(index)} src={IconRemove} alt="Icon Remove" className="file-multiple-remove" />
                                    <img src={data ? typeof(data) === 'string' ? data : URL.createObjectURL(data) : IconAddImage} alt="icon add image"  className="cursor-pointer input-file-image" />
                                </div>
                            </div>
                    })
                }
                {
                    images.length < 3 ?
                    <label className="file-multiple-container" htmlFor={`file-multiple-${images.length}`}>
                        <input onChange={handleChange} id={`file-multiple-${images.length}`} type="file" name={`file-multiple-${images.length}`} className="input-file-multiple" />
                        <img src={IconAddImage} alt="icon add image"  className="cursor-pointer input-file-image" />
                    </label>  : null
                }
            </div>
            <span className="file-multiple-desc">{props.placeholder}</span>
        </div>
    )
}

InputImageMultiple.propTypes = {
    id : PropTypes.string,
    label : PropTypes.string,
    placeholder : PropTypes.string,
    value : PropTypes.any,
    isReadOnly : PropTypes.bool,
    className : PropTypes.string,
    onChange : PropTypes.func,
    required : PropTypes.bool,
};

export default InputImageMultiple