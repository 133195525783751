import { Button, CardContainer, Input, InputToggle, RichText, SelectAsync2 } from "../../../components"
import UseFormDaftarOnlineshop from "./hookForm"

const AddDaftarOnlineshop = () => {
    const {
        isLoadingPost,
        navigate,
        form,
        handleChange,
        handleSubmit,
        handleChangeRich,
    } = UseFormDaftarOnlineshop({
        url: "/v1/official_online_shop",
        type: 'post',
    })
    return (
        <CardContainer>
            <form onSubmit={e => handleSubmit(e)}>
                <div className="col-lg-8 col-12">
                    <Input
                        id="nama"
                        onChange={handleChange}
                        placeholder="Masukkan nama store"
                        label="Nama Store" />
                    <SelectAsync2
                        onChange={handleChange}
                        labelName="name"
                        valueName="_id"
                        id="id_kategori"
                        url="/v1/category_market_place"
                        label="Kategori"
                        placeHolder="Pilih Kategori" />
                    <SelectAsync2
                        onChange={handleChange}
                        labelName="name"
                        valueName="_id"
                        id="id_provinsi"
                        url="/v1/province"
                        label="Provinsi"
                        placeHolder="Pilih Provinsi" />
                    <SelectAsync2
                        onChange={handleChange}
                        labelName="name"
                        valueName="_id"
                        id="id_kota"
                        url="/v1/city"
                        paramsId={`?province_id=${form.id_provinsi}`}
                        label="Kota"
                        placeHolder="Pilih Kota" />
                    <SelectAsync2
                        onChange={handleChange}
                        labelName="name"
                        valueName="_id"
                        id="id_kecamatan"
                        url="/v1/district"
                        paramsId={`?city_id=${form.id_kota}`}
                        label="Kecamatan"
                        placeHolder="Pilih Kecamatan" />
                    <Input
                        id="link_marketplace"
                        onChange={handleChange}
                        placeholder="Masukkan nama store"
                        label="Link Marketplace" />
                    <RichText
                        onChange={handleChangeRich}
                        label="Keterangan"
                        id="keterangan" />
                    <InputToggle
                        id="status"
                        onChange={handleChange}
                        checked={form.status}
                        label="Status Store"
                        placeholder="Status store berupa Active atau Inactive" />
                    <div className="d-flex justify-content-end">
                        <div className="me-4">
                            <Button
                                onClick={() => navigate(-1)}
                                type="button"
                                className={`btn-light w-100 mt-4 px-5`}
                                title="Batalkan" />
                        </div>
                        <Button
                            type="submit"
                            className={`btn-primary w-100 mt-4 px-5`}
                            title="Simpan"
                            isLoading={isLoadingPost} />
                    </div>
                </div>
            </form>
        </CardContainer>
    )
}
export default AddDaftarOnlineshop