import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios, errorResponse } from "../helpers";

const initialState = {
  data: {},
  isLogin: false,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const loginAction = createAsyncThunk("auth/login", async (form) => {
  try {
    const response = await axios.post("/v1/auth/login", form);
    return response.data;
  } catch (err) {
    return errorResponse(err);
  }
});

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    enterPage: (state) => {
      state.status = "idle";
      state.error = null;
    },
    logout: (state) => {
      state.status = "idle";
      state.isLogin = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(loginAction.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(loginAction.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.status = "succeeded";
        state.isLogin = true;
        state.data = action.payload.data;
        state.error = null;
      } else {
        state.status = "failed";
        state.error = action.payload;
      }
    });
    builder.addCase(loginAction.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  },
});

export const loginData = (state) => state.login;
export const { logout, enterPage } = loginSlice.actions;

export default loginSlice.reducer;
