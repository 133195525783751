import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { axios, errorResponse } from "../../helpers";
import { loginData, logout } from "../../stores/auth";

const useGet = (url, limit) => {
  const dispatch = useDispatch();
  const dataLogin = useSelector(loginData);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState({
    page: 1,
    limit: limit ? limit : 10,
    isUpdate: false,
    wilayah_kerja: "",
    search: "",
  });

  useEffect(() => {
    handleFetchData();
  }, [filter]);

  // handle
  const handlePagination = (e) => {
    setFilter({
      ...filter,
      page: e.selected + 1,
    });
  };
  const handleLimit = (e) => {
    setFilter({
      search: "",
      page: 1,
      limit: e.target.value,
    });
  };
  const handleFilter = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      
      if(value) {
        const {page, ...newFilter} = filter;
        setFilter({
          page:1,
          ...newFilter,
          [name]: value,
        });
      } else {
        setFilter({
          ...filter,
          [name]: value,
        });
    }

    } else {
      setFilter({
        ...filter,
        [e.name]: e.value,
      });
    }
  };

  const handleFetchData = async () => {
    setIsLoading(true);
    let urlParamsFilter = "";
    let operator = "?";

    Object.keys(filter).map(function (key, index) {
      if (filter[key] && key !== "token") {
        urlParamsFilter += `${index === 0 ? "" : "&"}${[key]}=${filter[key]}`;
      }
    });

    if (url.includes("?")) {
      operator = "&";
    }
    console.log(url);
    try {
      const response = await axios.get(
        `${url}${urlParamsFilter !== "" ? operator : ""}${urlParamsFilter}`,
        {
          headers: { Authorization: `Bearer ${dataLogin.data.token}` },
        }
      );
      if (response.data.status) {
        setData(response.data.data);
        setTotalData(response.data.total_data);
        setTotalPages(response.data.total_page);
        setError("");
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      if (errorResponse(err) === "jwt expired") {
        dispatch(logout());
      } else {
        return errorResponse(err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    filter,
    isLoading,
    data,
    error,
    totalPages,
    totalData,
    handleFetchData,
    handlePagination,
    handleLimit,
    handleFilter,
  };
};

export default useGet;
