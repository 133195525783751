import { useState } from "react";
import { GetQuery } from "../helpers";

const UseForm = () => {
  const [form, setForm] = useState({
    domisiliSelected: [],
    wilayahKerja: {},
    jenis_tanaman: [],
    jenis_opt: [],
    type_of_plant: [],
    reference_id: "",
    jabatan: "",
    terbitkan: "sekarang",
    isActive: true,
    jenis_produk: "OPT",
  });
  const [content, setContent] = useState("");
  const [showDomisilii, setShowDomisili] = useState({
    city: false,
    district: false,
    village: false,
  });
  const handleChange = (e) => {
    if (e.target) {
      const { files, name, value, type, checked } = e.target;
      if (type && files && type === "file") {
        setForm({
          ...form,
          [name]: files[0],
        });
      } else if (type === "checkbox") {
        if (name === "jenis_tanaman") {
          if (checked) {
            setForm({
              ...form,
              jenis_tanaman: [...form.jenis_tanaman, value],
            });
          } else {
            let typeOFPlantTemp = [];

            form.jenis_tanaman.map((data) => {
              if (data !== value) {
                typeOFPlantTemp.push(data);
              }
            });
            setForm({
              ...form,
              jenis_tanaman: typeOFPlantTemp,
            });
          }
        } else if (name === "jenis_opt") {
          if (checked) {
            setForm({
              ...form,
              jenis_opt: [...form.jenis_opt, value],
            });
          } else {
            let typeOFPlantTemp = [];

            form.jenis_opt.map((data) => {
              if (data !== value) {
                typeOFPlantTemp.push(data);
              }
            });
            setForm({
              ...form,
              jenis_opt: typeOFPlantTemp,
            });
          }
        }
      } else {
        if (name === "link_vidio") {
          if (value.includes("fb.watch")) {
            setForm({
              ...form,
              [name]: value,
            });
          } else {
            const codeYoutube = GetQuery(value);
            setForm({
              ...form,
              [name]: codeYoutube.v,
            });
          }
        } else {
          setForm({
            ...form,
            [name]: value,
          });
        }
      }
    } else {
      const { value, name } = e;
      if (name === "province") {
        setForm({
          ...form,
          [name]: value,
          city: "",
          district: "",
          village: "",
        });
      } else if (name === "city") {
        setForm({
          ...form,
          [name]: value,
          district: "",
          village: "",
        });
      } else if (name === "district") {
        setForm({
          ...form,
          [name]: value,
          village: "",
        });
        setShowDomisili({
          city: true,
          district: true,
          village: false,
        });
      } else if (name === "village") {
        setForm({
          ...form,
          [name]: value,
        });
      } else {
        setForm({
          ...form,
          [name]: value,
        });
      }
    }
  };
  const handleChangeRich = (e) => {
    if (e === "<p><br></p>") {
      setContent("");
    } else {
      setContent(e);
    }
  };

  return {
    form,
    content,
    handleChangeRich,
    setContent,
    setForm,
    handleChange,
  };
};

export default UseForm;
