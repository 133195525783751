import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CHANNEL, PARTPLANT } from "../../routes/routesNames";
import { UseForm } from "../../hooks";
import { UsePost, UsePut } from "../../hooks/api";

const UseFormChannel = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading: isLoadingPost, handlePost } = UsePost();
  const { isLoading: isLoadingPut, handlePut } = UsePut();
  const { form, setForm, handleChange } = UseForm();

  useEffect(() => {
    if (props.type === "put") {
      if (location.state) {
        setForm({
          ...location.state,
        });
      } else {
        navigate(CHANNEL);
      }
    }
  }, []);

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.type === "post") {
      let params = {
        url: props.url,
        form: {
          ...form,
        },
        navigation: PARTPLANT,
      };
      handlePost(params);
    } else {
      let params = {
        url: props.url + "?_id=" + form._id,
        form: {
          ...form,
        },
        navigation: PARTPLANT,
      };
      handlePut(params);
    }
  };

  return {
    handleSubmit,
    form,
    navigate,
    handleChange,
    isLoadingPut,
    isLoadingPost,
  };
};

export default UseFormChannel;
