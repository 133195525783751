import { Link } from "react-router-dom";
import { IconPencil } from "../../assets/icons";
import { ButtonAction } from "../../components";
import {
  EDITCHANNEL,
  EDITPARTPLANT,
  PARTPLANT,
} from "../../routes/routesNames";

const columns = [
  {
    name: "Bagian Tanaman",
    selector: (row) => row.name,
    sortable: true,
    minWidth: "200px",
  },

  {
    name: "Aksi",
    selector: (row) => row.name,
    sortable: true,
    maxWidth: "200px",
    format: (row, index) => {
      // return <Dropdown
      //             detail={row}
      //             data={[
      //                 {
      //                     title : 'Lihat',
      //                     path : '',
      //                     type : 'modal',

      //                 },
      //                 {
      //                     title : 'Ubah',
      //                     path : EDITADMIN,
      //                     type : 'path'
      //                 },
      //             ]} />
      return (
        <Link to={EDITPARTPLANT} state={row}>
          <ButtonAction title="Ubah" icon={IconPencil} />
        </Link>
      );
    },
  },
];

export default columns;
