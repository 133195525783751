import {  Button, CardContainer, Input, InputSelect, InputTags, InputToggle, RadioButton, RichText, SelectAsync2, SelectMultipleCheckbox, SelectMultipleCheckboxRow, SelectMultipleCheckboxStatic } from '../../components'
import { DateToday } from '../../helpers';
import UseFormArticle from './hookForm';

const AddArticle = () => {
    const {
        isLoadingPostDataForm,
        form,
        isEnable,
        navigate,
        handleChange,
        handleChangeRich,
        handleSubmit        
    } = UseFormArticle({
        type : 'post',
    })

    return (
        <CardContainer>
            <form onSubmit={e => isEnable ? handleSubmit(e) : {}}>
                <div className="col-lg-10 col-12">
                    <div className="col-lg-8 col-12">
                        <Input
                            type="file"
                            id="image_product"
                            onChange={handleChange}
                            placeholder="Format foto/gambar .JPG, .JPEG, .PNG, maksimal 2MB, dengan aspect ratio 1 : 1." 
                            label="Unggah Gambar" />                                               
                        <Input
                            id="product_name"
                            onChange={handleChange}
                            placeholder="Masukan Nama Produk" 
                            label="Nama Produk" /> 
                        <SelectAsync2                  
                            onChange={handleChange} 
                            labelName="name"
                            valueName="name"
                            id="kategori"
                            paramsId="?category_type=product"
                            url="/v1/kategori"
                            label="Kategori Produk"
                            placeHolder="Pilih Kategori Produk..." />   
                        <p className="mt-3">Digunakan untuk:</p>                                                                             
                        <SelectMultipleCheckbox 
                            id="jenis_tanaman"
                            placeHolder="Pilih jenis tanaman"
                            onChange={handleChange}
                            url="/v1/kategori?category_type=tanaman"
                            label="Jenis Tanaman" 
                            typeLabel="label-info" />     

                        <div className="form-group mt-3">
                            <label>Jenis OPT</label>
                            <SelectMultipleCheckboxRow 
                                id="categori_hama"
                                placeHolder="Pilih Hama"
                                onChange={handleChange}
                                url="/v1/kategori?category_type=hama"
                                label="Hama" 
                                typeLabel="label-info" />     
                            <SelectMultipleCheckboxRow 
                                id="categori_gulma"
                                placeHolder="Pilih Gulma"
                                onChange={handleChange}
                                url="/v1/kategori?category_type=gulma"
                                label="Gulma" 
                                typeLabel="label-info" />     
                            <SelectMultipleCheckboxRow 
                                id="categori_penyakit"
                                placeHolder="Pilih Penyakit"
                                onChange={handleChange}
                                url="/v1/kategori?category_type=opt"
                                label="Penyakit" 
                                typeLabel="label-info" />     
                        </div>  
                        <InputToggle 
                            id="is_unggulan"
                            onChange={handleChange}
                            checked={form.is_unggulan}
                            label="Jadikan Produk Unggulan" 
                            placeholder="Menjadikan status produk unggulan" />                                               
                        <InputSelect
                            label="Status" 
                            id="status"
                            placeHolder="Pilih Status"
                            onChange={handleChange}
                            data={[
                                {
                                    id : 'publish',
                                    name : 'Publish'
                                },
                                {
                                    id : 'arsip',
                                    name : 'Arsip'
                                }
                            ]} 
                        />                                                                                               
                    </div> 
                    <RichText   
                        onChange={handleChangeRich} 
                        label="Deskripsi" 
                        id="desc" />
                    <div className="d-flex justify-content-end">
                        <div className="me-4">
                            <Button 
                                onClick={() => navigate(-1)}
                                type="button"
                                className={`btn-light w-100 mt-4 px-5`} 
                                title="Batalkan" />
                        </div>
                        <Button 
                            disabled={!isEnable}
                            type="submit"
                            className={`${isEnable  ? 'btn-primary' : 'btn-secondary'}  w-100 mt-4 px-5`} 
                            title="Buat" 
                            isLoading={isLoadingPostDataForm} />
                    </div>
                </div>
            </form>
        </CardContainer>
    )
}

export default AddArticle