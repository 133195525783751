import React from 'react'
import PropTypes from 'prop-types';
import { useState } from "react";
import './inputMdMb.scss'
import { SelectAsync2,Input,Button} from '../../../components'
import { IconRemoveDanger } from '../../../assets/icons';
import { useEffect } from 'react';

const InputMdMb = props => {
    const [fields, setFields] = useState([
        {
            id_penyakit : {},
            md_penyakit : 0,
            mb_penyakit : 0,
        }
    ]);

    useEffect(() => {
        if(props.value){
            const fieldTemp = []

            props.value.map((data) => {
                fieldTemp.push({
                    id_penyakit : {
                        label: data.penyakit_name ,
                        value: data.id_pemyakit ,
                        name : "id_penyakit"
                    },
                    md_penyakit : data.md_penyakit,
                    mb_penyakit : data.mb_penyakit,
                })
            })

            setFields(fieldTemp);
        }
    },[props.value])

    const handleChange = (e,index) => {
        const fieldsTemp = [
            ...fields
        ]

        if(e.name === 'id_penyakit'){
            fieldsTemp[index] = {
                ...fieldsTemp[index],
                [e.name] : e
            }    
        }else{
            fieldsTemp[index] = {
                ...fieldsTemp[index],
                [e.name] : e.value,
            }    
        }

        props.onChange({
            name : props.id,
            value : fieldsTemp
        })

        setFields(fieldsTemp)
    }

    const addField = () => {
       const fieldsTemp = [
           ...fields
        ]

        fieldsTemp.push({
            id_penyakit : {},
            md_penyakit : 0,
            mb_penyakit : 1,
        })
        props.onChange({
            name : props.id,
            value : fieldsTemp
        })

        setFields(fieldsTemp)
    }

    const removeField = index => {
        const fieldsTemp = [
            ...fields
        ]
        fieldsTemp.splice(index, 1);
        props.onChange({
            name : props.id,
            value : fieldsTemp
        })

        setFields(fieldsTemp)
    }

    return (
        <>
            <h5 className="mt-5">{props.title}</h5>     
            <span className="sub-title-desc">{props.desc} </span> 
            <div className="mt-2 mb-3">
                {
                    fields.map((data,index) => (
                        <div className="row" key={index}>
                            <div className="col-lg-6 col-12">
                                <SelectAsync2 
                                    value={data.id_penyakit}                                  
                                    onChange={e => handleChange(e,index)} 
                                    labelName="name"
                                    valueName="_id"
                                    id="id_penyakit"
                                    paramsId={props.paramsId}
                                    url={props.url}
                                    label={props.label}
                                    placeHolder={props.placeholder} />   
                            </div>
                            <div className="col-lg-2 col-4">
                                <Input
                                    onChange={e => handleChange(e,index)} 
                                    value={data.md_penyakit}                                  
                                    type="number"
                                    id="md_penyakit"
                                    placeholder="Nilai MD" 
                                    label="Nilai MD" />   
                            </div>
                            <div className="col-lg-2 col-4">
                                <Input
                                    onChange={e => handleChange(e,index)} 
                                    value={data.mb_penyakit}  
                                    type="number"                                
                                    id="mb_penyakit"
                                    label="Nilai MB" />   
                            </div>    
                            {
                                fields.length > 1 ?
                                <div className="col-lg-2 col-4">
                                    <img 
                                        onClick={() => removeField(index)}
                                        src={IconRemoveDanger} 
                                        alt="Icon Remove Danger"
                                        className="mt-5 cursor-pointer" />
                                </div>  : null
                            }
                        </div>
                    ))
                }                
            </div>
            <Button 
                isSmall
                onClick={() => addField()}
                type="button"
                className={`btn-primary-light col-lg-4 col-12`} 
                title="Tambah +" />            
        </>
    )
}

InputMdMb.propTypes = {
    id : PropTypes.string,
    title : PropTypes.string,
    desc : PropTypes.string,
    url : PropTypes.string,
    paramsId : PropTypes.string,
    label : PropTypes.string,
    placeholder : PropTypes.string,
    value : PropTypes.any,
    isReadOnly : PropTypes.bool,
    className : PropTypes.string,
    onChange : PropTypes.func,
    required : PropTypes.bool,
};


export default InputMdMb