import { Button, CardContainer, Input, RichText, SelectMultipleCheckbox } from '../../../components'
import { PENYAKITCATEGORY } from '../../../routes/routesNames'
import UseFormCategories from '../hookForm'

const EditGulmaCategory = () => {
    const {
        isReady,
        isLoadingPutDataForm,
        form,
        isEnable,
        navigate,
        content,
        handleChange,
        handleChangeRich,
        handleSubmit        
    } = UseFormCategories({
        type : 'put',
        redirect : PENYAKITCATEGORY,
        categoryType : 'opt'
    })
  return (
      <CardContainer>
        <form onSubmit={e => isEnable ? handleSubmit(e) : e.preventDefault()}>
            <div className="col-lg-10 col-12">
                <div className="col-lg-8 col-12">
                    <Input
                        value={form.image_url}
                        type="file"
                        id="image"
                        onChange={handleChange}
                        placeholder="Format foto/gambar .JPG, .JPEG, .PNG, maksimal 2MB, dengan aspect ratio 1 : 1." 
                        label="Unggah Gambar" />                    
                    <Input
                        value={form.name}
                        id="name"
                        onChange={handleChange}
                        placeholder="Masukan nama kategori" 
                        label="Nama Kategori" />
                   <Input
                        value={form.nama_latin}
                        id="nama_latin"
                        onChange={handleChange}
                        placeholder="Masukan nama latin" 
                        label="Nama Latin" />                           
                    <SelectMultipleCheckbox 
                        value={form.type_of_plant}
                        id="type_of_plant"
                        placeHolder="Pilih jenis tanaman"
                        onChange={handleChange}
                        url="/v1/jenis_tanaman"
                        label="Jenis Tanaman" 
                        typeLabel="label-info" />                 
                    </div>
                {
                    isReady ?
                    <RichText value={content} onChange={handleChangeRich} label="Deskripsi" id="desc" /> : null
                }
                <div className="d-flex justify-content-end">
                    <div className="me-4">
                        <Button 
                            onClick={() => navigate(-1)}
                            type="button"
                            className={`btn-light w-100 mt-4 px-5`} 
                            title="Batalkan" />
                    </div>
                    <Button 
                        disabled={!isEnable}
                        type="submit"
                        className={`${isEnable  ? 'btn-primary' : 'btn-secondary'}  w-100 mt-4 px-5`} 
                        title="Ubah" 
                        isLoading={isLoadingPutDataForm} />
                </div>
            </div>
        </form>
      </CardContainer>
  )
}

export default EditGulmaCategory