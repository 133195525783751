import { Link } from "react-router-dom";
import { IconPencil } from "../../../assets/icons";
import { EDITDAFTARONLINESHOP } from "../../../routes/routesNames";
import { ButtonAction } from "../../../components";

const columns = (detail) => [
    {
        name: "Nama Store",
        selector: (row) => row.nama,
        sortable: true,
        minWidth: "200px",
    },
    {
        name: "Kategori",
        selector: (row) => row.kategori.nama,
        sortable: true,
        minWidth: "200px",
    },
    {
        name: "Provinsi",
        selector: (row) => row.provinsi.nama,
        sortable: true,
        minWidth: "200px",
    },
    {
        name: "Kabupaten/Kota",
        selector: (row) => row.kota.nama,
        sortable: true,
        minWidth: "200px",
    },
    {
        name: "Kecamatan",
        selector: (row) => row.kecamatan.nama,
        sortable: true,
        minWidth: "200px",
    },
    {
        name: "Aksi",
        selector: (row) => row.nama,
        sortable: true,
        maxWidth: "200px",
        format: (row) => {
            console.log(row);
            return (
                <Link to={EDITDAFTARONLINESHOP} state={row}>
                    <ButtonAction title="Ubah" icon={IconPencil} />
                </Link>
            );

        },
    },
]

export default columns