import { Button, CardContainer, Input, InputSelect, InputToggle, SelectAsync2 } from '../../components'
import UseFormChannel from './hookForm';

const AddChannelPage = () => {
    const {
        isLoadingPostDataForm,
        navigate,
        form,
        handleChange,
        handleSubmit
    } = UseFormChannel({
        url: "/v1/channel",
        type: 'post',
    })

    return (
        <CardContainer>
            <form onSubmit={e => handleSubmit(e)}>
                <div className="col-lg-8 col-12">
                    <Input
                        required={false}
                        type="file"
                        id="thumbnail"
                        onChange={handleChange}
                        placeholder="Format foto/gambar .JPG, .JPEG, .PNG, maksimal 2MB, dengan aspect ratio 1 : 1."
                        label="Foto Thumbnail" />
                    <Input
                        id="chanel_name"
                        onChange={handleChange}
                        placeholder="Nama Retailer"
                        label="Nama Retailer" />
                    <InputSelect
                        label="Jenis Retailer"
                        id="type"
                        onChange={handleChange}
                        data={[
                            {
                                id: 'R1',
                                name: 'Retailer 1'
                            },
                            {
                                id: 'R2',
                                name: 'Retailer 2'
                            },
                        ]}
                    />
                    <InputToggle
                        id="is_star_retailer"
                        onChange={handleChange}
                        checked={form.is_star_retailer}
                        label="Retailer Berbintang"
                        placeholder="Beri badge Bintang pada retailer" />
                    <SelectAsync2
                        onChange={handleChange}
                        labelName="name"
                        valueName="_id"
                        id="province"
                        url="/v1/province"
                        label="Provinsi"
                        placeHolder="Cari Provinsi..." />
                    {
                        form.province ?
                            <SelectAsync2
                                onChange={handleChange}
                                labelName="name"
                                valueName="_id"
                                id="city"
                                url="/v1/city"
                                paramsId={`?province_id=${form.province}`}
                                label="Kabupaten/Kota"
                                placeHolder="Cari Kabupaten/Kota..." /> : null
                    }
                    {
                        form.city ?
                            <SelectAsync2
                                onChange={handleChange}
                                labelName="name"
                                valueName="_id"
                                id="district"
                                url="/v1/district"
                                paramsId={`?city_id=${form.city}`}
                                label="Kecamatan"
                                placeHolder="Cari Kecamatan..." /> : null
                    }
                    {
                        form.district ?
                            <SelectAsync2
                                onChange={handleChange}
                                labelName="name"
                                valueName="_id"
                                id="village"
                                paramsId={`?district_id=${form.district}`}
                                url="/v1/village"
                                label="Desa"
                                placeHolder="Cari Desa..." /> : null
                    }
                    <Input
                        type="textarea"
                        id="alamat"
                        onChange={handleChange}
                        placeholder="Contoh: Plaza Aminta, Suite 802, 8th Floor, Jl. Let. Jend. TB Simatupang Kav. 10, Jakarta Selatan"
                        label="Alamat" />
                    <p className="mt-2">Format penulisan alamat sesuai EYD: Nama gedung (bila ada), Nama jalan, Kelurahan/Desa, Kecamatan, Kota/Kabupaten, Provinsi, Kode Pos</p>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Input
                                id="lat"
                                // type="number"
                                onChange={handleChange}
                                placeholder="Lattitude"
                                label="Lattitude" />
                        </div>
                        <div className="col-12 col-md-6">
                            <Input
                                id="long"
                                // type="number"
                                onChange={handleChange}
                                placeholder="Longitude"
                                label="Longitude" />
                        </div>
                    </div>
                    <InputToggle
                        onChange={handleChange}
                        id="isActive"
                        checked={form.isActive}
                        label="Status"
                        placeholder="Apakah retailer ini masih active " />
                    <div className="d-flex justify-content-end">
                        <div className="me-4">
                            <Button
                                onClick={() => navigate(-1)}
                                type="button"
                                className={`btn-light w-100 mt-4 px-5`}
                                title="Batalkan" />
                        </div>
                        <Button
                            type="submit"
                            className={`btn-primary w-100 mt-4 px-5`}
                            title="Simpan"
                            isLoading={isLoadingPostDataForm} />
                    </div>
                </div>
            </form>
        </CardContainer>
    )
}

export default AddChannelPage;