import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { UseForm } from "../../hooks";
import { UsePostDataForm, UsePutDataForm } from "../../hooks/api";
import { PRODUCT } from "../../routes/routesNames";
import { useState } from "react";

const UseFormProduct = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { isLoading: isLoadingPostDataForm, handlePostDataForm } =
    UsePostDataForm();
  const { isLoading: isLoadingPutDataForm, handlePutDataForm } =
    UsePutDataForm();
  const { form, setForm, setContent, handleChange, content, handleChangeRich } =
    UseForm();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setForm({
      ...form,
      status: "publish",
      is_unggulan: true,
    });
    if (props.type === "put") {
      if (location.state) {
        let categori_lainnya = [];
        let categori_hama = [];
        let categori_gulma = [];
        let categori_penyakit = [];

        location.state.all_categori_hama.map((data) => {
          categori_hama.push(data.name);
        });
        location.state.all_categori_gulma.map((data) => {
          categori_gulma.push(data.name);
        });
        location.state.all_categori_penyakit.map((data) => {
          categori_penyakit.push(data.name);
        });

        setForm({
          ...location.state,
          categori_lainnya: categori_lainnya,
          categori_hama: categori_hama,
          categori_gulma: categori_gulma,
          categori_penyakit: categori_penyakit,
        });
        setIsReady(true);
        setContent(location.state.desc);
      } else {
        navigate(PRODUCT);
      }
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (props.type === "post") {
      if (
        form.image_product.type === "image/jpeg" ||
        form.image_product.type === "image/png"
      ) {
        let params = {
          url: "/v1/product",
          form: {
            ...form,
            desc: content,
          },
          navigation: PRODUCT,
        };
        if (form.jenis_tanaman.length < 1) {
          swal("Gagal", "Jenis Tanaman harus diisi", "error");
        } else {
          handlePostDataForm(params);
        }
      } else {
        swal("Gagal", "File Harus Gambar", "error");
      }
    } else {
      if (typeof form.image_product !== "string") {
        if (
          form.image_product.type === "image/jpeg" ||
          form.image_product.type === "image/png"
        ) {
          let params = {
            url: "/v1/product?_id=" + form._id,
            form: {
              ...form,
              desc: content,
            },
            navigation: PRODUCT,
          };
          if (form.jenis_tanaman.length < 1) {
            swal("Gagal", "Jenis Tanaman harus diisi", "error");
          } else {
            handlePutDataForm(params);
          }
        } else {
          swal("Gagal", "File Harus Gambar", "error");
        }
      } else {
        let params = {
          url: "/v1/product?_id=" + form._id,
          form: {
            ...form,
            desc: content,
          },
          navigation: PRODUCT,
        };
        delete params.form.image_product;
        if (form.jenis_tanaman.length < 1) {
          swal("Gagal", "Jenis Tanaman harus diisi", "error");
        } else {
          handlePutDataForm(params);
        }
      }
    }
  };

  const isEnable = content;

  return {
    isLoadingPostDataForm,
    isLoadingPutDataForm,
    form,
    isEnable,
    handleChange,
    handleChangeRich,
    handleSubmit,
    navigate,
    content,
    isReady,
  };
};

export default UseFormProduct;
