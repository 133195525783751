import LoginPage from "./auth/login";
import ForgotPage from "./auth/forgot";
import ForgotSuccessPage from "./auth/forgotSuccess";
import ForgotPasswordPage from "./auth/forgotPassword";
import DashboardPage from "./dashboard";
import AdminPage from "./account/admin";
import AddAdminPage from "./account/admin/add";
import EditAdminPage from "./account/admin/edit";
import ExpertPage from "./account/expert";
import UserPage from "./account/customer";
import AddExpertPage from "./account/expert/add";
import EditExpertPage from "./account/expert/edit";
import SuperAdminPage from "./account/superAdmin";
import AddSuperAdminPage from "./account/superAdmin/add";
import EditSuperAdminPage from "./account/superAdmin/edit";
import JabatanPage from "./account/jabatan";
import AddJabatanPage from "./account/jabatan/add";
import EditJabatanPage from "./account/jabatan/edit";
import NotFoundPage from "./notFound";
import ProductCategory from "./categories/productCategory";
import AddProductCategory from "./categories/productCategory/add";
import EditProductCategory from "./categories/productCategory/edit";
import TanamanCategory from "./categories/tanamanCategory";
import AddTanamanCategory from "./categories/tanamanCategory/add";
import EditTanamanCategory from "./categories/tanamanCategory/edit";
import GulmaCategory from "./categories/gulmaCategory";
import AddGulmaCategory from "./categories/gulmaCategory/add";
import EditGulmaCategory from "./categories/gulmaCategory/edit";
import HamaCategory from "./categories/hamaCategory";
import AddHamaCategory from "./categories/hamaCategory/add";
import EditHamaCategory from "./categories/hamaCategory/edit";
import PenyakitCategory from "./categories/penyakitCategory";
import AddPenyakitCategory from "./categories/penyakitCategory/add";
import EditPenyakitCategory from "./categories/penyakitCategory/edit";
import ArticlePage from "./edukasi/article";
import AddArticlePage from "./edukasi/article/add";
import EditArticlePage from "./edukasi/article/edit";
import VideoPage from "./edukasi/video";
import AddVideoPage from "./edukasi/video/add";
import EditVideoPage from "./edukasi/video/edit";
import RegionalPage from "./domisili/regional";
import AddRegionalPage from "./domisili/regional/add";
import EditRegionalPage from "./domisili/regional/edit";
import TeritorialPage from "./domisili/teritorial";
import AddTeritorialPage from "./domisili/teritorial/add";
import EditTeritorialPage from "./domisili/teritorial/edit";
import PromotorPage from "./domisili/promotor";
import AddPromotorPage from "./domisili/promotor/add";
import EditPromotorPage from "./domisili/promotor/edit";
import ProductPage from "./product";
import AddProductPage from "./product/add";
import EditProductPage from "./product/edit";
import ChannelPage from "./channel";
import AddChannelPage from "./channel/add";
import EditChannelPage from "./channel/edit";
import PartPlanPage from "./part_plant";
import AddPartPlanPage from "./part_plant/add";
import EditPartPlanPage from "./part_plant/edit";
import SymptomPage from "./symptom";
import AddSymptomPage from "./symptom/add";
import EditSymptomPage from "./symptom/edit";
import QuestionPage from "./question";
import AddQuestionPage from "./question/add";
import EditQuestionPage from "./question/edit";
import ConsultationPage from "./consultation";
import DetailConsultationPage from "./consultation/detail";
import KategoriMarketplace from "./office_online_shop/kategori_marketplace";
import AddKategoriMarketplace from "./office_online_shop/kategori_marketplace/add";
import EditKategoriMarketplace from "./office_online_shop/kategori_marketplace/edit";
import DaftarOnlineshop from "./office_online_shop/daftar_online_shop";
import AddDaftarOnlineshop from "./office_online_shop/daftar_online_shop/add";
import EditDaftarOnlineshop from "./office_online_shop/daftar_online_shop/edit";

export {
  QuestionPage,
  AddQuestionPage,
  EditQuestionPage,
  SymptomPage,
  AddSymptomPage,
  EditSymptomPage,
  PartPlanPage,
  AddPartPlanPage,
  EditPartPlanPage,
  TanamanCategory,
  AddTanamanCategory,
  EditTanamanCategory,
  ChannelPage,
  AddChannelPage,
  EditChannelPage,
  HamaCategory,
  AddHamaCategory,
  EditHamaCategory,
  GulmaCategory,
  AddGulmaCategory,
  EditGulmaCategory,
  EditProductCategory,
  ProductCategory,
  AddProductCategory,
  LoginPage,
  ForgotPage,
  ForgotPasswordPage,
  DashboardPage,
  AdminPage,
  AddAdminPage,
  EditAdminPage,
  JabatanPage,
  AddJabatanPage,
  EditJabatanPage,
  ExpertPage,
  UserPage,
  AddExpertPage,
  EditExpertPage,
  NotFoundPage,
  PenyakitCategory,
  AddPenyakitCategory,
  EditPenyakitCategory,
  ForgotSuccessPage,
  ArticlePage,
  AddArticlePage,
  EditArticlePage,
  VideoPage,
  AddVideoPage,
  EditVideoPage,
  RegionalPage,
  AddRegionalPage,
  EditRegionalPage,
  TeritorialPage,
  AddTeritorialPage,
  EditTeritorialPage,
  PromotorPage,
  AddPromotorPage,
  EditPromotorPage,
  ProductPage,
  AddProductPage,
  EditProductPage,
  SuperAdminPage,
  AddSuperAdminPage,
  EditSuperAdminPage,
  ConsultationPage,
  DetailConsultationPage,
  KategoriMarketplace,
  AddKategoriMarketplace,
  EditKategoriMarketplace,
  DaftarOnlineshop,
  AddDaftarOnlineshop,
  EditDaftarOnlineshop,
};
