import { useLocation, useNavigate } from "react-router-dom";
import { UseForm } from "../../../hooks";
import { UsePost, UsePut } from "../../../hooks/api";
import { useEffect, useState } from "react";
import { KATEGORIMARKETPLACE } from "../../../routes/routesNames";

const UseFormKategoriMarketplace = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isLoading: isLoadingPost, handlePost } = UsePost();
    const { isLoading: isLoadingPut, handlePut } = UsePut();
    const [isReady, setIsReady] = useState(false);
    const { form, setForm, handleChange, } = UseForm();

    useEffect(() => {
        if (props.type === 'put') {
            if (location.state) {
                setForm({
                    ...location.state,
                });
            } else {
                navigate(KATEGORIMARKETPLACE)
            }
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (props.type === "post") {
            let params = {
                url: props.url,
                form: {
                    ...form,
                },
                navigation: KATEGORIMARKETPLACE
            }

            handlePost(params)
        } else {
            let params = {
                url: props.url + '?_id=' + form._id,
                form: {
                    ...form,
                },
                navigation: KATEGORIMARKETPLACE
            }
            handlePut(params)
        }
    }

    return {
        handleSubmit,
        form,
        navigate,
        handleChange,
        isLoadingPut,
        isReady,
        isLoadingPost,
    };
}
export default UseFormKategoriMarketplace