import React from 'react';
import { Link } from 'react-router-dom';
import { Button, CardContainer, Input } from '../../components'
import Table from '../../components/organisms/table'
import { UseTable } from '../../hooks';
import { UseGet } from '../../hooks/api';
import {  ADDPRODUCT } from '../../routes/routesNames';
import columns from './column'


const Product = () => {    
    const {
        filter,
        isLoading,
        data,
        error,
        totalPages,
        handlePagination,
        handleLimit,
        handleFilter,
    } = UseGet('/v1/product' )

    const {
        isLoading : isLoadingPost ,
        dataRemove,
        handleSelected,
        handleRemove
    } = UseTable();
    
  return (
    <CardContainer >
        <div className="d-flex flex-row justify-content-between">
            <Input type="search" id="search" placeholder="Cari Produk" onChange={handleFilter} />
            <div className="d-flex flex-row">
            {
                dataRemove.length === 0  ? 
                <Link to={ADDPRODUCT}>
                    <Button title="Tambah Produk" className="btn btn-primary" isSmall />                
                </Link> :
                <Button 
                    isLoading={isLoadingPost} 
                    onClick={() =>  handleRemove('/v1/product/delete')}  
                    title="Hapus Produk" 
                    className="btn btn-danger" isSmall />                
            }
            </div>
        </div>
        <Table 
            filter={filter}
            isLoading={isLoading}
            error={error}
            data={data}
            totalPages={totalPages}
            handlePagination={handlePagination}
            handleLimit={handleLimit}
            handleChangeSelect={handleSelected} 
            columns={columns}  />  
    </CardContainer>
  )
}

export default Product