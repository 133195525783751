import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { axios, errorResponse } from "../../helpers";
import { loginData, logout } from "../../stores/auth";

const useGetByParamsId = (url, paramsId, limit) => {
  const dispatch = useDispatch();
  const dataLogin = useSelector(loginData);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingParams, setIsLoadingParams] = useState(true);
  const [data, setData] = useState({});
  const [totalPages, setTotalPages] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState({
    page: 1,
    limit: limit ? limit : 10,
    isUpdate: false,
    wilayah_kerja: "",
    search: "",
  });

  useEffect(() => {
    handleFetchData("init");
  }, [filter]);

  useEffect(() => {
    handleFetchData("paramsId");
  }, [url, paramsId]);

  // handle
  const handlePagination = (e) => {
    setFilter({
      ...filter,
      page: e.selected + 1,
    });
  };
  const handleLimit = (e) => {
    setFilter({
      search: "",
      page: 1,
      limit: e.target.value,
    });
  };
  const handleFilter = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setFilter({
        ...filter,
        [name]: value,
      });
    } else {
      setFilter({
        ...filter,
        [e.name]: e.value.name,
      });
    }
  };

  const handleFetchData = async (type) => {
    if (type === "init") {
      setIsLoading(true);
    } else {
      setIsLoadingParams(true);
    }
    let urlParamsFilter = "";
    let operator = "?";
    Object.keys(filter).map(function (key, index) {
      if (filter[key] && key !== "token") {
        urlParamsFilter += `${index === 0 ? "" : "&"}${[key]}=${filter[key]}`;
      }
    });

    if (paramsId) {
      operator = "&";
    }

    try {
      const response = await axios.get(
        `${url}${paramsId}${
          urlParamsFilter !== "" ? operator : ""
        }${urlParamsFilter}`,
        {
          headers: { Authorization: `Bearer ${dataLogin.data.token}` },
        }
      );

      if (response.data.status) {
        setData(response.data.data);
        setTotalData(response.data.total_data);
        setTotalPages(response.data.total_page);
        setError("");
      } else {
        setData([]);
        setTotalData(0);
        setTotalPages(0);
        setError(response.data.message);
      }
    } catch (err) {
      if (errorResponse(err) === "jwt expired") {
        dispatch(logout());
      } else {
        return errorResponse(err);
      }
    } finally {
      if (type === "init") {
        setIsLoading(false);
      } else {
        setIsLoadingParams(false);
      }
    }
  };

  return {
    filter,
    isLoading,
    data,
    error,
    totalPages,
    totalData,
    handleFetchData,
    handlePagination,
    handleLimit,
    isLoadingParams,
    handleFilter,
  };
};

export default useGetByParamsId;
