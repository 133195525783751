import { Dropdown } from "../../../components";
import { EDITVIDEO } from "../../../routes/routesNames";

const columns = (handleEdit, handleDuplicate) => {
  return [
    {
      name: "Artikel",
      selector: (row) => row.title,
      sortable: true,
      width: "200px",
    },
    {
      name: "OPT",
      selector: (row) => row.desc,
      sortable: true,
      width: "200px",
      format: (row, index) => {
        return (
          <div className="pb-1 w-100">
            {row.jenis_opt &&
              row.jenis_opt.map((data, index) => (
                <span key={index} className="label-info">
                  {data}{" "}
                </span>
              ))}
          </div>
        );
      },
    },
    {
      name: "Tanaman",
      selector: (row) => row.desc,
      sortable: true,
      width: "200px",
      format: (row, index) => {
        return (
          <div className="pb-1 w-100">
            {row.jenis_tanaman &&
              row.jenis_tanaman.map((data, index) => (
                <span key={index} className="label-info">
                  {data}
                </span>
              ))}
          </div>
        );
      },
    },
    // {
    //     name: 'Penulis',
    //     selector: row => row.name,
    //     sortable: true,
    //     maxWidth : "296px",
    // },
    {
      name: "Tanggal",
      selector: (row) => row.tanggal_publish,
      sortable: true,
      width: "200px",
    },
    {
      name: "Dilihat",
      selector: (row) => row.total_viewer,
      sortable: true,
      width: "200px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "200px",
      format: (row, index) => {
        return (
          <div className="d-flex flex-row">
            <div
              className={
                row.status === "arsip" ? `label-warning` : "label-success"
              }
            >
              {row.status}
            </div>
            {row.is_trending ? (
              <div className="label-danger">Trending</div>
            ) : null}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      selector: (row) => row.status,
      sortable: true,
      maxWidth: "150px",
      format: (row, index) => {
        if (row.status) {
          return (
            <Dropdown
              detail={row}
              data={[
                {
                  title: "Lihat",
                  path: "",
                  type: "modal",
                },
                {
                  title: `Jadikan ${
                    row.status === "arsip" ? "Publish" : "Arsip"
                  }`,
                  path: "",
                  type: "onclick",
                  function: handleEdit,
                },
                {
                  title: "Ubah",
                  path: EDITVIDEO,
                  type: "path",
                },
                {
                  title: "Duplikat",
                  path: "",
                  type: "onclick",
                  function: handleDuplicate,
                },
              ]}
            />
          );
        } else {
          return <div></div>;
        }
      },
    },
  ];
};

export default columns;
