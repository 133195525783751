import React from 'react';
import { Button, CardContainer, ImportModal, Input, InputSelectAsync, Select } from '../../../components'
import columns from './column'
import { Link } from 'react-router-dom';
import { ADDADMIN } from '../../../routes/routesNames';
import { UseGet, } from '../../../hooks/api';
import { UseTable } from '../../../hooks';
import Table from '../../../components/organisms/table';

const Admin = () => {
    const {
        filter,
        isLoading,
        data,
        error,
        totalPages,
        handlePagination,
        handleLimit,
        handleFilter,
    } = UseGet('/v1/account/admin')

    const {
        isLoading: isLoadingPost,
        dataRemove,
        handleSelected,
        handleRemove
    } = UseTable();

    return (
        <CardContainer >
            <div className="d-flex flex-row justify-content-between">
                <Input type="search" id="search" placeholder="Cari Admin" onChange={handleFilter} />
                {
                    dataRemove.length === 0 ?
                        <div className="d-flex flex-row">
                            <div className="me-2">
                                <InputSelectAsync
                                    isAll={true}
                                    value={filter.wilayah_kerja ? filter.wilayah_kerja : 'Semua Wilayah'}
                                    handleSelected={data => handleFilter({
                                        name: 'wilayah_kerja',
                                        value: data.name
                                    })}
                                    className="select-filter-table"
                                    placeHolder="Wilayah Kerja"
                                    url='/v1/domain'
                                    labelName="name"
                                />
                            </div>
                            <div className="me-2">
                                <Select
                                    id="status"
                                    onChange={handleFilter}
                                    placeHolder="Semua Status"
                                    data={[
                                        {
                                            id: 'aktif',
                                            name: 'Aktif'
                                        },
                                        {
                                            id: 'in aktif',
                                            name: 'In Aktif'
                                        },
                                    ]}
                                />
                            </div>
                            {/* <ImportModal 
                            urlImport="/v1/account/admin/import_excel"
                            urlDownload="http://34.101.70.83:5000/web/admin/v1/account/admin/download_template" /> */}
                            {/* <Button icon={IconImport} title="Import" className="btn btn-primary-light me-3 px-4" isSmall />                 */}
                            <Link to={ADDADMIN}>
                                <Button title="Tambah Admin" className="btn btn-primary" isSmall />
                            </Link>
                        </div>
                        :
                        <Button
                            isLoading={isLoadingPost}
                            onClick={() => handleRemove('/v1/account/admin/delete')}
                            title="Hapus Admin"
                            className="btn btn-danger" isSmall />
                }
            </div>
            {
                // console.log(data, 'data')
            }
            <Table
                totalPages={totalPages}
                filter={filter}
                isLoading={isLoading}
                error={error}
                data={data}
                handlePagination={handlePagination}
                handleLimit={handleLimit}
                handleChangeSelect={handleSelected}
                columns={columns} />
        </CardContainer>
    )
}

export default Admin