import React, { useEffect, useState } from 'react'
import { UseGet } from '../../hooks/api';
import { CardContainer, Button, RadioButton, Select } from '../../components'
import { useLocation } from "react-router-dom";
import { LogoImageDefault } from '../../assets/images';
import './detail.scss';
import { Modal } from "react-bootstrap";
import UseFormDetail from './hookForm';

function tanggal(params) {
  var now = new Date(params)
  var d = now.getDay()
  var M = "";
  var Y = now.getFullYear()
  switch (now.getMonth() + 1) {
    case 1:
      M = "Januari"
      break;
    case 2:
      M = "Februari"
      break;
    case 3:
      M = "Maret"
      break;
    case 4:
      M = "April"
      break;
    case 5:
      M = "Mei"
      break;
    case 6:
      M = "Juni"
      break;
    case 7:
      M = "Juli"
      break;
    case 8:
      M = "Agustus"
      break;
    case 9:
      M = "September"
      break;
    case 10:
      M = "Oktober"
      break;
    case 11:
      M = "November"
      break;
    case 12:
      M = "Desember"
      break;
    default:
      break;
  }
  return d + " " + M + " " + Y;
}


const DetailConsultation = () => {
  const location = useLocation();
  const { isLoading, data } = UseGet(`/v1/consultation/detail?_id=${location.state._id}`)

  const { form, handleChange, dataExTm, dataExFp, handleSubmit, isEnable, handleFilter, isShowModal, setIsShowModal } = UseFormDetail({
    url: "/v1/consultation/assign_expert",
    id: location.state._id,
  })


  // console.log(location.state);
  // const { dataT, setDataT } = useState([{ id: "", name: "" }])
  return (
    isLoading ? ("") :
      (
        <CardContainer>
          <div className="d-flex flex-row justify-content-between">
            <div className="">
              <p><b>Informasi Konsultasi</b></p>
              <p>{tanggal(data.createdAt)}</p>
              <br />
              <p>Nama {location.state.expert_territory}</p>
              <p>
                <div className="d-flex flex-row-reverse" >
                  {data.expert_data.map((v, i) =>
                    <div className='m-2'>
                      {(data.expert_data.length > 1 && data.expert_data.length != i + 1) ? <i className="fas fa-arrow-right"> {'==>'}&nbsp;</i> : <div></div>}
                      <img
                        className="mb-2 mr-2"
                        style={{ borderRadius: "8px" }}
                        width={32}
                        height={32}
                        src={v.image ? v.image : LogoImageDefault} /> &nbsp;
                      {v.name}
                    </div>
                  )}
                </div>
              </p>
              <br />
              <p>Nama User</p>
              <p>
                <div className="">
                  <img
                    // crossorigin="anonymous"
                    className="mb-2 mr-12"
                    // height={120}
                    width={32}
                    src={data.user_data.image ? data.user_data.image : LogoImageDefault} />
                  {data.user_data.name}
                </div>
              </p>
            </div>
            <div className="">
              <span className={data.status === "Selesai" ? "label-success" : data.status === "Sedang berlangsung" ? "label-warning" : "label-danger"}>{data.status}</span>
            </div>
          </div>
          <br />
          <div className="top-menu">
            <div className="d-flex flex-row justify-content-between mb-16 ">
              <div className="column">
                <p><b>Percakapan Konsultasi</b></p>
                <p></p>
              </div>
              {
                data.status !== "Selesai" ? <Button
                  isLoading={isLoading}
                  onClick={() => setIsShowModal(true)}
                  title="Alihkan"
                  className="btn btn-warning" isSmall /> : <div></div>
              }

            </div>
          </div>

          <div className="menu">

            {
              data.data_consultation.map((v) =>

                v.type === "text" ? (
                  v.is_sender ?
                    <div><br /><div className="float-left">{v.content}</div><br /><div className="float-left-text">{v.send_date}</div> <br /></div> :
                    <div><br /><div className="float-right">{v.content}</div><br /><div className="float-right-text">{v.send_date}</div><br /></div>
                ) :
                  (
                    <div className={v.is_sender ? "float-left-img" : "float-right-img"}>
                      <img className={v.is_sender ? "img-radius-left" : "img-radius-right"} src={v.content} />
                      <br />
                      {v.send_date}
                    </div>
                  )
              )
            }
          </div>
          {
            data.status === "Selesai" ? <div className="label-success">Konsultasi {data.status}</div> : <div></div>
          }

          <Modal
            show={isShowModal}
            onHide={() => setIsShowModal(false)}
            backdrop="static"
            size="lg"
            keyboard={false} >
            <Modal.Header closeButton>
              <Modal.Title >
                <div className='d-flex flex-row justify-content-between' >
                  <p>Alihkan Konsultasi</p>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className="row" onSubmit={e => isEnable != "" ? handleSubmit(e) : e.preventDefault()}>
                <RadioButton
                  data={[
                    {
                      name: "Territory Manager",
                      value: "Territory Manager"
                    },
                    {
                      name: "Field Promotor",
                      value: "Field Promotor"
                    }
                  ]}
                  value={form.type}
                  id="type"
                  label="Kepada"
                  onChange={handleChange}
                />
                {
                  form.type === "Territory Manager" ? <Select id="expert_id" placeHolder={"Pilih Territory Manager"} value={form.expert_id} onChange={handleFilter} data={dataExTm} /> : <div></div>
                }
                {

                  form.type === "Field Promotor" ? <Select id="expert_id" placeHolder={"Pilih Field Promotor"} value={form.expert_id} onChange={handleFilter} data={dataExFp} /> : <div></div>
                }
                <div className='d-flex flex-row justify-content-center mt-3'>
                  <Button title="Batalkan" type="button" onClick={() => setIsShowModal(false)} />
                  <Button title="Alihkan" type="submit" className="btn btn-success" />
                </div>
              </form>
            </Modal.Body>
            {/* <Modal.Footer>
            </Modal.Footer> */}

          </Modal>

        </CardContainer >
      )
  )
}

export default DetailConsultation