import {
  IconCatalog,
  IconCatalogActive,
  IconChannel,
  IconChannelActive,
  IconConsultation,
  IconConsultationActive,
  IconEducation,
  IconEducationActive,
  IconHome,
  IconHomeActive,
  IconMap,
  IconMapActive,
  IconPakar,
  IconPakarActive,
  IconUsers,
  IconUsersActive,
  IconQuestion,
  IconQuestionActive,
  IconStore,
  IconStoreActive,
} from "../../../assets/icons";
import {
  ADMIN,
  ARTICLE,
  CHANNEL,
  CONSULTATION,
  CUSTOMER,
  DASHBOARD,
  EXPERT,
  GULMACATEGORY,
  HAMACATEGORY,
  JABATAN,
  OPTCATEGORY,
  PARTPLANT,
  PENYAKITCATEGORY,
  PRODUCT,
  PRODUCTCATEGORY,
  PROMOTOR,
  QUESTION,
  REGIONAL,
  SUPERADMIN,
  SYMPTOM,
  TANAMANCATEGORY,
  TERITORIAL,
  VIDEO,
  KATEGORIMARKETPLACE,
  DAFTARONLINESHOP,
} from "../../../routes/routesNames";

const dataLinks = [
  {
    title: "Dashboard",
    path: DASHBOARD,
    icon: IconHome,
    iconActive: IconHomeActive,
    isExpand: false,
    data: [],
    isOpen: true,
  },
  {
    title: "Konsultasi",
    path: CONSULTATION,
    icon: IconConsultation,
    iconActive: IconConsultationActive,
    isExpand: false,
    data: [],
    isOpen: false,
  },
  {
    title: "Akun",
    path: "account",
    icon: IconUsers,
    iconActive: IconUsersActive,
    isExpand: true,
    data: [
      {
        title: "Admin",
        path: ADMIN,
      },
      {
        title: "Expert",
        path: EXPERT,
      },
      {
        title: "User",
        path: CUSTOMER,
      },

      {
        title: "Super Admin",
        path: SUPERADMIN,
      },
      {
        title: "Jabatan",
        path: JABATAN,
      },
    ],
    isOpen: false,
  },
  {
    title: "Katalog",
    path: "catalog",
    icon: IconCatalog,
    iconActive: IconCatalogActive,
    isExpand: true,
    data: [
      {
        title: "Kategori Produk",
        path: PRODUCTCATEGORY,
      },
      {
        title: "Kategori OPT",
        path: OPTCATEGORY,
        isExpand: true,
        isOpen: false,
        data: [
          {
            title: "Gulma",
            path: GULMACATEGORY,
          },
          {
            title: "Hama",
            path: HAMACATEGORY,
          },
          {
            title: "Penyakit",
            path: PENYAKITCATEGORY,
          },
        ],
      },
      {
        title: "Kategori Tanaman",
        path: TANAMANCATEGORY,
      },
      {
        title: "Produk",
        path: PRODUCT,
      },
    ],
    isOpen: false,
  },
  {
    title: "Wilayah Kerja",
    path: "domain",
    icon: IconMap,
    iconActive: IconMapActive,
    isExpand: true,
    data: [
      {
        title: "Regional",
        path: REGIONAL,
      },
      {
        title: "Teritori",
        path: TERITORIAL,
      },
      {
        title: "Sub Teritori",
        path: PROMOTOR,
      },
    ],
    isOpen: false,
  },
  {
    title: "Retailer",
    path: CHANNEL,
    icon: IconChannel,
    iconActive: IconChannelActive,
    isExpand: false,
    data: [],
    isOpen: true,
  },
  {
    title: "Official Online Shop",
    path: "official_onlin_shop",
    icon: IconStore,
    iconActive: IconStoreActive,
    isExpand: true,
    data: [
      {
        title: "Kategori Market Place",
        path: KATEGORIMARKETPLACE,
      },
      {
        title: "Daftar Online Shop",
        path: DAFTARONLINESHOP,
      },
    ],
    isOpen: false,
  },
  {
    title: "Edukasi",
    path: "education",
    icon: IconEducation,
    iconActive: IconEducationActive,
    isExpand: true,
    data: [
      {
        title: "Info",
        path: ARTICLE,
      },
      {
        title: "Video",
        path: VIDEO,
      },
    ],
    isOpen: false,
  },
  {
    title: "Sistem Pakar",
    path: "pakar",
    icon: IconPakar,
    iconActive: IconPakarActive,
    isExpand: true,
    data: [
      {
        title: "Bagian Tanaman",
        path: PARTPLANT,
      },
      {
        title: "Gejala",
        path: SYMPTOM,
      },
    ],
    isOpen: false,
  },
  {
    title: "Pertanyaan",
    path: QUESTION,
    icon: IconQuestion,
    iconActive: IconQuestionActive,
    isExpand: false,
    data: [],
    isOpen: true,
  },
];

export default dataLinks;
