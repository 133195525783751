import {useState} from 'react'
import swal from 'sweetalert'
import { UsePost } from './api'

const UseTable = () => {
    const {isLoading,error,handlePost} = UsePost()

    const [dataRemove,setDataRemove] = useState([])

    const handleRemove = url => {
        swal({
            text: "Apakah Anda yakin ingin menghapus data ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                let dataDelete = []
                dataRemove.map((data) => {
                    if(data.user_id){
                        dataDelete.push(data.user_id);
                    }else{
                        dataDelete.push(data._id);
                    }
                })
                let params = {
                    url : url,
                    form : {
                        id : dataDelete
                    },   
                }
                
                handlePost(params)
            }
          });
    }

    const handleSelected = ({ selectedRows }) => {
        setDataRemove(selectedRows)
    }

    return {
        isLoading,
        error,
        dataRemove,
        handleRemove,
        handleSelected,
    }
}

export default UseTable