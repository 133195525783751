import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import './selectMultiple.scss'
import Input from '../input';
import Checkbox from '../checkbox';
import Loader from '../loader';
import { IconArrowDown, IconArrowUp } from '../../../assets/icons';
import { UseModal } from '../../../hooks';
import { UseGet } from '../../../hooks/api';

const SelectMultipleCheckbox = (props) => {
    const {
        isShowModal,
        setIsShowModal,
        wrapperRef,
    } = UseModal()

    const {
        isLoading,
        data,
        error,
        handleFilter,
    } = UseGet(props.url,100 )

    const [dataSelected,setDataSelected] = useState([])
    const [isUpdate,setIsUpdate] = useState(false)

    useEffect(() => {
        if(props.value){
            setDataSelected(props.value)
        }
    },[props.value]);


    useEffect(() => {
        if(data.length){
            handleFilter({
             name : 'search',
             value : '',
            })
        }
    },[isShowModal]);

    const handleChange = e => {
        const {value,checked} = e.target

        if(checked){
            let dataTemp = dataSelected ;

            dataTemp.push(value);
            setDataSelected(dataTemp)
            setIsUpdate(!isUpdate)
            props.onChange({
                name : props.id,
                value : dataTemp
            })
        }else{
            let dataTemp = [];

            dataSelected.map((data) => {
                if(data !== value){
                    dataTemp.push(data)
                }
            })
            setDataSelected(dataTemp);
            setIsUpdate(!isUpdate)
            props.onChange({
                name : props.id,
                value : dataTemp
            })
        }
    }

    const onRemoveItem = value => {
        let dataTemp = [];

        dataSelected.map((data) => {
            if(data !== value){
                dataTemp.push(data)
            }
        })
        setDataSelected(dataTemp);
        props.onChange({
            name : props.id,
            value : dataTemp
        })

    }

  return (
    <div className="position-relative checkbox-row" ref={wrapperRef}>
        <div className={`row align-items-center form-group form-select-check-mulitple ${props.label ? 'mb-3' : ''}`} >
            <div className="col-4">
            {
                props.label ?
                <label style={{
                    color: 'black',
                    fontWeight : '400',
                }} htmlFor={props.id}>{props.label}</label> : null
            }
            </div>
            <div className="col-8 position-relative" style={{
                height: '48px',
                width: '64%'
            }}>
                <div className="selected-card">
                {
                    dataSelected.length ?
                    dataSelected.map((data,index) => (
                        <span key={index} className={`label-card cursor-pointer ${props.typeLabel}`} onClick={() => onRemoveItem(data)} >
                            <span>{data}</span>  <span className="ps-2">x</span>
                        </span>  
                    )) :
                    <span className="span-placeholder">{props.placeHolder}</span>
                }                
                </div>
                <div className="form-control form-control-multiple cursor-pointer"  onClick={() => setIsShowModal(!isShowModal)}>
                </div>
            </div>
        </div>
        <img src={isShowModal ? IconArrowUp : IconArrowDown} alt="" className="icon-arrow cursor-pointer" onClick={() => setIsShowModal(!isShowModal)} />
        {
            isShowModal ? 
            <div>
                <div className="modal-form-select">
                <Input 
                    type="search" 
                    id="search" 
                    placeholder={props.placeHolder} 
                    onChange={handleFilter} />
                <div className="container-select-item" >
                    {
                        isLoading ?
                        <Loader /> : 
                        error === ''?
                        data.map((data,index) => (
                            <label 
                                key={index} 
                                className="d-flex justify-content-between"  >
                                <span>{data.name}</span>
                                <Checkbox 
                                    checked={dataSelected.includes(data.name)}
                                    id={props.id} 
                                    name={props.id} 
                                    value={data[props.valueCheckbox]} 
                                    onChange={(e) => handleChange(e)} />
                            </label>
                        ))
                        :
                        <p className="text-center">{error}</p>
                    }
                </div>
            </div>
            </div>
             : null
        }
    </div>
  )
}

SelectMultipleCheckbox.propTypes = {
    label : PropTypes.string,
    id : PropTypes.string,
    value : PropTypes.any,
    placeHolder : PropTypes.string,
    className : PropTypes.string,
    onChange : PropTypes.func,
    typeLabel : PropTypes.string,
    url : PropTypes.string,
    valueCheckbox : PropTypes.string,
    data : PropTypes.array,
};
SelectMultipleCheckbox.defaultProps = {
    placeHolder : '',
    valueCheckbox : 'name',
}

export default SelectMultipleCheckbox