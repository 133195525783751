import { Link } from "react-router-dom";
import { IconPencil } from "../../assets/icons";
import { ButtonAction } from "../../components";
import { ADDSYMPTOM, QUESTION } from "../../routes/routesNames";

const columns = [
  {
    name: "Tanaman",
    selector: (row) => row.name,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Tanaman",
    selector: (row) => row.bagian_tanaman,
    sortable: true,
    minWidth: "300px",
    format: (row, index) => {
      let bagianTanaman = row.bagian_tanaman.filter((c, index) => {
        return row.bagian_tanaman.map((e) => e.name).indexOf(c.name) === index;
      });

      return (
        <div className="pb-1 w-100 d-flex flex-row">
          {row.bagian_tanaman &&
            bagianTanaman.map((data, index) => (
              <Link
                key={index}
                to={QUESTION}
                state={{ detail: data, jenis_tanaman: row }}
                className="me-2"
              >
                <ButtonAction title={data.name + " >"} />
              </Link>
            ))}
        </div>
      );
    },
  },
  {
    name: "Aksi",
    selector: (row) => row.name,
    sortable: true,
    maxWidth: "200px",
    format: (row, index) => {
      // return <Dropdown
      //             detail={row}
      //             data={[
      //                 {
      //                     title : 'Lihat',
      //                     path : '',
      //                     type : 'modal',

      //                 },
      //                 {
      //                     title : 'Ubah',
      //                     path : EDITADMIN,
      //                     type : 'path'
      //                 },
      //             ]} />
      return (
        <Link to={ADDSYMPTOM} state={row}>
          <ButtonAction title="Tambah" />
        </Link>
      );
    },
  },
];

export default columns;
