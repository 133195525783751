
import { useEffect, useState } from "react";
import { UseGet } from '../../hooks/api';
import { UsePut } from "../../hooks/api";
import { socket } from "../../socket";
import { useLocation } from "react-router-dom";

const UseFormDetail = (props) => {
    const { data: dataTm } = UseGet(`/v1/consultation/list_expert?type=Territory Manager`)
    const { data: dataFp } = UseGet(`/v1/consultation/list_expert?type=Field Promotor`)
    const [dataEx, setDataEx] = useState([])
    const [dataExTm, setDataExTm] = useState([])
    const [dataExFp, setDataExFp] = useState([])
    const datas = [];
    const [isShowModal, setIsShowModal] = useState(false)
    const location = useLocation();

    useEffect(() => {
        socket.emit('joinFlutter',
            parseInt(location.state.room_id, 10),
            'Admin TaniDoc'
        );
    }, [])

    const {
        isLoading: isLoadingPut,
        handlePut
    } = UsePut();
    const [form, setForm] = useState({
        type: '',
        expert_id: ''
    })

    

    const handleChange = (e) => {
        if (e.target) {
            const { name, value } = e.target;
            setForm({
                ...form,
                [name]: value
            })
            // console.log("form.expert_id", form.expert_id);
            if (value === "Territory Manager") {
                for (let i = 0; i < dataTm.length; i++) {
                    const e = dataTm[i];
                    datas.push({ id: e._id, name: e.expert_name });
                }
                setDataExTm(datas);
            } else {
                for (let i = 0; i < dataFp.length; i++) {
                    const e = dataFp[i];
                    datas.push({ id: e._id, name: e.expert_name });
                }
                setDataExFp(datas);
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setIsShowModal(false);
        let params = {
            url: props.url + "?_id=" + props.id,
            form: {
                ...form,
            },
            // navigation: DETAILCONSULTATION
        }
        socket.emit('sendMessageFlutter',
            location.state.room_id,
            {
                "_id": '1',
                "content": "",
                "send_date": '16:16',
                "type": 'switch',
                "is_local": false,
                "is_sender": false,
            },
        )
        handlePut(params)

    }

    const handleFilter = (e) => {
        if (e.target) {
            const { name, value } = e.target;
            // console.log("form.expert_id", value);
            setForm({
                ...form,
                [name]: value
            })

        }
    }

    const isEnable = form.expert_id;

    return {
        form,
        dataEx,
        dataExTm,
        dataExFp,
        isEnable,
        isShowModal,
        handleChange,
        handleSubmit,
        handleFilter,
        setIsShowModal,
    };
};
export default UseFormDetail;