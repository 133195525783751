import { Link } from "react-router-dom";
import { IconPencil } from "../../assets/icons";
import { ButtonAction } from "../../components";
import { EDITCHANNEL } from "../../routes/routesNames";

const columns = [
  {
    name: "Nama Channel",
    selector: (row) => row.chanel_name,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Jenis",
    selector: (row) => row.type,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Provinsi",
    selector: (row) => row.province.title,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Kabupaten/Kota",
    selector: (row) => row.city.title,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Kecamatan",
    selector: (row) => row.village.title,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Desa",
    selector: (row) => row.district.title,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Latitude",
    selector: (row) => row.lat,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Longitude",
    selector: (row) => row.long,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Alamat",
    selector: (row) => row.alamat,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    format: (row, index) => {
      return (
        <div className="pb-1">
          <span
            className={
              row.status === "aktif" ? `label-success` : "label-danger"
            }
          >
            {row.status}
          </span>
        </div>
      );
    },
  },
  {
    name: "Pembaruan Terakhir",
    selector: (row) => row.last_update,
    sortable: true,
    minWidth: "300px",
  },
  {
    name: "Aksi",
    selector: (row) => row.name,
    sortable: true,
    maxWidth: "200px",
    format: (row, index) => {
      // return <Dropdown
      //             detail={row}
      //             data={[
      //                 {
      //                     title : 'Lihat',
      //                     path : '',
      //                     type : 'modal',

      //                 },
      //                 {
      //                     title : 'Ubah',
      //                     path : EDITADMIN,
      //                     type : 'path'
      //                 },
      //             ]} />
      return (
        <Link to={EDITCHANNEL} state={row}>
          <ButtonAction title="Ubah" icon={IconPencil} />
        </Link>
      );
    },
  },
];

export default columns;
