import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { JABATAN } from "../../../routes/routesNames";
import { UseForm } from "../../../hooks";
import { UsePost, UsePut } from "../../../hooks/api";

const UseFormJabatan = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {
        isLoading : isLoadingPost,
        handlePost
    } = UsePost();
    const {
        isLoading : isLoadingPut,
        handlePut
    } = UsePut();
    const {form,setForm,handleChange} = UseForm();


    useEffect(() => {
        if(props.type === 'put'){
            if(location.state){
                setForm({
                    ...location.state,
                });
            }else{
                navigate(JABATAN)
            }
        }
    }, []);
    
    // handle submit
    const handleSubmit = e => {
        e.preventDefault()

        if(props.type === 'put'){
            let params = {
                url : props.url + '?_id=' + form._id,
                form : {
                    ...form,
                },
                navigation : JABATAN
            }

            handlePut(params)
        }else{
            let params = {
                url : props.url,
                form : {
                    ...form,
                },
                navigation : JABATAN
            }

            handlePost(params)
        }
        
        
    }

    return {
        handleSubmit,
        form,
        navigate,
        handleChange,
        isLoadingPost,
        isLoadingPut,
    }
}

export default UseFormJabatan