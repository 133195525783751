import { Button, CardContainer, Input, SelectAsync2, RichText } from '../../components'
import UseFormQuestion from './hookForm';

const AddQuestionPage = () => {
    const {
        isLoadingPost,
        navigate,
        form,
        handleChange,
        handleChangeRich,
        handleSubmit
    } = UseFormQuestion({
        url: "/v1/pertanyaan",
        type: 'post',
    })

    return (
        <CardContainer>
            <div style={{ color: "#00AA4F", fontSize: "16px", marginBottom: "24px" }}>Silahkan isi informasi terkait pertanyaan di bawah ini!</div>
            <form onSubmit={e => handleSubmit(e)}>
                <div className="col-lg-8 col-12">
                    <Input
                        id="pertanyaan"
                        onChange={handleChange}
                        placeholder="Masukkan pertanyaan"
                        label="Pertanyaan" />
                    <RichText
                        onChange={handleChangeRich}
                        label="Jawaban"
                        id="jawaban" />
                    <SelectAsync2
                        onChange={handleChange}
                        labelName="product_name"
                        valueName="_id"
                        id="id_produk"
                        url="/v1/product"
                        label="Produk"
                        placeHolder="Pilih Produk" />
                    <SelectAsync2
                        onChange={handleChange}
                        labelName="name"
                        valueName="_id"
                        id="id_tanaman"
                        url="/v1/kategori"
                        paramsId={`?category_type=tanaman`}
                        label="Tanaman"
                        placeHolder="Pilih Tanaman" />
                    <SelectAsync2
                        onChange={handleChange}
                        labelName="name"
                        valueName="_id"
                        id="kategori_produk_id"
                        url="/v1/kategori"
                        paramsId={`?category_type=product`}
                        label="Kategori Produk"
                        placeHolder="Pilih Kategori Produk" />
                    <SelectAsync2
                        onChange={handleChange}
                        labelName="name"
                        valueName="_id"
                        id="kategori_opt_id"
                        url="/v1/kategori"
                        paramsId={`?category_type=opt`}
                        label="Kategori OPT"
                        placeHolder="Pilih Kategori OPT" />
                    <div className="d-flex justify-content-end">
                        <div className="me-4">
                            <Button
                                onClick={() => navigate(-1)}
                                type="button"
                                className={`btn-light w-100 mt-4 px-5`}
                                title="Batalkan" />
                        </div>
                        <Button
                            type="submit"
                            className={`btn-primary w-100 mt-4 px-5`}
                            title="Simpan"
                            isLoading={isLoadingPost} />
                    </div>
                </div>
            </form>
        </CardContainer>
    )
}

export default AddQuestionPage;