import { Button, CardContainer, Input } from '../../components'
import Table from '../../components/organisms/table'
import columns from './column'
import { Link } from 'react-router-dom';
import { ADDCHANNEL, ADDJABATAN } from '../../routes/routesNames';
import { UseGet } from '../../hooks/api';
import { UseTable } from '../../hooks';
import ImportModalSecond from '../../components/molecules/importModalSecond';

const Channel = () => {
    const {
        filter,
        isLoading,
        data,
        error,
        totalPages,
        handlePagination,
        handleLimit,
        handleFilter,
    } = UseGet('/v1/channel')

    const {
        isLoading: isLoadingPost,
        dataRemove,
        handleSelected,
        handleRemove
    } = UseTable();

    return (
        <CardContainer >
            <div className="d-flex flex-row justify-content-between">
                <Input type="search" id="search" placeholder="Cari Retailer" onChange={handleFilter} />
                {
                    dataRemove.length === 0 ?
                        <div className="d-flex flex-row">
                            <Link to={ADDCHANNEL}>
                                <Button title="Tambah Retailer" className="btn btn-primary" isSmall />
                            </Link>
                            <ImportModalSecond
                                urlImport={`${process.env.REACT_APP_API_URL}/v1/channel/import_excel`}
                                urlDownload={`${process.env.REACT_APP_API_URL}/v1/channel/blank_template_excel`}
                                title={"Import Retailer"}
                            />
                            <Link to="" target='_blank'>
                                <Button title="Export" className="btn btn-primary" isSmall onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = `${process.env.REACT_APP_API_URL}/v1/channel/download_template`;
                                }} />
                            </Link>
                        </div>
                        :
                        <Button
                            isLoading={isLoadingPost}
                            onClick={() => handleRemove('/v1/channel/delete')}
                            title="Hapus Retailer"
                            className="btn btn-danger" isSmall />
                }

            </div>
            <Table
                filter={filter}
                isLoading={isLoading}
                error={error}
                data={data}
                totalPages={totalPages}
                handlePagination={handlePagination}
                handleLimit={handleLimit}
                handleChangeSelect={handleSelected}
                columns={columns} />
        </CardContainer>
    )
}

export default Channel