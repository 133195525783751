import { Button, CardContainer, Input, SelectAsync2, RichText } from '../../components'
import UseFormQuestion from './hookForm';

const EditQuestionPage = () => {
    const {
        isLoadingPut,
        form,
        content,
        navigate,
        handleChange,
        handleChangeRich,
        handleSubmit,
        isReady,
    } = UseFormQuestion({
        url: "/v1/pertanyaan",
        type: 'put',
    })

    if (!isReady) {
        return <p>Loading..</p>
    }

    return (
        <CardContainer>
            <div style={{ color: "#00AA4F", fontSize: "16px", marginBottom: "24px" }}>Silahkan isi informasi terkait pertanyaan di bawah ini!</div>
            <form onSubmit={e => handleSubmit(e)}>
                <div className="col-lg-8 col-12">
                    <Input
                        id="pertanyaan"
                        onChange={handleChange}
                        value={form.pertanyaan}
                        placeholder="Masukkan pertanyaan"
                        label="Pertanyaan" />
                    <RichText
                        value={content}
                        onChange={handleChangeRich}
                        label="Jawaban"
                        id="jawaban" />
                    <SelectAsync2
                        onChange={handleChange}
                        labelName="product_name"
                        valueName="_id"
                        id="id_produk"
                        url="/v1/product"
                        label="Produk"
                        value={{
                            label: form.produk ? form.produk.nama : '',
                            value: form.produk ? form.produk._id : '',
                            name: "id_produk"
                        }}
                        placeHolder="Pilih Produk" />
                    <SelectAsync2
                        onChange={handleChange}
                        labelName="name"
                        valueName="_id"
                        id="id_tanaman"
                        url="/v1/kategori"
                        paramsId={`?category_type=tanaman`}
                        label="Tanaman"
                        value={{
                            label: form.tanaman ? form.tanaman.nama : '',
                            value: form.tanaman ? form.tanaman._id : '',
                            name: "id_tanaman"
                        }}
                        placeHolder="Pilih Tanaman" />
                    <SelectAsync2
                        onChange={handleChange}
                        labelName="name"
                        valueName="_id"
                        id="kategori_produk_id"
                        url="/v1/kategori"
                        paramsId={`?category_type=product`}
                        label="Kategori Produk"
                        value={{
                            label: form.kategori_produk ? form.kategori_produk.nama : '',
                            value: form.kategori_produk ? form.kategori_produk._id : '',
                            name: "kategori_produk_id"
                        }}
                        placeHolder="Pilih Kategori Produk" />
                    <SelectAsync2
                        onChange={handleChange}
                        labelName="name"
                        valueName="_id"
                        id="kategori_opt_id"
                        url="/v1/kategori"
                        paramsId={`?category_type=opt`}
                        label="Kategori OPT"
                        value={{
                            label: form.kategori_penyakit ? form.kategori_penyakit.nama : '',
                            value: form.kategori_penyakit ? form.kategori_penyakit._id : '',
                            name: "kategori_opt_id"
                        }}
                        placeHolder="Pilih Kategori OPT" />
                    <div className="d-flex justify-content-end">
                        <div className="me-4">
                            <Button
                                onClick={() => navigate(-1)}
                                type="button"
                                className={`btn-light w-100 mt-4 px-5`}
                                title="Batalkan" />
                        </div>
                        <Button
                            type="submit"
                            className={`btn-primary w-100 mt-4 px-5`}
                            title="Simpan"
                            isLoading={isLoadingPut} />
                    </div>
                </div>
            </form>
        </CardContainer>
    )
}

export default EditQuestionPage;