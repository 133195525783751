import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { UseForm } from "../../../hooks";
import { UsePostDataForm, UsePutDataForm, UseGet } from "../../../hooks/api";
import { ARTICLE } from "../../../routes/routesNames";
import { useState } from "react";

const UseFormArticle = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);


  const { isLoading: isLoadingPostDataForm, handlePostDataForm } =
    UsePostDataForm();
  const { isLoading: isLoadingPutDataForm, handlePutDataForm } =
    UsePutDataForm();
  const { form, setForm, setContent, handleChange, content, handleChangeRich } =
    UseForm();
  const { isLoading: isLoadingEx, data: dataEx } = UseGet("/v1/jabatan")



  useEffect(() => {
    setForm({
      ...form,
      status: "arsip",
      is_trending: false,
    });
    if (props.type === "put") {
      if (location.state) {
        let dataTag = [];

        location.state.tag.map((data) => {
          dataTag.push({
            id: data,
            text: data,
          });
        });

        setForm({
          ...location.state,
          tag: dataTag,
        });
        setContent(location.state.content);
        setIsReady(true);
      } else {
        navigate(ARTICLE);
      }
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    let dataTag = [];

    form.tag.map((data) => {
      dataTag.push(data.text);
    });


    if (props.type === "post") {
      if (
        form.thumbnail.type === "image/jpeg" ||
        form.thumbnail.type === "image/png"
      ) {
        let te = form.type_expert;
        let pt = form.province_type;
        if (te === "semua") {
          te = "Semua";
        }
        if (pt === "semua") {
          pt = "Semua";
        }

        let params = {
          url: "/v1/article",
          form: {
            ...form,
            jns_tanaman: form.jenis_tanaman,
            tag: dataTag,
            type_expert: te,
            province_type: pt,
            status: "publish",
            content: content,
          },
          navigation: ARTICLE,
        };
        if (form.tag.length < 1) {
          swal("Gagal", "Tag harus diisi", "error");
        } else {
          handlePostDataForm(params);
        }
      } else {
        swal("Gagal", "File Harus Gambar", "error");
      }
    } else {
      if (typeof form.thumbnail !== "string") {
        if (
          form.thumbnail.type === "image/jpeg" ||
          form.thumbnail.type === "image/png"
        ) {
          let params = {
            url: "/v1/article?_id=" + form._id,
            form: {
              ...form,
              content: content,
              tag: dataTag,
            },
            navigation: ARTICLE,
          };
          if (form.tag.length < 1) {
            swal("Gagal", "Tag harus diisi", "error");
          } else {
            handlePutDataForm(params);
          }
        } else {
          swal("Gagal", "File Harus Gambar", "error");
        }
      } else {
        let params = {
          url: "/v1/article?_id=" + form._id,
          form: {
            ...form,
            tag: dataTag,
            content: content,
          },
          navigation: ARTICLE,
        };
        delete params.form.thumbnail;
        if (form.tag.length < 1) {
          swal("Gagal", "Tag harus diisi", "error");
        } else {
          handlePutDataForm(params);
        }
      }
    }
  };

  const isEnable = content;

  // if(form.terbitkan === 'sekarang'){
  //     isEnable = form.title  && content &&  form.jenis_opt.length &&  form.jenis_tanaman.length &&  form.tag.length ;
  // }else{
  //     isEnable = form.title  && content &&  form.jenis_opt.length &&  form.jenis_tanaman.length &&  form.tag.length && form.tanggal_publish && form.waktu_publish ;
  // }

  return {
    isLoadingPostDataForm,
    isLoadingPutDataForm,
    form,
    isReady,
    isEnable,
    dataEx,
    handleChange,
    handleChangeRich,
    handleSubmit,
    navigate,
    content,
  };
};

export default UseFormArticle;
