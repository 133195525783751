import React, { useState ,useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {loginData, enterPage,loginAction} from '../../../stores/auth';
import { Link } from "react-router-dom";
import './auth.scss';
import {LogoTagline} from '../../../assets/images';
import {Input,Button,Alert} from '../../../components';

const LoginPage = () => {
    const dispatch = useDispatch();
    const dataLogin = useSelector(loginData);

    const [form,setForm] = useState({})

    useEffect(() => {
        dispatch(enterPage());
    }, []);
    

    const handleChange = e => {
        const {value,id} = e.target
        setForm({
            ...form,
            [id] : value
        })
    }

    const handleSubmit = e => {
        e.preventDefault()
        dispatch(loginAction(form));
    }

  return (
    <div className="auth">
        <div className="auth-card">
            <img src={LogoTagline} alt="logo" width="180" />
            <h1>Selamat Datang!</h1>
            <p className="sub-title">Silahkan masuk ke akun Anda</p>
            <form onSubmit={e => handleSubmit(e)}>
                {
                    dataLogin.status === "failed" ?
                    <Alert type="danger" message={dataLogin.error}  />: null
                }
                <Input id="email" label="Email" onChange={handleChange} placeholder="Masukan email" type="email" />
                <Input id="password" label="Password" onChange={handleChange} placeholder="Masukan password" type="password"  />
                <div className="d-flex justify-content-end">
                <Link to="/auth/forgot" className="text-secondary mt-2">Lupa Sandi ?</Link>
                </div>
                <Button className="btn-primary w-100 mt-4" title="Masuk" isLoading={dataLogin.status === 'loading'} />
            </form>
        </div>
    </div>
  )
}

export default LoginPage